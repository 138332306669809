import React from "react";
import "./CompanyPage.less";
import EmptyLine from "../ui/EmptyLine";
import AirJobbFooter from "../component/AirJobbFooter";
import {AJButton4} from "../ui/AJButton3";
import { Row, Col } from "antd";
import { ceoCardContent } from '../config/ValuesConfig';
import CarouselContainer from "../component/startV2/carouselContainer";
import { CeoCard } from "../page/StartPageV2";


export default function CompanyPage(props) {
    return (
        <>
            <div className={'static-page'}>
                <div className={'static-page-container'}>
                    <div className={'static-page-header'}>
                        <img src={'/img/company/company-header.png'} width={'100%'} alt={'company page header'}/>
                        <div className={'static-page-title'} style={{fontFamily: 'BespokeStencilBold'}}>
                            Employer
                        </div>
                    </div>
                    <EmptyLine height={32}/>
                    <div className={'static-page-text-container'}>
                        <div className={'static-page-text-title'}>
                            Bring your<br/> Engineering Team<br/>To Life
                        </div>
                        <EmptyLine height={32}/>
                        <div className={'static-page-text-body'}>
                            <div>In today's fiercely competitive and innovative tech landscape, how can your company truly stand out? The answer lies in creating an exceptional candidate experience that resonates deeply with top engineering talent. This will help you become an iconic destination for the brightest engineers.</div>
                            <EmptyLine height={19}/>
                            <div>We created Genvalues to help you be the company that everyone wants to work for in your industry by beautifully presenting how your engineering teams operate according to your specific guiding principles and values.</div>
                            <EmptyLine height={19}/>
                            <div>We help you craft an authentic narrative that showcases the unique ethos of your engineering team and company mission. Imagine a world where your company is the top destination for the brightest engineers, the place where they want to change the industry.</div>
                            <EmptyLine height={32}/>
                            
                            <div className="title">Current platforms don't speak to Engineers</div>
                            <div>They fail to highlight your engineering team's unique strengths and culture, leaving no insight into team dynamics. Cluttered job listings create confusion, diluting the focus on engineering roles. Unappealing designs fail to capture the innovative spirit of engineering teams.</div>
                            <EmptyLine height={19}/>
                            <div>Witness the astonishing power of a well-crafted Engineering career site that reflects the essence of your team. Current platforms don't speak to the elite Engineers.</div>

                            <div className="title">There is a movement</div>
                            <div>There's a movement happening that goes beyond traditional recruiting. It's about the brightest individuals seeking work that matters, companies offering opportunities aligned with core principles, and people striving for a positive impact on our world.</div>

                            <div className="title">Seamless Integration</div>
                            <div>Our platform integrates simesless with tools like Teamtailor and Jobylon, to attract the very best talent without disrupting your current methods.</div>
                        </div>
                        <EmptyLine height={70}/>
                    </div>
                </div>
                <img src={'/img/company/company-pic-1-v2.png'} width={'100%'} alt={'company page pic 1'}/>
                <EmptyLine height={52}/>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <AJButton4
                        title={'Contact us'}
                        size={'mini'}
                        width={193}
                        type={'secondary'}
                        height={45}
                        titleStyle={{
                            fontSize: 18,
                            fontWeight: 400,
                            fontFamily: "InterRegular",
                            color: '#000000'
                        }}
                        hoverToBlack={true}
                        onClick={() => {
                            window.open('mailto:team@genvalues.com');
                        }}/>
                </div>
                <EmptyLine height={42}/>

                <div className={'static-page-container'}>
                    <div className={'static-page-text-container'}>
                        <div className={'static-page-text-title'}>
                            Why Choose<br/>Genvalues?
                        </div>
                        <EmptyLine height={32}/>
                        <div className={'static-page-text-body'}>
                            <div>Joining Genvalues means more than just exposure to thousands of Engineers, UI/UX Designers, DevOps, CTOs, and Product Managers. It means becoming part of a community that values diversity, fosters strong team unity, and clarifies product direction. It's about creating bonds that drive success.</div>
                            
                            <div className="title">Begin Your Genvalues</div>
                            <div className="title-line-two">Journey Today</div>
                            <div>Are you ready to revolutionize how you attract and retain top Engineering talent, with a special emphasis on the team and product mission of making a positive impact? Visit Genvalues.com to create your profile and join a community of forward-thinking companies.</div>

                            <EmptyLine height={32}/>

                        </div>
                        <div className={'static-page-text-title'}>
                            Publish a profile
                        </div>
                        <EmptyLine height={32}/>
                        <div className={'static-page-text-body'}>
            
                            <ul style={{marginLeft: -23}}>

                                <li>Pick your Engineering principles & product mission.</li>
                                <br/>
                                <li>Describe them shortly (you receive support).</li>
                                <br/>
                                <li>Link easily your job openings to your profile with integrations to Teamtailor, Jobylon, The Hub, or manually from any website.</li>
                                <br/>
                                <li>Top Engineers will connect that resonate with your team.</li>                    
                                <br/>
                                <li>Contact us via email at <a href='mailto:team@genvalues.com' style={{'text-decoration': 'underline'}}>team@genvalues.com </a> to go faster.</li>

                            </ul>

                            <EmptyLine height={24}/>

                        </div>
                    </div>
                </div>
                <EmptyLine height={23}/>

                <div style={{paddingLeft: '5%', paddingRight: '5%'}}>
                    <img src={'/img/company/company-we-are-friend.svg'} width={'100%'} alt={'company page pic 2'}/>
                </div>
                <EmptyLine height={33}/>
                <img src={'/img/company/company-pic-3-v2.png'} width={'100%'} alt={'company page pic 3'}/>
                <EmptyLine height={32}/>

                <div className={'static-page-container'}>
                    <div className={'static-page-text-container'}>
                        <div className={'static-page-text-title'}>
                            The Power of<br/>Aligning Your<br/>Engineering team
                        </div>
                        <EmptyLine height={32}/>
                        <div className={'static-page-text-body'} style={{marginLeft: -24}}>
                            <ul>
                                <li>
                                    <b>Boosted Motivation and Commitment:</b>
                                    <li style={{marginTop: 20, marginLeft: 28}}>Aligning with both your engineering principles and team values ignites a deeper sense of purpose in People. This not only attracts top-tier professionals but also smoothens the onboarding process, as they resonate with your methodologies and ethos.</li>
                                </li>
                                <br/>

                                <li>
                                    <b>Enhanced Communication and Collaborative Engineering:</b>
                                    <li style={{marginTop: 20, marginLeft: 28}}>When team members share your engineering principles, they understand not just 'what' to do but 'how' and 'why' as well. This shared understanding fosters better teamwork, efficient problem-solving, and more innovative engineering solutions.</li>
                                </li>
                                <br/>

                                <li>
                                    <b>Nurturing Innovation and Creative Problem-Solving:</b>
                                    <li style={{marginTop: 20, marginLeft: 28}}>A team united by common principles and values becomes a powerhouse of creativity and innovation. This environment is conducive to exploring new ideas and approaches in engineering, leading to advanced solutions and cutting-edge developments.</li>
                                </li>
                                <br/>

                                <li>
                                    <b>Increased Satisfaction and Employee Retention:</b>
                                    <li style={{marginTop: 20, marginLeft: 28}}>Engineers who align with your principles and values experience higher job satisfaction. This alignment breeds loyalty and a strong commitment to the company, significantly enhancing employee retention and building a more robust and dedicated team.</li>
                                </li>
                                <br/>

                                <li>
                                    <b>Elevating Company Reputation in the Tech Community:</b>
                                    <li style={{marginTop: 20, marginLeft: 28}}>Teams that operate on shared engineering principles and values positively impact your company's reputation. This shared ethos is visible in the quality of your work and products, attracting not only talent but also clients who value your approach.</li>
                                </li>
                            </ul>

                            <EmptyLine height={24}/>

                        </div>
                    </div>
                </div>

                <EmptyLine height={24}/>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <AJButton4
                        title={'Contact us'}
                        size={'mini'}
                        width={193}
                        type={'secondary'}
                        height={45}
                        titleStyle={{
                            fontSize: 18,
                            fontWeight: 400,
                            fontFamily: "InterRegular",
                            color: '#000000'
                        }}
                        hoverToBlack={true}
                        onClick={() => {
                            window.open('mailto:team@genvalues.com');
                        }}/>
                </div>

                <EmptyLine height={74}/>

                <Row>
                    <Col span={8}>
                        <div style={{textAlign: 'center'}}>
                            <img style={{width: '73%'}} src={`/img/company/pic1.svg`}></img>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={{textAlign: 'center'}}>
                            <img style={{width: '65%'}} src={`/img/company/pic2.svg`}></img>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={{textAlign: 'center'}}>
                            <img style={{width: '73%'}} src={`/img/company/pic3.svg`}></img>
                        </div>
                    </Col>
                </Row>

                <EmptyLine height={74}/>

                <div className="title-container">
                    <img src={"/img/home/ceoSaying.svg"}></img>
                </div>

                <CarouselContainer>
                    {
                        ceoCardContent.map(item => {
                            return <CeoCard item={item}></CeoCard>
                        })
                    }
                </CarouselContainer>

                <EmptyLine height={74}/>

            </div>
            <AirJobbFooter/>
        </>
    )
}