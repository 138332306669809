import React from "react";

function SvgRightIcon(props) {
    let {fill='white', background='black', width="'48'"} = props;

    return (
        <svg width={width} height={width} viewBox="0 0 48 48" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="23" stroke={background} stroke-width="2"/>
            <path d="M21.1763 14.1176L30.0988 21.9082C30.3994 22.1649 30.6411 22.4851 30.8069 22.8464C30.9727 23.2077 31.0586 23.6014 31.0586 24C31.0586 24.3985 30.9727 24.7922 30.8069 25.1536C30.6411 25.5149 30.3994 25.8351 30.0988 26.0917L21.1763 33.8823" stroke={background} stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
    );
}

export default SvgRightIcon;
