import React from 'react';
import { Icon } from 'antd-mobile';

export function Loading(props) {
    let {width, height, background} = props

    width = width || '100%'
    height = height || '100%'

    return (

        <div style={{height, width, background, textAlign: 'center'}}>
            <Icon type={'loading'} size={'lg'}/>
        </div>
    )
}