import React from "react";

import "./AJLoading.less"
import ReactLoading from "react-loading";

export default function AJLoading(props) {
    return (
        <div className={'aj-loading-mask'} hidden={!props.visible}>
            <div className={'aj-loading-container'}
                 style={{
                left: `calc(50% - ${(props.width || 32)/2}px)`
            }}>
                <ReactLoading
                    type={props.type || 'spokes'}
                    color={ props.color || '#000'}
                    height={props.height || 32}
                    width={props.width || 32}
                    className={props.className || 'aj-loading'}/>
            </div>

        </div>

    )
}
