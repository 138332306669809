export function arrTrans(arr, num) {
    const iconsArr = [];
    arr.forEach((item, index) => {
        const page = Math.floor(index / num);
        if (!iconsArr[page]) {
            iconsArr[page] = [];
        }
        iconsArr[page].push(item);
    });
    return iconsArr;
}

// export function arrTransAndFill(arr, num) {
//     const iconsArr = [];
//     arr.forEach((item, index) => {
//         const page = Math.floor(index / num);
//         if (!iconsArr[page]) {
//             iconsArr[page] = [];
//         }
//         iconsArr[page].push(item);
//     });
//
//     const missing = Math.floor(index / num) * num - arr.length
//
//     for (let index = 0; index < missing; ++index) {
//
//     }
//
//     return iconsArr;
// }


export function arrayContains(array, element) {
    if (!array) {
        return false
    }
    let i = array.length;
    while (i--) {
        if (array[i] === element) {
            return true;
        }
    }
    return false;
}

function isSafariBrowser() {
    return navigator.userAgent.indexOf('Safari') !== -1;
}
