import React from "react";

function LogoWithoutText(props) {
    let {schema} = props;

    return (
        <svg width="149" height="36" viewBox="0 0 149 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17.3897" cy="31.8057" r="3.59283" fill={schema}/>
            <circle cx="17.4597" cy="22.4636" r="1.94013" fill={schema}/>
            <circle cx="17.4607" cy="16.572" r="1.07785" fill={schema}/>
            <circle cx="11.7839" cy="13.4811" r="1.94013" fill={schema}/>
            <circle cx="23.2097" cy="13.4811" r="1.94013" fill={schema}/>
            <circle cx="3.59283" cy="19.9737" r="3.59283" fill={schema}/>
            <circle cx="31.2374" cy="19.9737" r="3.59283" fill={schema}/>
            <circle cx="9.05377" cy="3.59283" r="3.59283" fill={schema}/>
            <circle cx="25.7217" cy="3.59283" r="3.59283" fill={schema}/>
        </svg>
    );
}

export default LogoWithoutText;
