import React from 'react'
import ReactDOM from 'react-dom'

export default function CompanySignIn() {
    return <div style={{height: 800, paddingTop: 300, textAlign: 'center', fontSize: 20, fontWight: 700}}>
        <div>New User?</div>
        <br/>
        <div>
            <div>
                Please make sure to use
            </div>
            <div>
                a computer.
            </div>
        </div>

    </div>
}