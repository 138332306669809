import React, { createContext, useState, useContext } from 'react';

/*
This context is used to track the user email and name that the user provides when clicking
the 'Read more' in a job card. This is a workaround if the localStorage and sessionStorage
are disabled in the user's browser.
*/
const UserInfoContext = createContext();

export function useUserInfoContext() {
  return useContext(UserInfoContext);
}

export function UserInfoProvider({ children }) {
    const [emailContext, setEmailContext] = useState(null);
    const [nameContext, setNameContext] = useState(null);

    return (
      <UserInfoContext.Provider value={{ emailContext, setEmailContext, nameContext, setNameContext }}>
        {children}
      </UserInfoContext.Provider>
    );
}
  