import React, {useState, useContext, useEffect, useLayoutEffect, useRef} from 'react';
import EmptyLine from "../ui/EmptyLine";
import "./MatchedCompanies.less";
import JobCard from "../component/start/JobCard";
import { Loading } from '../component/matched-companies/loading';
import {Toast} from "antd-mobile";
import {useHistory, useLocation} from 'react-router';
import {talentValueMatch} from "../http/AJHttp";
import InfiniteScroll from 'react-infinite-scroller';
import {useDispatch, useSelector} from "react-redux";
import {
    selectMatches,
    selectUnfilteredMatches,
    selectMatchesDone, selectMatchesPage,
    setDone, setMatches, setPage, setUnfilteredMatches,
    selectAllCompaniesMatches,
    selectAllCompaniesUnfilteredMatches,
    selectAllCompaniesMatchesDone, selectAllCompaniesMatchesPage,
    setAllCompaniesDone, setAllCompaniesMatches, setAllCompaniesPage, setAllCompaniesUnfilteredMatches,
    selectTopMatches, setTopMatches, selectTopMatchesNum, setTopMatchesNum
} from "../redux/slices/start";
import _ from "lodash";
import TechnologySelector from '../../src/component/matched-companies/technologySelect';
import AirJobbFooter from "../component/AirJobbFooter";

const setCardBottom = () => {
    const footerEl = document.getElementById('aj-start-footer')
    const el = document.getElementsByClassName('join-community-card-start-page-show-2')[0]
    if (el && footerEl) {
        let offset = window.scrollY - footerEl.offsetTop
        offset = offset > 0 ? offset : 0
        el.style.bottom = `${offset}px`
    }
}

export default function MatchedCompaniesWrapper(props) {
    return (<>
        <MatchedCompaniesPage {...props}/>
    </>)
}

function MatchedCompaniesPage(props) {
    const location = useLocation();
    const history = useHistory();

    const matches = useSelector(selectMatches);
    const unfilteredMatches = useSelector(selectUnfilteredMatches);
    const matchesDone = useSelector(selectMatchesDone);
    const page = useSelector(selectMatchesPage);
    const allCompaniesMatches = useSelector(selectAllCompaniesMatches);
    const allCompaniesUnfilteredMatches = useSelector(selectAllCompaniesUnfilteredMatches);
    const allCompaniesMatchesDone = useSelector(selectAllCompaniesMatchesDone);
    const allCompaniesPage = useSelector(selectAllCompaniesMatchesPage);
    const isTop = useSelector(selectTopMatches);
    const topNumber = useSelector(selectTopMatchesNum);
    const dispatch = useDispatch();

    const setHasNext = (has) => {
        _setHasNext(has);
        dispatch(setDone(!has));
    }

    const setCurrentPage = (page) => {
        _setCurrentPage(page);
        dispatch(setPage(page));
    }

    const setCards = (data) => {
        _setCards(data);
        dispatch(setMatches(data));
    }

    const setUnfilteredCards = (data) => {
        _setUnfilteredCards(data);
        dispatch(setUnfilteredMatches(data));
    }

    const setHasAllCompaniesNext = (has) => {
        _setHasAllCompaniesNext(has);
        dispatch(setAllCompaniesDone(!has));
    }

    const setCurrentAllCompaniesPage = (page) => {
        _setCurrentAllCompaniesPage(page);
        dispatch(setAllCompaniesPage(page));
    }

    const setAllCompaniesCards = (data) => {
        _setAllCompaniesCards(data);
        dispatch(setAllCompaniesMatches(data));
    }

    const setUnfilteredAllCompaniesCards = (data) => {
        _setUnfilteredAllCompaniesCards(data);
        dispatch(setAllCompaniesUnfilteredMatches(data));
    }

    const setIsTopMatches = (data) => {
        _setIsTopMatches(data);
        dispatch(setTopMatches(data));
    }

    const setTopMatchesNumber = (data) => {
        _setTopMatchesNumber(data);
        dispatch(setTopMatchesNum(data));
    }

    let tmp = location.state?.selectedValues;
    if (!tmp) {
        const cachedSelectedValues = JSON.parse(sessionStorage.getItem('cachedSelectedValues'))?.selectedValues;
        tmp = cachedSelectedValues ? cachedSelectedValues : [];
    }
    let selectedValuesList = [];
    for (let valueType of tmp) {
        for (let value of valueType['values']) {
            if (value['isSelected']) {
                selectedValuesList.push(value['value']);
            }
        }
    }
    let [selectedValue, setSelectedValue] = useState(selectedValuesList);
    const [searching, setSearching] = useState(false);
    const [cards, _setCards] = useState(matches);
    let [unfilteredCards, _setUnfilteredCards] = useState(unfilteredMatches);
    const [currentPage, _setCurrentPage] = useState(page);
    const [hasNext, _setHasNext] = useState(!matchesDone);

    const [isTopMatches, _setIsTopMatches] = useState(isTop);

    let cachedSelectedTechnology = JSON.parse(sessionStorage.getItem('cachedSelectedTechnology'));
    if (!cachedSelectedTechnology) {
        cachedSelectedTechnology = [];
    }
    let [selectedTechnology, setSelectedTechnology] = useState(cachedSelectedTechnology);
    let [topMatchesNumber, _setTopMatchesNumber] = useState(topNumber);

    const [allCompaniesSearching, setAllCompaniesSearching] = useState(false);
    const [allCompaniesCards, _setAllCompaniesCards] = useState(allCompaniesMatches);
    let [unfilteredAllCompaniesCards, _setUnfilteredAllCompaniesCards] = useState(allCompaniesUnfilteredMatches);
    const [currentAllCompaniesPage, _setCurrentAllCompaniesPage] = useState(allCompaniesPage);
    const [hasAllCompaniesNext, _setHasAllCompaniesNext] = useState(!allCompaniesMatchesDone);

    let [isSelectedValueChanged, setIsSelectedValueChanged] = useState(false);

    const fetchMatchedCompaniesData = async (values, page) => {
        // retrieve company number is larger than top matches number, return
        if (topMatchesNumber && topMatchesNumber<=8*(page-1)) {
            setHasNext(false);
            return;
        }

        if (searching || !hasNext) {
            console.log("skip search...");
            return;
        }
        values = values || [];
        page = page || 1;
        setSearching(true);
        const skeletons = _.times(8, () => {
            return {type : 'skeleton'};
        });
        setCards([...cards, ...skeletons]);

        const response = await talentValueMatch(values, page, 8, true);
        let totalMatchNumber = 0;
        if (response) {
            let tmpList = response.data.results;
            if (tmpList && tmpList.length > 0) {
                totalMatchNumber = Number(tmpList[0]['matched']);
            }

            if (page === 1) {
                if (tmpList && tmpList.length > 0) {
                    setTopMatchesNumber(totalMatchNumber);
                }
            } else {
                if (currentPage > page) {
                    setHasNext(false);
                    return;
                }
            }

            let tmp = unfilteredCards.length + tmpList.length;
            if (tmp > totalMatchNumber) {
                const redundantCompanyNumber = tmp - totalMatchNumber;
                tmpList = tmpList.slice(0, -redundantCompanyNumber);
            }

            setUnfilteredCards([...unfilteredCards, ...tmpList]);
            // if(selectedCompanyStructure.length > 0) {
            //     tmpList = companyStructureFilter(tmpList, selectedCompanyStructure);
            // }
            if(selectedTechnology.length > 0) {
                tmpList = technologyFilter(tmpList, selectedTechnology.map(item=>item.name));
            }
            setCards([...cards, ...tmpList]);

            if (response.data.next) {
                setCurrentPage(page + 1);
            } else {
                setHasNext(false);
            }

            setTimeout(setCardBottom, 0);

            // ReactGA.event({
            //     category: 'talent',
            //     action: 'talentmatch',
            //     transport: 'beacon'
            // });
        } else {
            setHasNext(false);
            Toast.fail("fetch matched company data failed");
        }
        setSearching(false);
    };

    const fetchAllCompaniesData = async (values, page) => {
        if (allCompaniesSearching || !hasAllCompaniesNext) {
            console.log("skip search all companies...");
            return;
        }
        values = values || [];
        page = page || 1;
        setAllCompaniesSearching(true);
        const skeletons = _.times(8, () => {
            return {type : 'skeleton'};
        });
        setAllCompaniesCards([...allCompaniesCards, ...skeletons]);

        const response = await talentValueMatch(values, page, 8, true);
        if (response) {
            let tmpList = response.data.results;

            if (page !== 1) {
                if (currentAllCompaniesPage > page) {
                    setHasAllCompaniesNext(false);
                    return;
                }
            }
            setUnfilteredAllCompaniesCards([...unfilteredAllCompaniesCards, ...tmpList]);
            // if(selectedCompanyStructure.length > 0) {
            //     tmpList = companyStructureFilter(tmpList, selectedCompanyStructure);
            // }
            if(selectedTechnology.length > 0) {
                tmpList = technologyFilter(tmpList, selectedTechnology.map(item=>item.name));
            }
            setAllCompaniesCards([...allCompaniesCards, ...tmpList]);

            if (response.data.next) {
                setCurrentAllCompaniesPage(page + 1);
            } else {
                setHasAllCompaniesNext(false);
            }

            setTimeout(setCardBottom, 0);
        }
        else {
            setHasAllCompaniesNext(false);
            Toast.fail("fetch all company data failed");
        }
        setAllCompaniesSearching(false);
    };

    function handleTechnologyChange(data) {
        setSelectedTechnology(data);
        sessionStorage.setItem('cachedSelectedTechnology', JSON.stringify(data));

        let cardsTmp = technologyFilter(unfilteredCards, data.map(item=>item.name));
        // cardsTmp = companyStructureFilter(cardsTmp, selectedCompanyStructure);
        setCards(Array.from(cardsTmp));

        let allCompanyCardsTmp = technologyFilter(unfilteredAllCompaniesCards, data.map(item=>item.name));
        // allCompanyCardsTmp = companyStructureFilter(allCompanyCardsTmp, selectedCompanyStructure);
        setAllCompaniesCards(Array.from(allCompanyCardsTmp));
    }

    function technologyFilter(companyList, selectedTechnologyList) {
        if (selectedTechnologyList && selectedTechnologyList.length == 0) {
            return companyList;
        }
        return companyList.filter(item=>{
            if (item['technologies'] && item['technologies']['selected'] && Array.isArray(item['technologies']['selected'])) {
                for (let tech of item['technologies']['selected']) {
                    if (selectedTechnologyList.includes(tech['name'])) {
                        return true;
                    }
                }
            }
            return false;
        });
    }

    function canFilter() {
        return isTopMatches ? searching : allCompaniesSearching;
    }

    function handleRestartSelection() {
        dispatch(setMatches([]));
        dispatch(setUnfilteredMatches([]));
        dispatch(setPage(1));
        dispatch(setDone(false));
        dispatch(setAllCompaniesMatches([]));
        dispatch(setAllCompaniesUnfilteredMatches([]));
        dispatch(setAllCompaniesPage(1));
        dispatch(setAllCompaniesDone(false));
        dispatch(setTopMatches(true));
        dispatch(setTopMatchesNum(null));

        if (isSelectedValueChanged) {
            for (let valueType of tmp) {
                for (let value of valueType['values']) {
                    if (value['isSelected'] && !selectedValue.includes(value['value'])) {
                        value['isSelected'] = false;
                    }
                }
            }
        }
        history.push({
            pathname: '/value-select',
            state: {
                isChanged: isSelectedValueChanged,
                selectedValues: tmp
            }
        });
    }

    function handleDeleteValue(v, index) {
        const newItems = selectedValue.filter((item) => v !== item);
        setSelectedValue(newItems);
        if (!isSelectedValueChanged) {
            setIsSelectedValueChanged(true);
        }

        if (newItems.length) {
            setTopMatchesNumber(null);
            setIsTopMatches(true);
            setCurrentPage(1);
            setSearching(false);
            setCards([...[]]);
            setUnfilteredCards([...[]]);
            setHasNext(true);
        } else {
            setTopMatchesNumber(null);
            setIsTopMatches(true);
            setCurrentPage(1);
            setSearching(false);
            setCards([...[]]);
            setUnfilteredCards([...[]]);
            setHasNext(false);
        }
    }

    useEffect(() => {
        setTopMatchesNumber(null);
        setIsTopMatches(true);
        setCurrentPage(1);
        setSearching(false);
        setCards([...[]]);
        setUnfilteredCards([...[]]);
        setHasNext(true);
    }, []);

    return (
        <div className={'matched-companies-page'}>

            <EmptyLine height={55}/>
            <div className={'selected-values-container'}>
                <div className='values-title'>Your values</div>

                <EmptyLine height={12}/>

                <ValuesDisplayer data={selectedValue} closeDisable={searching || !isTopMatches} onValueChange={(v, index) => {handleDeleteValue(v, index)}}  />

                <EmptyLine height={15}/>

                <button className='matched-company-button' onClick={()=>{handleRestartSelection()}}>Restart selection</button>

            </div>

            <EmptyLine height={42}/>

            <div className={'tech-filter-container'}>
                <TechnologySelector disabled={canFilter()} selected={selectedTechnology} onChange={(data)=>{handleTechnologyChange(data)}}></TechnologySelector>
            </div>

            <div className={'company-filter-container'}>
                <EmptyLine height={22}/>
                <button className={`matched-company-button ${!isTopMatches ? 'active' : ''}`} style={{'width': '230px'}} onClick={()=>{setIsTopMatches(false)}}>Discover more</button>
                <EmptyLine height={22}/>
                <button className={`matched-company-button ${isTopMatches ? 'active' : ''}`} style={{'width': '230px'}} onClick={()=>{setIsTopMatches(true)}}>
                    <img src="/img/matched-companies/diamond.svg" style={{'position': 'relative', 'right': '6px', 'bottom': '2px'}}></img>
                    <span style={{'position': 'relative', 'bottom': '1px'}}>
                        Your matches {topMatchesNumber ? <span>({topMatchesNumber})</span> : <></>}
                    </span>
                </button>
            </div>

            <div style={{'background': 'white', 'minHeight': '500px'}}>
                <div className={'matched-companies-container'} style={{'padding-top': '43px'}}>
                    {
                        isTopMatches ? (
                            <InfiniteScroll
                                key={currentPage}
                                pageStart={0}
                                loadMore={async () => {
                                    await fetchMatchedCompaniesData(selectedValue, currentPage);
                                }}
                                hasMore={!matchesDone}
                                loader={<Loading size={50}/>}
                                threshold={250}
                            >
                                {cards.map(data =>
                                    <>
                                        <JobCard data={data} number={data.job_count}/>
                                        <EmptyLine height={32}/>
                                    </>)}
                            </InfiniteScroll>

                        ) : (
                            <InfiniteScroll
                                key={currentAllCompaniesPage}
                                pageStart={0}
                                loadMore={async () => {
                                    await fetchAllCompaniesData([], currentAllCompaniesPage);
                                }}
                                hasMore={!allCompaniesMatchesDone}
                                loader={<Loading size={50}/>}
                                threshold={250}
                            >
                                {allCompaniesCards.map(data =>
                                    <>
                                        <JobCard data={data} number={data.job_count}/>
                                        <EmptyLine height={32}/>
                                    </>)}
                            </InfiniteScroll>
                        )
                    }
                    <EmptyLine height={50}/>
                </div>
            </div>

            <AirJobbFooter/>

        </div>
    )
}

function ValuesDisplayer({data, closeDisable, onValueChange}) {
    closeDisable = closeDisable || false;

    return (
        <div className={'values-tags-container'}>
            {
                data.map((v, index)=>{
                    return (
                        <div style={{'display': 'inline'}}>
                            <span className={'value-tag'}>
                                {v}
                                <img src={`/img/matched-companies/grey-close${closeDisable ? '-disabled' : ''}.svg`} style={{'width': '11px', 'margin-left': '9px', 'cursor': closeDisable ? 'not-allowed':'pointer'}}
                                    onClick={()=>{
                                        if (!closeDisable) {
                                            onValueChange(v, index);
                                        }
                                    }}>
                                </img>
                            </span>
                            {(index+1) == (data.length / 2).toFixed(0) ? <br/> : <></>}
                        </div>
                    )
                })
            }
        </div>
    );
}
