import React from "react";
import "./AJButton3.less";
import {Icon} from "antd-mobile";

export default function AJButton3(props) {

    let {shadow,
        title,
        disabled,
        type,
        size,
        scheme,
        onClick,
        icon,
        width,
        height,
        fontSize,
        loading,
        customStyle,
        titleStyle,
    href} = props

    type = type || 'primary'
    size = size || 'normal'
    scheme = scheme || 'dark'
    onClick = onClick || (() => {})

    const paddingLeft = size === 'normal' ? 32 : 22
    const paddingRight = size === 'normal' ? 32 : 22

    customStyle = customStyle || { }
    if (width) {
        customStyle = {
            ...customStyle,
            width: width,
        }
    }
    else {
        customStyle = {
            ...customStyle,
            paddingLeft,
            paddingRight,
        }
    }

    if (height) {
        customStyle = {
            ...customStyle,
            height,
        }
    }

    if (fontSize) {
        customStyle = {
            ...customStyle,
            ...fontSize,
        }
    }

    if (loading) {
        // disable click event ...
        onClick = () => {}
        customStyle = {
            ...customStyle,
            cursor: "not-allowed",
        }
    }

    if (href) {
        customStyle = {
            ...customStyle,
            href,
        }
    }

    let iconFontSize = size === 'normal' ? 18 : 12

    const className = `aj-btn-3 
        aj-btn-${size} 
        ${scheme.length === 0? `aj-btn-${type}-container` : `aj-btn-${scheme}-${type}-container`}
        ${shadow ? 'aj-btn-box-shadow' : ''}`

    titleStyle = titleStyle || {}

    return (
        <button className={className}
                style={customStyle}
                disabled={disabled}
                onClick={onClick}>
            <div>
                {loading? (<div style={{display: 'inline-block', verticalAlign: 'middle'}}> <Icon type="loading"/></div>) : null}
                {loading? <div style={{display: 'inline-block'}}>&nbsp;&nbsp;</div> : null}
                <div style={{fontSize: fontSize, display: 'inline-block', ...titleStyle}}>{title}</div>
                {icon? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : null}
                {icon? (<Icon type={icon} style={{fontSize: iconFontSize}}/>) : null}
            </div>
        </button>
    )
}

export function AJButton5(props) {

    let {shadow,
        title,
        disabled,
        type,
        size,
        scheme,
        onClick,
        icon,
        width,
        height,
        font,
        loading,
        titleStyle,
        customStyle,
        href,
        onMouseEnter,
        onMouseLeave} = props

    type = type || 'primary'
    size = size || 'normal'
    scheme = scheme || 'dark'
    onClick = onClick || (() => {})
    onMouseEnter = onMouseEnter || (() => {})
    onMouseLeave = onMouseLeave || (() => {})

    const paddingLeft = size === 'normal' ? 32 : 22
    const paddingRight = size === 'normal' ? 32 : 22

    customStyle = customStyle || { }
    if (width) {
        customStyle = {
            ...customStyle,
            width: width,
        }
    }
    else {
        customStyle = {
            ...customStyle,
            paddingLeft,
            paddingRight,
        }
    }

    if (height) {
        customStyle = {
            ...customStyle,
            height,
        }
    }

    if (font) {
        customStyle = {
            ...customStyle,
            ...font,
        }
    }

    if (loading) {
        // disable click event ...
        onClick = () => {}
        customStyle = {
            ...customStyle,
            cursor: "not-allowed",
        }
    }

    if (href) {
        customStyle = {
            ...customStyle,
            href,
        }
    }

    const iconFontSize = size === 'normal' ? 18 : 12

    const className = `aj-btn-3 
        aj-btn-${size} 
        ${scheme.length === 0? `aj-btn-${type}-container` : `aj-btn-${scheme}-${type}-container`}
        ${shadow ? 'aj-btn-box-shadow' : ''}`

    return (
        <button className={className}
                style={customStyle}
                disabled={disabled}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>
            <div>
                {loading? (<Icon type="loading"/>) : null}
                {loading? <span>&nbsp;&nbsp;</span> : null}
                <div style={{...titleStyle}}>{title}</div>
                {icon? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : null}
                {icon? (<Icon type={icon} style={{fontSize: iconFontSize}}/>) : null}
            </div>
        </button>
    )
}

export function AJCenterButton(props) {
    return (
        <div style={{width: '100%', margin: 'auto', textAlign: 'center'}}>
            {AJButton3(props)}
        </div>
    )
}

export function AJButton4(props) {

    let {shadow,
        title,
        disabled,
        type,
        size,
        scheme,
        onClick,
        icon,
        width,
        height,
        fontSize,
        loading,
        titleStyle,
        href,
        hoverToBlack} = props

    type = type || 'primary'
    size = size || 'normal'
    scheme = scheme || 'dark'
    onClick = onClick || (() => {})
    hoverToBlack = hoverToBlack || false

    const paddingLeft = size === 'normal' ? 32 : 22
    const paddingRight = size === 'normal' ? 32 : 22

    let customStyle = {position: 'relative'}
    if (width) {
        customStyle = {
            ...customStyle,
            width: width,
        }
    }
    else {
        customStyle = {
            ...customStyle,
            paddingLeft,
            paddingRight,
        }
    }

    if (height) {
        customStyle = {
            ...customStyle,
            height,
        }
    }

    if (fontSize) {
        customStyle = {
            ...customStyle,
            ...fontSize,
        }
    }

    if (loading) {
        // disable click event ...
        onClick = () => {}
        customStyle = {
            ...customStyle,
            cursor: "not-allowed",
        }
    }

    if (href) {
        customStyle = {
            ...customStyle,
            href,
        }
    }

    let iconFontSize = size === 'normal' ? 18 : 12

    const className = `aj-btn-3 
        ${hoverToBlack ? 'aj-btn-hover-to-black' : ''} 
        aj-btn-${size} 
        ${scheme.length === 0? `aj-btn-${type}-container` : `aj-btn-${scheme}-${type}-container`}
        ${shadow ? 'aj-btn-box-shadow' : ''}`

    return (
        <button className={className}
                style={customStyle}
                disabled={disabled}
                onClick={onClick}>
            <div>
                {loading? (<span> <Icon type="loading"/></span>) : null}
                {loading? <span>&nbsp;&nbsp;</span> : null}
                <div style={{fontSize: fontSize, display: 'inline-block', ...titleStyle}} className="button-title">{title}</div>
                {icon? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : null}
                {icon? (<Icon type={icon} style={{fontSize: iconFontSize}}/>) : null}
            </div>
        </button>
    )
}
