import Axios from 'axios';
import { BlogClient } from 'seobot';
import {
    AJUrl,
    checkTokenPath,
    companyProfilePath,
    companyProfileUpdatePath,
    companyRegisterPath,
    jobOpeningsExtractPath,
    loginPath,
    creationPath,
    talentValueMatchPath,
    favoritePath,
    sendCommunityMailPath,
    getMediumNewsPath,
    resetEmailPasswordPath,
    changeEmailPasswordPath,
    saveClapCountPath,
    sendNFTEmailPath,
    jobOpeningVisitorPath,
    getInterviewQuestionsPath,
    sendPriceRequestPath,
    getAllCompaniesPath
} from "./AJHttpConst";
import {currentToken, getAJNoLoginUUID} from "../storage/AJStorage";
import {getQuestions} from "../config/ValuesConfig";
const axios = Axios.create({
    // headers: {
    //     'Access-Control-Allow-Origin' : '*',
    //     'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // }
})

export const client = new BlogClient('8b3cfee9-3dac-4e25-be21-4ff6877de582');

function getLogonToken() {
    return currentToken()
}

export async function uploadImage() {

    const url = AJUrl('uploadfile')

}

async function httpRequest(requestFun) {

    try {
        const token = getLogonToken()
        const  config = token ? {
            headers: {
                Authorization: `token ${getLogonToken()}`}
        } :  null
        const response = await requestFun(config)

        // console.log(response)

        return response
    }
    catch (e) {
        // TODO test
        // alert(e)
        return null
    }
}


export async function companyLogin(userName, password) {
    return await httpRequest(async () => {
        const data = {
            "username": userName,
            // "email" : userName,
            "password": password,
        };
        return await axios.post(loginPath, data)


    })
}


export async function companyCreation(companyInfo) {
    return await httpRequest(async () => {
        try {
            return await axios.post(creationPath, companyInfo)
        }
        catch (e) {
            alert(JSON.stringify(e.response.data))
            throw e
        }
    })
}

//http://server1:8000/jobextract?url=https://northvolt.com/career&source=jobylon

export async function jobExtract(source, url) {
    return await httpRequest(async (config) => {
        config = {
            ...config,
            params: {
                source,
                url
            }
        }
        return await axios.get(jobOpeningsExtractPath, config)
    })
}

export async function companyRegister(companyRegisterInfo) {
    return await httpRequest(async (config) => {
        return await axios.post(companyRegisterPath, companyRegisterInfo, config)
    })
}

export async function companyProfile(companyId) {
    return await httpRequest(async () => {
        return await axios.get(companyProfilePath, {
            params: {
                companyid : companyId
            }
        })
    })

}

export async function updateCompanyProfile(companyId, profile) {
    return await httpRequest(async (config) => {
        return await axios.put(`${companyProfileUpdatePath}${companyId}/`, profile, config)
    })
}

export async function checkToken(token) {
    return await httpRequest(async () => {
        return await axios.post(checkTokenPath, {
            token
        }, {
            headers: {
                Authorization: `token ${token}`,
            }

        })
    })

}

// export async function talentValueMatch(values, page) {
//     return await httpRequest(async () => {
//         return await axios.post(talentValueMatchPath, {
//             values : values,
//             uuid : getAJNoLoginUUID()
//         }, {
//             params: {
//                 limit: 10,
//                 page
//             }
//         })
//     })
// }

export async function talentValueMatch(values, page, pageSize=8, hasStructure=false) {
    return await httpRequest(async () => {
        return await axios.post(talentValueMatchPath, {
            values : values,
            uuid : getAJNoLoginUUID(),
            companyStructure: hasStructure ? "Yes" : "No"
        }, {
            params: {
                page,
                pageSize: pageSize
            }
        })
    })
}

export async function getFavorite() {
    return await httpRequest(async () => {
        return await axios.get(favoritePath, {
            params: {
                uuid : getAJNoLoginUUID()
            }
        })
    })

}

export async function submitFavorite(companyId) {
    return await httpRequest(async () => {
        return await axios.post(favoritePath, {
            uuid : getAJNoLoginUUID(),
            companyid: companyId
        })
    })
}

export async function removeFavorite(companyId) {
    return await httpRequest(async () => {
        return await axios.delete(favoritePath, {
            params: {
                uuid: getAJNoLoginUUID(),
                companyid: companyId
            }
        })
    })
}

export async function sendCommunityMail(email, name) {
    return await httpRequest(async () => {
        return await axios.post(sendCommunityMailPath, {
            uuid : getAJNoLoginUUID(),
            email: email,
            name
        })
    })
}

export async function jobOpeningVisitor(email, name, jobOpening, company) {
    if (!email) {
        return
    }
    return await httpRequest(async () => {
        return await axios.post(jobOpeningVisitorPath, {
            email,
            jobOpening,
            name,
            company,
            timestamp: new Date().getTime()
        })
    })
}

// export async function subscribeCommunityMail(email) {
//     //https://airjobb.us18.list-manage.com/subscribe/post-json
//     return await httpRequest(async () => {
//         return await axios.ge    t("https://airjobb.us18.list-manage.com/subscribe/post-json", {
//             params : {
//                 u : '8c82cc7caab1e8c7325d17662',
//                 id : '7a0a52ede3',
//                 EMAIL: email
//         }
//
//         })
//     })
// }

export async function getMediumNews() {
    return await httpRequest(async ()=>{
        return await axios.get(getMediumNewsPath)
    })
}

export async function resetEmailPassword(email) {
    return await httpRequest(async () => {
        return await axios.get(resetEmailPasswordPath, {
            params: {
                email : email
            }
        })
    })
}

export async function changePassword(email, token, password1, password2) {
    return await httpRequest(async () => {
        return await axios.post(changeEmailPasswordPath, {
            new_password1: password1,
            new_password2: password2,
            email: email
        }, {
            headers: {
                Authorization: `token ${token}`
            }
        })
    })
}

export async function addClapCount(count, uuid, companyId) {
    return await httpRequest(async () => {
        return await axios.post(saveClapCountPath, {
            uuid: uuid,
            clapcnt: count,
            companyid: companyId
        })
    })
}

export async function sendNFTEmail(user, email) {
    return await httpRequest(async () => {
        return await axios.post(sendNFTEmailPath, {
            params: {
                user,
                email
            }
        })
    })
}


const keyHtml = `<p class=MsoNormal><span style='font-size:11.0pt;font-family:"SF Pro Text";mso-fareast-language:EN-GB'>{{key}}<o:p></o:p></span></p>`
const valueHtml = `<p class=MsoNormal><span style='font-size:11.0pt;font-family:"SF Pro Text";mso-fareast-language:EN-GB'>{{value}}<o:p></o:p></span></p>`
const emptyHtml = `<p class=MsoNormal><span style='font-size:11.0pt;font-family:"SF Pro Text";mso-fareast-language:EN-GB'><o:p>&nbsp;</o:p></span></p>`

export async function getInterviewQuestions(email, values) {
    if (!email) {
        return
    }

    const questions = values.map(value => {
        return {
            value,
            questions: getQuestions(value)
        }
    })
    let html = ``;
    for (const info of questions) {

        html += keyHtml.replace('{{key}}', info.value);
        html += '\n'
        for (let i = 0; i<info.questions.length; ++i) {
            html += emptyHtml;
            html += '\n'
            html += valueHtml.replace('{{value}}', `${i+1}. ${info.questions[i]}`)
            html += '\n'
        }

        html += emptyHtml;
        html += emptyHtml;
        html += '\n'
    }
    html += '\n'


    // console.log(html);

    return await httpRequest(async () => {
        return await axios.post(getInterviewQuestionsPath, {
            email,
            values,
            content: html
        })
    })
}

export async function sendPriceRequest(name, mobile, email, company) {
    return await httpRequest(async () => {
        return await axios.post(sendPriceRequestPath, {
            name,
            mobile,
            email,
            company,
            timestamp: new Date().getTime()
        })
    })
}

export async function getAllCompanies() {
    return await httpRequest(async () => {
        return await axios.get(getAllCompaniesPath)
    })
}
