import React, {useEffect, useState} from "react";
import "./BlogPage.less";
import { useParams, useHistory } from "react-router-dom";
import EmptyLine from "../ui/EmptyLine";
import {client} from "../http/AJHttp";
import AJLoading from "../ui/AJLoading";
import AirJobbFooter from "../component/AirJobbFooter";
import {removeH1Content, BlogsContainer} from "./Insights";


export default function BlogPage(props) {
    let history = useHistory();
    let [latestArticle, setLatestArticle] = useState(null);
    let [isLoading, setIsLoading] = useState(true);
    let { id } = useParams();

    function navigateBack() {
        history.goBack();
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const latestArticle = await client.getArticle(id);
                setLatestArticle(latestArticle);
                setIsLoading(false);
            } catch (error) {
                console.log('fail to fetch single blog here');
            }
        };
        
        fetchData();
    }, []);

    return (
        <>
            <AJLoading visible={isLoading} /> 
            <div className={`page-container ${isLoading ? 'not-show' : null}`}>
                <div className={'single-blog-container'}>
                    <EmptyLine height={30} />
                    <div className="single-blog-title">{latestArticle?.headline}</div>

                    <EmptyLine height={24}/>
                    <img src="/img/blog/blog-pic1.svg" style={{width: '100%'}} />
                    <EmptyLine height={8}/>
                    <div className="photo-owner">Photo by Claire</div>
                    <EmptyLine height={40}/>

                    <div className="single-blog-content" dangerouslySetInnerHTML={{__html: removeH1Content(latestArticle?.html ?? '')}}></div>

                    <EmptyLine height={24}/>
                    <img src="/img/blog/blog-pic2.svg" style={{width: '100%'}} />
                    <EmptyLine height={8}/>
                    <div className="photo-owner">Photo by Carwan</div>
                    <EmptyLine height={80}/>
                </div>

                <div className="more-blogs-container">
                    <EmptyLine height={28}/>
                    <div className="more-blog-title">More Interesting Topics</div>
                    <EmptyLine height={28}/>
                    <BlogsContainer ignoreBlogSlug={id}></BlogsContainer>
                </div>

                <AirJobbFooter/>
            </div>
            
        </>
    )
}
