import React, {useEffect, useState} from "react"
import Logo from "../icons/Logo";
import LogoWithoutText from "../icons/LogoWithoutText";
import _ from 'lodash';

export default function AJLogo(props) {
    let {schema, changeWithScroll} = props;
    schema = schema ?? 'white';
    changeWithScroll = changeWithScroll ?? true;

    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = _.throttle(() => {
        setIsScrolled(window.scrollY > 0);
    }, 300);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        setIsScrolled(window.scrollY > 0);
    }, []);

    let logo = null;
    if (changeWithScroll) {
        logo = isScrolled ? <LogoWithoutText schema={schema} /> : <Logo schema={schema} />;
    } else {
        logo = <Logo schema={schema} />
    }

    return <>{logo}</>
}
