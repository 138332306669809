import React from "react";
import EmptyLine from "../../ui/EmptyLine";
import {useHistory} from "react-router";
import {AJButton5} from "../../ui/AJButton3";
import "./JobCard.less";
import {AJImageUrl} from "../../http/AJHttpConst";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


export default function JobCard(props) {
    const {number, data} = props
    const image = AJImageUrl(data.logoUrl)
    const history = useHistory()

    if (data.type === 'skeleton') {
        return <JobCardSkeleton/>;
    }

    return (
        <div className={'positions-card'}>
            <EmptyLine height={20}/>
            <div className={'company-logo'}>
                <img src={image} alt={data.company_name}/>
            </div>
            <EmptyLine height={10}/>
            <div className={'positions-card-info-number'}>
                {number}</div>
            <EmptyLine height={2}/>
            <div className={'positions-card-info-position'}>Open positions</div>
            <EmptyLine height={18}/>
            <div style={{margin: 'auto', textAlign: "center"}}>
                <AJButton5
                    title={'Visit profile'}
                    type={'secondary'}
                    scheme={'secondary'}
                    height={46}
                    width={269}
                    size={'small'}
                    shadow={false}
                    titleStyle={{
                        fontSize: 24,
                        fontWeight: 400,
                        fontFamily: 'manropeRegular',
                        color: '#181414'
                    }}
                    customStyle={{
                        border: '1.6px solid #000000',
                        borderRadius: 2,
                        background: 'transparent'
                    }}
                    onClick={() => {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'custom_event',
                            'eventCategory':'profile',
                            'eventAction': 'click',
                            'eventlabel': data.company_name
                    });
                    history.push(`/company-profile/${data.company_id}`)
                }}/>
            </div>

        </div>
    )
}

function JobCardSkeleton({index}) {

    return <div className={'positions-card'}>
        <EmptyLine height={20}/>
        <div className={'company-logo'}>
            <Skeleton
                circle
                height="100%"
                containerClassName="avatar-skeleton"
            />
        </div>
        <EmptyLine height={10}/>
        <div className={'positions-card-info-number'}>
            <Skeleton width={60} />
        </div>
        <EmptyLine height={2}/>
        <div className={'positions-card-info-position'}>
            <Skeleton width={140} />
        </div>
        <EmptyLine height={18}/>
        <div style={{margin: 'auto', textAlign: "center"}}>
            <Skeleton width={269} height={46} />
        </div>

    </div>;
}
