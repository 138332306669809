import React, {useEffect} from "react";
import "./ContactUs.less"
import EmptyLine from "../ui/EmptyLine";
import {hideContact} from "../App";

export default function ContactUs(props) {
    const onClose = function () {
        hideContact()
        if (props.onClose) {
            props.onClose()
        }
    }

    return (
        <div className={'contact-us'} hidden={props.hidden} id={'aj-contact'}>
            <div className={'contact-us-close'} onClick={onClose}>
                <img src={'/img/close.svg'}/>
            </div>
            <EmptyLine height={59}/>
            <div className={'contact-us-container'}>
                <div style={{width: 314, height: 314, margin: 'auto'}}>
                    <img src={'/img/mail.svg'}/>
                </div>
            </div>
            <EmptyLine height={24}/>
            <div className={'contact-us-title'}>
                Contact us
            </div>
            <EmptyLine height={40}/>
            <div className={'contact-us-body'}>
                <div className={'contact-us-body-content-title'}>
                    Phone
                </div>
                <div className={'contact-us-body-content'}>
                    <a href={'tel:+46 73 280 22 61'}>+46 73 280 22 61</a>
                </div>
            </div>
            <EmptyLine height={24}/>
            <div className={'contact-us-body'}>
                <div className={'contact-us-body-content-title'}>
                    Mail
                </div>
                <div className={'contact-us-body-content'}>
                    <a href={'mail:team@airjobb.com'}>team@genvalues.com</a>
                </div>

            </div>
        </div>
    )
}
