import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';
import './main.less';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import { getFavorite } from "./http/AJHttp";
import {getAJNoLoginUUID, clearAJNoLoginUUID, setAJNoLoginUUID} from "./storage/AJStorage";
import {UserInfoProvider} from "./component/company/UserInfoContext";


// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
// ScreenOrientation.lock('portrait')
window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

async function appInit () {
    if (getAJNoLoginUUID()) {
        const response = await getFavorite();
        if (response && response.data && Array.isArray(response.data)) {
            console.log("uuid right here");
        } else {
            clearAJNoLoginUUID();
            setAJNoLoginUUID();
        }
    } else {
        setAJNoLoginUUID();
    }
    
    ReactDOM.render(
      <React.StrictMode>
          <BrowserRouter>
            <UserInfoProvider>
                <App />
            </UserInfoProvider>
          </BrowserRouter>
      </React.StrictMode>,
      document.getElementById('root')
    );
    
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}

appInit();
