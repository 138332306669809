import React, {useState} from "react";
import "./ClapComponent.less";
import {addClapCount} from "../../http/AJHttp";
import Claps from "../../icons/Claps";
import {getAJNoLoginUUID, getClapCount, saveClapCount} from "../../storage/AJStorage";
import useLongPress from "../../hooks/useLongPress";
import LazyComponent from "../LazyComponent";
const ClapButton = React.lazy(() => import('../react-clap-button/index'))

export default function ClapComponent(props) {
    let {profileData} = props
    const onLongPress = () => {
        // console.log('longpress is triggered');
    }

    const onClick = () => {
        if (child) {
            child.onClick()
        }
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    }

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions)
    const [child, setChild] = useState(null)
    const [count, setCount] = useState(profileData.clapTotal || profileData.favorite || 0)

    return (
        <div style={{display: "inline-block", verticalAlign: 'middle'}}>
            <span style={{verticalAlign: 'inherit'}}>
                <LazyComponent>
                    <ClapButton
                        onRef={(ref)=> {setChild(ref)}}
                        count={getClapCount(props.profileData.id)}
                        countTotal={count}
                        maxCount={50}
                        isClicked={false}
                        unclicked={false}
                        longPressEvent={longPressEvent}
                        onCountChange={() => {
                            setCount(count + 1);
                            saveClapCount(getClapCount(profileData.id) + 1, profileData.id)
                            addClapCount(getClapCount(profileData.id), getAJNoLoginUUID(), profileData.id)
                        }}
                        onLongTap={() => {

                        }}
                        style={{border: 'none', background: 'transparent'}}
                        iconComponent={props => <Claps {...props} style={{marginTop: -4}} size={38} /> }
                    />
                </LazyComponent>
            </span>
            <div className={'clap-count'} style={{marginLeft: -6}}>
                {count}
            </div>
        </div>
    )
}
