import React from "react";

function SvgMenuIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="2" fill={props.fill}/>
        <rect width="20" height="2" transform="translate(0 18)" fill={props.fill}/>
        <rect width="16" height="2" transform="translate(4 9)" fill={props.fill}/>
    </svg>

);
}

export default SvgMenuIcon;

