import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import {useHistory} from 'react-router';
import './bubbleLogo.less';
import {gotoLink} from "../../component/Navigator";
import { Modal } from 'antd-mobile';
import EmptySpan from "../../ui/EmptySpan";

const BubbleLogoButton = forwardRef(({img, containerWidthHeight, from, to, companyId, companyName, currentIndex, forceUpdate}, ref) => {
  useImperativeHandle(ref, () => {
    return {
      updatePosition: updatePosition
    }
  });

  let [sx, setSx] = useState({width: `105px`, height: `105px`, position: 'absolute', display: 'none'});
  const boxRef = useRef(null);
  let history = useHistory();
  let [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (from || to || img || containerWidthHeight) {
      updatePosition(from, to);
    }
  }, [from, to, img, containerWidthHeight, forceUpdate]);

  useEffect(() => {
    const handleAnimationEnd = (event) => {
      if (event.animationName.includes('slide-from')) {
        // scroll the axis to keep the bubble logos in the center
        setTimeout(() => {
          const parentElement = document.getElementById('bubble-logo-container');
          const totalBubbleNum = JSON.parse(sessionStorage.getItem('cachedSelectedValues'))?.bubbleNum ?? 0;
          if (parentElement) {
            if (boxRef.current) {
              boxRef.current.style.left = `${boxRef.current.getBoundingClientRect().left  }px`;
              boxRef.current.style.top = `${boxRef.current.getBoundingClientRect().top  }px`;
              boxRef.current.style.animation = '';
            }
            
            setTimeout(() => {
              if (boxRef.current) {
                boxRef.current.style.animation = getBubbleFloatingAnimation();
                const scrollTop = (parentElement.scrollHeight - parentElement.clientHeight) / 2;
                parentElement.scrollTop = scrollTop;
                if (totalBubbleNum>=24) parentElement.scrollLeft = 65;
              }
            }, 600);
          }
        }, 100);
      }
    };

    if (boxRef.current) {
      boxRef.current.addEventListener('animationend', handleAnimationEnd);
    }

    return () => {
      if (boxRef.current) {
        boxRef.current.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, []);

  function getKeyframes(keyframesName) {
    try {
      let styleSheet = document.styleSheets;
      let animation = {};
      for (let i = 0; i < styleSheet.length; i++) {
        for (let j = 0; j < styleSheet[i].cssRules.length; j++) {
          if (styleSheet[i].cssRules[j].constructor.name === 'CSSKeyframesRule') {
            if (keyframesName === styleSheet[i].cssRules[j].name) {
              animation.cssRules = styleSheet[i].cssRules[j];
              animation.index = j;
              animation.styleSheet = styleSheet[i];
              return animation;
            }
          }
        }
      }
      return undefined;
    } catch {
      return undefined;
    }
  }

  function insertKeyFrames(keyframesName) {
    let styleSheet = document.styleSheets[0];
    styleSheet.insertRule(keyframesName, 0);
  }

  function modifyPosition(toPosition) {
    const totalBubbleNum = JSON.parse(sessionStorage.getItem('cachedSelectedValues'))?.bubbleNum ?? 0;
    // If the position x or y is smaller than 0, which means it is outside the border, we should modify it
    // x axis
    if (totalBubbleNum>=24) {
      toPosition[0] = (Number(toPosition[0]) + 65).toString();
    }

    // y axis
    if (totalBubbleNum>=4 && totalBubbleNum<=15) {
      const toPosition4 = getPositionCoordinate(4, containerWidthHeight[0], containerWidthHeight[1]);
      if (Number(toPosition4[1]) < 0) {
        toPosition[1] = (Number(toPosition[1]) + (-Number(toPosition4[1]))).toString();
      }
    }

    // y axis
    if (totalBubbleNum>=16) {
      const toPosition16 = getPositionCoordinate(16, containerWidthHeight[0], containerWidthHeight[1]);
      if (Number(toPosition16[1]) < 0) {
        toPosition[1] = (Number(toPosition[1]) + (-Number(toPosition16[1]))).toString();
      }
    }
  }

  function updatePosition(fromPositionIndex, toPositionIndex) {
    boxRef.current.style.left = '0px';
    boxRef.current.style.top = '5px';

    // get the position of from and to positions
    let fromPosition, toPosition;
    if (fromPositionIndex === 0) {
      // if it is a new bubble, it should fly in from the edge of the page
      fromPosition = getOriginStartPosition(toPositionIndex, containerWidthHeight[0], containerWidthHeight[1]);
    } else {
      fromPosition = getPositionCoordinate(fromPositionIndex, containerWidthHeight[0], containerWidthHeight[1]);
    }
    toPosition = getPositionCoordinate(toPositionIndex, containerWidthHeight[0], containerWidthHeight[1]);

    // If some bubbles are not visible, we should modify their position
    modifyPosition(toPosition);

    setSx({...sx, width: `${getBubbleSize(toPositionIndex)}px`, height: `${getBubbleSize(toPositionIndex)}px`, display: 'block'});

    const keyFrames = getKeyframes(`slide-from-${fromPositionIndex}-${fromPosition[0]}-${fromPosition[1]}-${containerWidthHeight[0]}-to-${toPositionIndex}-${toPosition[0]}-${toPosition[1]}-${containerWidthHeight[1]}`);
    if (keyFrames === undefined) {
      let newKeyframes = `@keyframes slide-from-${fromPositionIndex}-${fromPosition[0]}-${fromPosition[1]}-${containerWidthHeight[0]}-to-${toPositionIndex}-${toPosition[0]}-${toPosition[1]}-${containerWidthHeight[1]} {\n` +
      '            from {\n' +
      `                -webkit-transform: translate(${fromPosition[0]}px, ${fromPosition[1]}px);\n` +
      `                        transform: translate(${fromPosition[0]}px, ${fromPosition[1]}px);\n` +
      '            }\n' +
      '            to {\n' +
      `                -webkit-transform: translate(${toPosition[0]}px, ${toPosition[1]}px);\n` +
      `                        transform: translate(${toPosition[0]}px, ${toPosition[1]}px);\n` +
      '            }\n' +
      '        }';
      insertKeyFrames(newKeyframes);
    }
    boxRef.current.style.animation = `slide-from-${fromPositionIndex}-${fromPosition[0]}-${fromPosition[1]}-${containerWidthHeight[0]}-to-${toPositionIndex}-${toPosition[0]}-${toPosition[1]}-${containerWidthHeight[1]} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards`;
  }

  function getBubbleFloatingAnimation() {
    const randomNum = Math.floor(Math.random() * 6) + 1;
    const isDelay = randomNum % 2 === 0 ? '1s' : '';
    return `floating${randomNum} 10s ease-in-out ${isDelay} infinite both`;
  }

  function sin(angle) {
    const radians = angle * (Math.PI / 180);
    return Math.sin(radians);
  }

  function cos(angle) {
    const radians = angle * (Math.PI / 180);
    return Math.cos(radians);
  }

  function getPositionCoordinate(positionIndex, width, height) {
    const centralX = Number((width/2).toFixed(0));
    const centralY = Number((height/2).toFixed(0));
    const marginRight = 0.3*centralX;
    const scale = Number(((-0.15/294)*centralX + (0.85 - 1024 * (-0.15/294))).toFixed(2));
    const radiusOne = Number((((centralX - marginRight)/3)*scale).toFixed(0));
    const radiusTwo = 2.8 * radiusOne;
    const radiusThree = 4.2 * radiusOne;
    const halfBubbleSize = getBubbleSize(positionIndex)/2;

    switch (positionIndex) {
      case 1: {
        return [(centralX - halfBubbleSize).toFixed(0), (centralY - radiusOne - halfBubbleSize).toFixed(0)];
      }
      case 2: {
        return [(centralX - cos(30)*radiusOne - halfBubbleSize).toFixed(0), (centralY + sin(30)*radiusOne - halfBubbleSize).toFixed(0)];
      }
      case 3: {
        return [(centralX + cos(30)*radiusOne - halfBubbleSize).toFixed(0), (centralY + sin(30)*radiusOne - halfBubbleSize).toFixed(0)];;
      }
      case 4: {
        return [(centralX + sin(0)*radiusTwo - halfBubbleSize).toFixed(0), (centralY - cos(0)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 5: {
        return [(centralX + sin(30)*radiusTwo - halfBubbleSize).toFixed(0), (centralY - cos(30)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 6: {
        return [(centralX + sin(60)*radiusTwo - halfBubbleSize).toFixed(0), (centralY - cos(60)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 7: {
        return [(centralX + radiusTwo - halfBubbleSize).toFixed(0), (centralY - halfBubbleSize).toFixed(0)];
      }
      case 8: {
        return [(centralX + sin(60)*radiusTwo - halfBubbleSize).toFixed(0), (centralY + cos(60)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 9: {
        return [(centralX + sin(30)*radiusTwo - halfBubbleSize).toFixed(0), (centralY + cos(30)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 10: {
        return [(centralX + sin(0)*radiusTwo - halfBubbleSize).toFixed(0), (centralY + cos(0)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 11: {
        return [(centralX - sin(30)*radiusTwo - halfBubbleSize).toFixed(0), (centralY + cos(30)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 12: {
        return [(centralX - sin(60)*radiusTwo - halfBubbleSize).toFixed(0), (centralY + cos(60)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 13: {
        return [(centralX - sin(90)*radiusTwo - halfBubbleSize).toFixed(0), (centralY + cos(90)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 14: {
        return [(centralX - sin(60)*radiusTwo - halfBubbleSize).toFixed(0), (centralY - cos(60)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 15: {
        return [(centralX - sin(30)*radiusTwo - halfBubbleSize).toFixed(0), (centralY - cos(30)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 16: {
        return [(centralX + sin(15)*radiusThree - halfBubbleSize).toFixed(0), (centralY - cos(15)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 17: {
        return [(centralX + sin(45)*radiusThree - halfBubbleSize).toFixed(0), (centralY - cos(45)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 18: {
        return [(centralX + sin(75)*radiusThree - halfBubbleSize).toFixed(0), (centralY - cos(75)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 19: {
        return [(centralX + sin(75)*radiusThree - halfBubbleSize).toFixed(0), (centralY + cos(75)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 20: {
        return [(centralX + sin(45)*radiusThree - halfBubbleSize).toFixed(0), (centralY + cos(45)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 21: {
        return [(centralX + sin(15)*radiusThree - halfBubbleSize).toFixed(0), (centralY + cos(15)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 22: {
        return [(centralX - sin(15)*radiusThree - halfBubbleSize).toFixed(0), (centralY + cos(15)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 23: {
        return [(centralX - sin(45)*radiusThree - halfBubbleSize).toFixed(0), (centralY + cos(45)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 24: {
        return [(centralX - sin(75)*radiusThree - halfBubbleSize).toFixed(0), (centralY + cos(75)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 25: {
        return [(centralX - sin(75)*radiusThree - halfBubbleSize).toFixed(0), (centralY - cos(75)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 26: {
        return [(centralX - sin(45)*radiusThree - halfBubbleSize).toFixed(0), (centralY - cos(45)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 27: {
        return [(centralX - sin(15)*radiusThree - halfBubbleSize).toFixed(0), (centralY - cos(15)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      default: {
        return [0, 0];
      }
    }
  }

  const bubbleSize = {
    1: 75,
    2: 75,
    3: 75,
    4: 45,
    5: 45,
    6: 45,
    7: 45,
    8: 45,
    9: 45,
    10: 45,
    11: 45,
    12: 45,
    13: 45,
    14: 45,
    15: 45,
    16: 36,
    17: 36,
    18: 36,
    19: 36,
    20: 36,
    21: 36,
    22: 36,
    23: 36,
    24: 36,
    25: 36,
    26: 36,
    27: 36
  }

  function getBubbleSize(positionIndex) {
    return Object.keys(bubbleSize).includes(positionIndex.toString()) ? bubbleSize[positionIndex] : 0;
  }

  function getOriginStartPosition(positionIndex, width, height) {
    const centralX = Number((width/2).toFixed(0));
    const centralY = Number((height/2).toFixed(0));
    const marginRight = 0.34*centralX - 158;
    const scale = Number(((-0.15/294)*centralX + (0.85 - 1024 * (-0.15/294))).toFixed(2));
    const radiusOne = Number((((centralX - marginRight)/3)*scale).toFixed(0));
    const radiusTwo = 2 * radiusOne;
    const radiusThree = 3 * radiusOne;
    const halfBubbleSize = getBubbleSize(positionIndex)/2;
    
    switch (positionIndex) {
      case 1: {
        return [0, (centralY - radiusOne - halfBubbleSize).toFixed(0)];
      }
      case 2: {
        return [0, (centralY + sin(30)*radiusOne - halfBubbleSize).toFixed(0)];
      }
      case 3: {
        return [2 * centralX, (centralY + sin(30)*radiusOne - halfBubbleSize).toFixed(0)];;
      }
      case 4: {
        return [(centralX + sin(0)*radiusTwo - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 5: {
        return [(centralX + sin(30)*radiusTwo - halfBubbleSize).toFixed(0), 0];
      }
      case 6: {
        return [2 * centralX, (centralY - halfBubbleSize).toFixed(0)];
      }
      case 7: {
        return [2 * centralX, (centralY + cos(54)*radiusTwo - halfBubbleSize).toFixed(0)];
      }
      case 8: {
        return [(centralX + sin(19)*radiusTwo - halfBubbleSize).toFixed(0), 2 * centralY];
      }
      case 9: {
        return [(centralX - sin(19)*radiusTwo - 4 * halfBubbleSize).toFixed(0), 2 * centralY];
      }
      case 10: {
        return [(centralX - sin(54)*radiusTwo - 4 * halfBubbleSize).toFixed(0), 2 * centralY];
      }
      case 11: {
        return [0, (centralY - halfBubbleSize).toFixed(0)];
      }
      case 12: {
        return [(centralX - sin(54)*radiusTwo - 4 * halfBubbleSize).toFixed(0), 0];
      }
      case 13: {
        return [(centralX - sin(19)*radiusTwo - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 14: {
        return [(centralX + sin(50)*radiusThree - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 15: {
        return [2 * centralX, (centralY - cos(75)*radiusThree - 4 * halfBubbleSize).toFixed(0)];
      }
      case 16: {
        return [2 * centralX, (centralY + cos(75)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 17: {
        return [(centralX + sin(54)*radiusThree - halfBubbleSize).toFixed(0), 2 * centralY];
      }
      case 18: {
        return [0, 2 * centralY];
      }
      case 19: {
        return [0, (centralY + cos(75)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 20: {
        return [0, (centralY - cos(75)*radiusThree - halfBubbleSize).toFixed(0)];
      }
      case 21: {
        return [(centralX - sin(50)*radiusThree - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 22: {
        return [(centralX - sin(50)*radiusThree - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 23: {
        return [(centralX - sin(50)*radiusThree - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 24: {
        return [(centralX - sin(50)*radiusThree - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 25: {
        return [(centralX - sin(50)*radiusThree - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 26: {
        return [(centralX - sin(50)*radiusThree - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      case 27: {
        return [(centralX - sin(50)*radiusThree - halfBubbleSize).toFixed(0), 0 - 2 * halfBubbleSize];
      }
      default: {
        return [0, 0];
      }
    }
  }

  function handleModalOpen(companyId) {
    gotoLink(`/company-profile/${companyId}`, history);
  }

  return (
    <div>
      <div ref={boxRef} className={'logo-button'} style={sx} 
        onClick={()=>{
          if (!modalVisible) {setModalVisible(true);}
        }}
      >
        <img src={img} alt='company logo'></img>
      </div>

      <Modal
        visible={modalVisible}
        className={"company-modal"}
        wrapClassName={'company-modal-wrapper'}      
        animated={false}
        popup={true}
        maskClosable={true}
        closable={false}
      >
        <div className={"modal-title"}>
            You are not yet done with the value selection. Do you still want to visit <span style={{'fontWeight': '700'}}>{companyName}</span>'s profile?
        </div>

        <div className={"button-container"}>
            <button className={'modal-button'} onClick={()=> handleModalOpen(companyId)}>Visit the profile</button>
            <EmptySpan width={13}/>
            <button className={'modal-button'} style={{'background': '#FFD865', 'border': '1px solid #FFD865'}}
            onClick={() => setModalVisible(false)}
            >Continue selection</button>
        </div>

      </Modal>
    </div>
  )
});

export default BubbleLogoButton;
