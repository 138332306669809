import React from "react";
import "./PrivacyPage.less"
import EmptyLine from "../ui/EmptyLine";
import AirJobbFooter from "../component/AirJobbFooter";


export default function PrivacyPage() {
    return (
        <>
        <div className={'privacy-page'}>
            <div className={'privacy-page-container'}>
                <div className={'privacy-page-header'}>
                    <img src={'/img/privacy-page-header.png'} width={'100%'}/>
                    <div className={'privacy-page-title'}>
                        Privacy
                    </div>

                </div>
            </div>
            <div className={'privacy-page-content'}>
                <img src={'/img/privacy-notice.svg'} width={'100%'}/>
            </div>

            <EmptyLine height={200}/>

        </div>
            <AirJobbFooter/>
            </>
    )
}
