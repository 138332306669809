import React from "react";
import {useState} from "react";

import "./PriceRequestCard.less"
import {InputItem, Toast} from "antd-mobile";
import EmptyLine from "../../ui/EmptyLine";
import {jobOpeningVisitor, sendCommunityMail, sendPriceRequest} from "../../http/AJHttp";
import {setJoinedCommunity} from "../../storage/AJStorage";
import AJButton3, {AJButton4} from "../../ui/AJButton3";


export default function PriceRequestCard({onSend}) {
    const [disabled, setDisabled] = useState(true)
    const [sending, setSending] = useState(false)
    const [fullName, setFullName] = useState('')
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('')
    const [companyName, setCompanyName] = useState('')
    onSend = onSend || (() => {})

    const validation = (fullName, mobileNumber, email, companyName) => {
        const emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/;
        const phonePattern = /^[0-9\-\+]+$/;;

        if (emailPattern.test(email) && phonePattern.test(mobileNumber) && fullName.length > 0 && companyName.length > 0) {
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }
    }
    return <div className={"price-request-card-container"}>
        <EmptyLine height={56}/>
        <InputItem
            placeholder={'Full Name'}
            value={fullName}
            onChange={(newValue) => {
                setFullName(newValue)
                validation(newValue, mobileNumber, email, companyName)
            }}
        />
        <EmptyLine height={45}/>
        <InputItem
            placeholder={'Mobile Number'}
            value={mobileNumber}
            onChange={(newValue) => {
                setMobileNumber(newValue)
                validation(fullName, newValue, email, companyName)
        }}/>
        <EmptyLine height={45}/>
        <InputItem
            placeholder={'E-mail'}
            value={email}
            onChange={(newValue) => {
                setEmail(newValue)
                validation(fullName, mobileNumber, newValue, companyName)
        }}/>
        <EmptyLine height={45}/>
        <InputItem
            placeholder={'Company Name'}
            value={companyName}
            onChange={(newValue) => {
                setCompanyName(newValue)
                validation(fullName, mobileNumber, email, newValue)
        }}/>
        <EmptyLine height={80}/>

        <div className={'price-request-button'}>
            <AJButton4 title={'Send'}
                       size={'mini'}
                       width={'100%'}
                       type={'secondary'}
                       loading={sending}
                       height={71}
                       fontSize={38}
                       // scheme={'white'}
                        disabled={disabled}
                       onClick={
                           async () => {
                               setSending(true)
                               const response = await sendPriceRequest(fullName, mobileNumber, email, companyName)
                               if (response) {
                                   Toast.info("Send successfully!")
                               }
                               else {
                                   Toast.fail("Send failed!")
                               }
                               setSending(false)
                           }
                       }/>
        </div>



    </div>
}