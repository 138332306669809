import React, {useState} from 'react'
import EmptyLine from "../ui/EmptyLine";

import "./NFTPage.less"
import AirJobbFooter from "../component/AirJobbFooter";
import {AJCenterButton} from "../ui/AJButton3";
import {InputItem, Toast} from "antd-mobile";
import {sendNFTEmail} from "../http/AJHttp";


export default function NFTPage() {
    return (
        <>
            <div className={'insights-page'}>
                <div className={'insights-page-container'}>
                    <div className={'insights-page-header'}>
                        <img src={'/img/nft-header.png'} width={'100%'}/>
                    </div>
                    <EmptyLine height={31}/>
                    <div className={'nft-page-text-container'}>
                        <div className={'nft-page-title'}>
                            Genvalues first<br/> NFT collection
                        </div>
                        <EmptyLine height={14}/>
                        <img src={'/img/nft-pic.png'} alt={'nft'} width={'100%'} height={'100%'}/>
                        <EmptyLine height={25}/>

                        <div className={'nft-page-body'}>
                            Genvalues was created with the vision to inspire the next generation of value-driven talents do work align with their values and drive innovations for good.<br/><br/>

                            We believe that humans are creative species craving to explore and have the power to overcome any challenge. We are many who wants to make a difference in the world. And in the spirit of all that, we are launching a unique <a href={'https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/1025043156764980159683949676761650636587266656588422543951746458399807635473'} target="_blank">NFT</a> giveaway that symbolizes the urgency and importance of this matter.<br/><br/>

                            The inspiration behind this art also inspired by the the Netflix movie "Don't Look Up."<br/><br/>

                            There are 17 unique NFT:s, which<br/> referes to the 17 Sustianable<br/> Development Goals.<br/>
                            You will get exclusive access to the coming NFT projects on Genvalues related to the subject if you hold a specific NFT number.
                        </div>
                        <br/><br/><br/><br/>
                        <NFTCard/>
                    </div>

                </div>
            </div>
            <EmptyLine height={172}/>
            <AirJobbFooter/>
        </>

    )
}

const NFTCard = () => {
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [loading, setLoading] = useState(false);
    return <div className={'nft-card'}>
        <div className={'nft-card-title'}>
            Win an NFT art
        </div>
        <EmptyLine height={60}/>
        <InputItem placeholder={'Full Name'} onChange={(e) => {
            setName(e);
        }}/>
        <EmptyLine height={10}/>
        <InputItem placeholder={'Email'} onChange={(e) => {
            setEmail(e);
        }}/>
        <EmptyLine height={39}/>
        <AJCenterButton title={'Send'}
                        size={'small'}
                        scheme={'white'}
                        loading={loading}
                        height={38}
                        width={'100%'}
                        disabled={!email || !name}
                        onClick={async () => {
                            setLoading(true);
                            const response = await sendNFTEmail(name, email)
                            if (response) {
                                Toast.success('Send NFT mail successfully!')
                            }
                            else {
                                Toast.fail('Something wrong with sending NFT mail.')
                            }
                            setLoading(false);
                        }}/>
    </div>
}