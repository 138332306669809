import EmptyLine from "../ui/EmptyLine";
import AirJobbFooter from "../component/AirJobbFooter";
import React from "react";
import {useHistory} from "react-router";
import "./TalentPage.less";
import JoinCommunity from "../component/JoinCommunity";
import { gotoLink } from "../component/Navigator";
import { AJButton4 } from "../ui/AJButton3";
import CarouselContainer from "../component/startV2/carouselContainer";
import { talentCardContent } from "../config/ValuesConfig";
import { CeoCard } from "../page/StartPageV2";


export default function TalentPage(props) {
    const history = useHistory();

    return (
        <>
            <div className={'static-page'}>
                <div className={'static-page-container'}>
                    <div className={'static-page-header'}>
                        <img src={'/img/talent/talent-header.png'} width={'100%'} alt={'company page header'}/>
                        <div className={'static-page-title'} style={{fontFamily: 'BespokeStencilBold'}}>
                            Talent
                        </div>
                    </div>
                    <EmptyLine height={32}/>
                    <div className={'static-page-text-container'}>
                        <div className={'static-page-text-title'}>
                            Find a Team You <br/>
                            Want To Work With
                        </div>
                        <EmptyLine height={32}/>
                        <div className={'static-page-text-body'}>

                            At Genvalues, we recognize that engineering isn't just about technical skills—it's about finding a team that works in harmony with your principles and contributing to a positive impact. This synergy is where creativity thrives. We're dedicated to matching you with teams that share your approach to technology, ensuring that your work is more than just a job, but a reflection of your beliefs.<br/><br/>

                            In the fast-paced tech world, where precision and adaptability are key, being part of the right team can immensely boost your professional development and creative output. Join a team where open communication and innovative problem-solving aren't just encouraged but are the norm.<br/><br/>

                            We connect you with engineering teams that not only use agile methodologies but also value continuous learning, and practice sustainability in their product development. These aren't mere buzzwords to us; they're vital components that shape how technology influences society.<br/><br/>

                        </div>
                        <EmptyLine height={24}/>

                    </div>
                </div>
                <img src={'/img/talent/talent-pic-1-v2.png'} width={'100%'} alt={'talent page pic 1'}/>
                <EmptyLine height={52}/>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <AJButton4
                        title={'True Fit'}
                        size={'mini'}
                        width={193}
                        type={'secondary'}
                        height={45}
                        titleStyle={{
                            fontSize: 18,
                            fontWeight: 400,
                            fontFamily: "InterRegular",
                            color: '#000000'
                        }}
                        hoverToBlack={true}
                        onClick={() => {
                            gotoLink('/toolbox', history);
                        }}/>
                </div>
                <EmptyLine height={42}/>

                <div className={'static-page-container'}>
                    <div className={'static-page-text-container'}>
                        <div className={'static-page-text-title'}>
                            Crafted Your Career
                        </div>
                        <EmptyLine height={32}/>
                        <div className={'static-page-text-body'}>

                            Choosing a team that resonates with your principles means your work contributes to technological progress while staying true to your personal values.<br/><br/>

                            Genvalues is more than a job-finding platform. It's where your talents are celebrated, and your values find a voice. In an environment that reflects your ideals, every task is a chance to grow, and every project becomes a platform for your innovation.<br/><br/>

                        </div>
                        <EmptyLine height={24}/>
                    </div>
                </div>

                <img src={'/img/talent/talent-pic-2-v2.png'} width={'100%'} alt={'talent page pic 2'}/>
                <EmptyLine height={32}/>

                <div className={'static-page-container'}>
                    <div className={'static-page-text-container'}>
                        <div className={'static-page-text-title'}>
                            5 Key advantages with<br/>Genvalues
                        </div>
                        <EmptyLine height={32}/>
                        <div className={'static-page-text-body'}>
                            <ul style={{paddingLeft: 17}}>
                                <li>
                                    <b>Find the Right Team for You: </b>Connect your enthusiasm for teamwork and innovation with a team that shares your outlook for a more rewarding career.
                                </li>
                                <EmptyLine height={23}/>
                                <li>
                                    <b>Feel Appreciated and Excel: </b>In a team that values your input, you're inspired to deliver your best work.
                                </li>
                                <EmptyLine height={23}/>
                                <li>
                                    <b>Work with Meaning: </b>When your personal goals and your company's objectives match, you'll naturally feel more motivated and satisfied, leading to better performance.
                                </li>
                                <EmptyLine height={23}/>
                                <li>
                                    <b>Better Team Dynamics, More Innovation: </b>For example, if your team values open communication and transparency, you can share ideas and insights easily and drive progress much easier.
                                </li>
                                <EmptyLine height={23}/>
                                <li>
                                    <b>More Than Just a Job: </b>Be part of a team that cares about social responsibility and aligns with your values for a deeper sense of connection and a more fulfilling, balanced career.
                                </li>
                            </ul>
                            <EmptyLine height={40}/>
                        </div>

                        <div className="title-container">
                            <img src={"/img/home/talentSaying.svg"}></img>
                        </div>

                        <CarouselContainer>
                            {
                                talentCardContent.map(item => {
                                    return <CeoCard item={item}></CeoCard>
                                })
                            }
                        </CarouselContainer>

                        <EmptyLine height={90}/>
                        <JoinCommunity/>
                    </div>

                </div>


                <EmptyLine height={74}/>
            </div>
            <AirJobbFooter/>
        </>
    )
}