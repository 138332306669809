import React from "react";

function Logo(props) {
    let {schema} = props;

    return (
        <svg width="149" height="36" viewBox="0 0 149 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17.3897" cy="31.8057" r="3.59283" fill={schema}/>
            <circle cx="17.4597" cy="22.4636" r="1.94013" fill={schema}/>
            <circle cx="17.4607" cy="16.572" r="1.07785" fill={schema}/>
            <circle cx="11.7839" cy="13.4811" r="1.94013" fill={schema}/>
            <circle cx="23.2097" cy="13.4811" r="1.94013" fill={schema}/>
            <circle cx="3.59283" cy="19.9737" r="3.59283" fill={schema}/>
            <circle cx="31.2374" cy="19.9737" r="3.59283" fill={schema}/>
            <circle cx="9.05377" cy="3.59283" r="3.59283" fill={schema}/>
            <circle cx="25.7217" cy="3.59283" r="3.59283" fill={schema}/>
            <path d="M140.389 15.0282C140.389 13.1129 141.904 11.6653 144.576 11.5762H145.133C146.157 11.6207 147.16 11.888 147.984 12.2443L147.583 14.0928C146.692 13.7588 145.846 13.5583 145.088 13.5583C143.908 13.5583 143.218 14.1151 143.218 14.8501C143.218 14.9391 143.24 15.006 143.262 15.0728C143.574 16.654 148.14 16.7431 148.362 19.7498C148.385 19.9057 148.385 20.0616 148.385 20.2397C148.362 20.3956 148.34 20.5515 148.295 20.6852H145.4C145.467 20.4847 145.489 20.2843 145.445 20.0393C145.044 18.2576 140.389 18.2353 140.389 15.0282ZM143.819 21.5315H147.961C147.427 22.645 146.157 23.4245 144.309 23.5136C144.108 23.5359 143.908 23.5359 143.708 23.5359C142.394 23.4691 141.035 23.0459 140.211 22.5782L140.679 20.7297C141.681 21.1974 142.772 21.5315 143.819 21.5315Z" fill={schema}/>
            <path d="M137.699 22.7119C136.63 23.2018 135.116 23.5582 133.78 23.5582C130.261 23.5582 128.078 21.1529 128.078 17.5672C128.078 16.32 128.345 15.2287 128.835 14.3824H131.486C131.152 14.8501 130.907 15.5405 130.818 16.4536L135.383 16.4091C135.517 15.006 135.071 13.5361 133.446 13.5361H129.414C130.328 12.2666 131.82 11.5762 133.557 11.5762C137.187 11.5762 138.479 14.5383 137.855 17.7676C136.519 18.1462 133.045 18.2353 130.862 18.1908C131.085 20.5738 132.532 21.3978 134.27 21.3978C135.294 21.3978 136.452 21.1529 137.298 20.8633L137.699 22.7119Z" fill={schema}/>
            <path d="M119.215 21.2865C119.794 21.2865 120.351 21.1306 120.863 20.8633C120.885 21.5537 121.063 22.1551 121.286 22.5337C120.328 23.2241 119.193 23.5359 118.124 23.5359C116.052 23.5359 114.738 22.4001 114.738 19.9502V12.1107L117.5 11.5762V19.1707C117.5 20.4847 117.834 21.2865 119.215 21.2865ZM124.627 23.5359C122.801 23.5359 121.776 22.2887 121.776 20.3065V11.8434H124.538V20.1284C124.538 20.9747 124.916 21.4869 125.651 21.4869C125.874 21.4869 126.074 21.4647 126.319 21.4201V23.1127C125.896 23.3577 125.295 23.5359 124.627 23.5359Z" fill={schema}/>
            <path d="M111.187 23.2677H108.426V6.83139L111.187 6.29688V23.2677Z" fill={schema}/>
            <path d="M99.9048 11.5762C102.956 11.5762 104.003 12.9793 104.003 15.8523V16.1863H101.286C101.174 14.6942 100.796 13.781 99.2367 13.781C98.4126 13.781 97.2991 13.9815 95.896 14.4492L95.4951 12.4225C97.1209 11.9102 98.3904 11.5762 99.9048 11.5762ZM99.0808 21.6428C99.5039 21.6428 99.9939 21.5315 100.439 21.3756C100.528 21.9324 100.684 22.4223 100.929 22.8232C100.038 23.2686 99.0585 23.5359 98.1454 23.5359C96.1632 23.5359 94.8047 22.3555 94.8047 20.4624C94.8047 18.3912 96.4082 17.3222 99.5485 17.1217C99.9048 17.0995 100.261 17.0772 100.618 17.0772H100.796L102.132 17.0549H104.003V19.4157C104.003 19.772 104.025 20.1061 104.07 20.3956C104.136 21.1083 104.493 21.4869 105.183 21.4869C105.384 21.4869 105.606 21.4647 105.829 21.4424V23.1127C105.517 23.2909 105.094 23.4468 104.582 23.4913C104.27 23.5136 103.958 23.5136 103.669 23.4913C102.466 23.3354 101.709 22.6228 101.464 21.2865C101.375 20.507 101.308 19.6607 101.308 18.703H100.217C99.9716 18.703 99.7489 18.7253 99.5708 18.7253C98.0118 18.8144 97.4327 19.3711 97.4327 20.262C97.4327 21.1751 98.0563 21.6428 99.0808 21.6428Z" fill={schema}/>
            <path d="M85.0679 11.5762L89.099 23.2686H86.4041L82.4844 12.0884L85.0679 11.5762ZM93.5087 11.8434L89.8562 22.4446L88.6313 18.6807L90.9252 11.8434H93.5087Z" fill={schema}/>
            <path d="M73.0585 11.5762V23.2686H70.2969V12.1107L73.0585 11.5762ZM80.2967 15.1619V23.2686H77.5351V16.2309C77.5351 14.6273 77.1787 13.8479 75.7756 13.8479C75.152 13.8479 74.5284 13.9815 73.9494 14.2265V12.2889C74.818 11.8212 75.8202 11.5762 76.8001 11.5762C78.8936 11.5762 80.2967 12.712 80.2967 15.1619Z" fill={schema}/>
            <path d="M66.8517 22.7119C65.7827 23.2018 64.2682 23.5582 62.9319 23.5582C59.4131 23.5582 57.2305 21.1529 57.2305 17.5672C57.2305 16.32 57.4977 15.2287 57.9877 14.3824H60.638C60.3039 14.8501 60.0589 15.5405 59.9698 16.4536L64.5355 16.4091C64.6691 15.006 64.2237 13.5361 62.5979 13.5361H58.5667C59.4799 12.2666 60.9721 11.5762 62.7092 11.5762C66.3395 11.5762 67.6312 14.5383 67.0076 17.7676C65.6713 18.1462 62.197 18.2353 60.0144 18.1908C60.2371 20.5738 61.6847 21.3978 63.4219 21.3978C64.4464 21.3978 65.6045 21.1529 66.4508 20.8633L66.8517 22.7119Z" fill={schema}/>
            <path d="M48.8173 23.6695C48.2383 23.625 47.7037 23.5804 47.147 23.5136C46.6125 23.9813 46.2784 24.5826 46.2784 25.3621C46.2784 25.429 46.2784 25.518 46.3007 25.5849C46.412 26.6316 47.3697 27.2775 48.9509 27.2775C49.8863 27.2775 50.7327 27.0993 51.3117 26.8543V28.7474C50.6213 28.9701 49.7527 29.1037 48.8619 29.1037C45.6993 29.1037 43.8953 27.812 43.7617 26.0526V25.6739C43.8508 24.5158 44.5858 23.6918 45.7439 22.9568C45.098 22.5114 44.6748 21.821 44.6748 21.0415C44.6748 20.9747 44.6748 20.9301 44.6971 20.8633C44.7639 20.0393 45.2762 19.438 45.9666 18.9257C44.9421 18.2353 44.3408 17.144 44.3408 15.6518C44.3408 15.585 44.3408 15.5182 44.363 15.4514C44.4521 12.9347 46.5011 11.5762 49.1514 11.5762C50.0868 11.5762 50.9331 11.7543 51.6458 12.0661L55.0978 11.5762V13.6029H50.2204C49.9754 13.5806 49.6859 13.5583 49.4186 13.5583C49.1514 13.4915 48.8841 13.4692 48.5946 13.5138C47.5701 13.6697 46.9465 14.4937 46.9465 15.6518C46.9465 15.83 46.9688 15.9859 47.0133 16.1418C47.1915 17.2108 47.971 17.879 49.0846 17.879C50.3986 17.879 51.2672 17.0327 51.2672 15.6964C51.2672 15.2064 51.1335 14.8055 50.9554 14.4714H53.6279C53.7838 14.8278 53.8506 15.2287 53.8506 15.6964C53.8506 18.3244 51.7571 19.7275 49.0178 19.7275H48.8396C48.2605 19.7052 47.7483 19.6384 47.2806 19.4825C47.0579 19.7275 46.9243 19.9947 46.9243 20.3288C46.9243 20.4847 46.9465 20.6183 47.0133 20.7297C47.1247 21.1306 47.5256 21.3756 48.0601 21.4424C48.216 21.4424 48.3719 21.4647 48.5278 21.4647L50.844 21.5537C50.9331 21.5537 50.9999 21.576 51.0667 21.576C53.3384 21.7319 54.6747 22.89 54.786 24.7608C54.786 24.9613 54.786 25.1394 54.7638 25.3399C54.6301 26.743 53.6947 27.7897 52.1803 28.3688V25.9635C52.1803 25.4512 52.1135 24.9835 51.9353 24.5826C51.6681 24.0259 51.0445 23.7586 50.02 23.7141L48.8173 23.6695Z" fill={schema}/>
        </svg>
    );
}

export default Logo;
