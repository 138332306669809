import React from "react";
import "./TermsConditions.less"
import EmptyLine from "../ui/EmptyLine";
import AirJobbFooter from "../component/AirJobbFooter";


export default function TermsConditions() {
    return (
        <>
            <div className={'terms-conditions'}>
                <div className={'terms-conditions-container'}>
                    <div className={'terms-conditions-header'}>
                        <img src={'/img/terms-conditions-header.png'} width={'100%'}/>
                        <div className={'terms-conditions-title'}>
                            Terms & <br/> Conditions
                        </div>

                    </div>
                </div>
                <div className={'terms-conditions-content'}>
                    <img src={'/img/terms-conditions.svg'} width={'100%'}/>
                </div>

                <EmptyLine height={200}/>

            </div>
            <AirJobbFooter/>
        </>
    )
}
