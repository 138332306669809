import React from "react";

function SvgLeftIcon(props) {
    let {fill='white', background='black', width="'48'"} = props;

    return (
        <svg width={width} height={width} viewBox="0 0 48 48" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="23" transform="rotate(-180 24 24)" stroke={background} stroke-width="2"/>
            <path d="M26.8237 33.8824L17.9012 26.0918C17.6006 25.8351 17.3589 25.5149 17.1931 25.1536C17.0273 24.7923 16.9414 24.3986 16.9414 24C16.9414 23.6015 17.0273 23.2078 17.1931 22.8464C17.3589 22.4851 17.6006 22.1649 17.9012 21.9083L26.8237 14.1177" stroke={background} stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
    );
}

export default SvgLeftIcon;
