import React from "react";
import EmptySpan from "../ui/EmptySpan";
import SvgLinkedin from "../icons/Linkedin";
import SvgFacebook from "../icons/Facebook";
import SvgMedium from "../icons/Medium";
import SvgYoutube from "../icons/Youtube";
import SvgInstagram from "../icons/Instagram";
import EmptyLine from "../ui/EmptyLine";

export default function SocialMediaIcons(props) {
    let {space, height, width, color, onClick, socials, backgroundColor} = props
    color = color || '#fff'
    space = space || 24
    height = height || 24
    width = width || 24
    onClick = onClick || ((company) => {
        const link = socials[company]
        if (link && link.startsWith('http')) {
            window.open(socials[company])
        }
    })

    socials = socials || {
        linkedin : '',
        facebook : '',
        twitter : '',
        instagram: '',
        medium : '',
        youtube: ''
    }
    backgroundColor = backgroundColor || 'black'

    const contains = (key) => {
        return socials.hasOwnProperty(key) && socials[key] != null && socials[key] !== ''
    }

    return (
        <div className={'company-icon-links'}>

            <span hidden={!contains('linkedin')}>
                <SvgLinkedin height={height} width={width} fill={color} style={{
                    cursor: 'pointer'
                }} onClick={(e) => {
                    onClick('linkedin')
                }}/>
            <EmptySpan width={space}/>
            </span>

            <span hidden={!contains('facebook')}>
            <SvgFacebook height={height} width={width} fill={color} style={{
                cursor: 'pointer'
            }} onClick={(e) => {
                onClick('facebook')
            }}/>
            <EmptySpan width={space}/>
            </span>

            <span hidden={!contains('twitter')}>
            <img src={backgroundColor === 'black' ? '/img/menu/twitter.svg' : '/img/menu/twitter-black.svg'} 
                top={-7}
                height={height} width={width}
                style={{'height': '27px', 'position': 'relative', 'bottom': backgroundColor === 'black' ? '11px' : '7px'}}
                onClick={(e) => onClick('twitter')}></img>
            <EmptySpan width={space}/>
            </span>

            <span hidden={!contains('instagram')}>
            <SvgInstagram height={height} width={width} fill={color} style={{
                cursor: 'pointer'
            }} onClick={(e) => {
                onClick('instagram')
            }}/>
            <EmptySpan width={space}/>
            </span>

            <span hidden={!contains('medium')}>
            <SvgMedium height={height} width={width} fill={color} style={{
                cursor: 'pointer'
            }} onClick={(e) => {
                onClick('medium')
            }}/>
            <EmptySpan width={space}/>
            </span>

            <span hidden={!contains('youtube')}>
            <SvgYoutube height={height} width={width} fill={color} style={{
                cursor: 'pointer'
            }} onClick={(e) => {
                onClick('youtube')
            }}/>
            </span>

        </div>
    )
}

export function SocialMediaIcons2Line(props) {
    let {space, height, width, color, onClick, socials} = props
    color = color || '#fff'
    space = space || 24
    height = height || 24
    width = width || 24
    onClick = onClick || ((company) => {
        const link = socials[company]
        if (link && link.startsWith('http')) {
            window.open(socials[company])
        }
    })

    socials = socials || {
        linkedin : '',
        facebook : '',
        twitter : '',
        instagram: '',
        medium : '',
        youtube: ''
    }

    const contains = (key) => {
        return socials.hasOwnProperty(key) && socials[key] != null
    }

    return (
        <>
            <div className={'company-icon-links'}>

                <span hidden={!contains('linkedin')}>
                    <SvgLinkedin height={height} width={width} fill={color} style={{
                        cursor: 'pointer'
                    }} onClick={(e) => {
                        onClick('linkedin')
                    }}/>
                <EmptySpan width={space}/>
                </span>

                <span hidden={!contains('facebook')}>
                <SvgFacebook height={height} width={width} fill={color} style={{
                    cursor: 'pointer'
                }} onClick={(e) => {
                    onClick('facebook')
                }}/>
                <EmptySpan width={space}/>
                </span>

                <span hidden={!contains('twitter')}>
                <img src={'/img/menu/twitter.svg'} 
                    style={{'width': '21px', 'height': '21px', 'position': 'absolute'}}
                    onClick={(e) => onClick('twitter')}></img>
                <EmptySpan width={space}/>
                </span>
            </div>

            <EmptyLine height={14}/>

            <div className={'company-icon-links'}>
                <span hidden={!contains('instagram')}>
                <SvgInstagram height={height} width={width} fill={color} style={{
                    cursor: 'pointer'
                }} onClick={(e) => {
                    onClick('instagram')
                }}/>
                <EmptySpan width={space}/>
                </span>

                <span hidden={!contains('medium')}>
                <SvgMedium height={height} width={width} fill={color} style={{
                    cursor: 'pointer'
                }} onClick={(e) => {
                    onClick('medium')
                }}/>
                <EmptySpan width={space}/>
                </span>

                <span hidden={!contains('youtube')}>
                <SvgYoutube height={height} width={width} fill={color} style={{
                    cursor: 'pointer'
                }} onClick={(e) => {
                    onClick('youtube')
                }}/>
                </span>
            </div>
        </>
    )
}
