import React, {useState} from "react";
import "./JobOpenings.less";
import EmptyLine from "../../ui/EmptyLine";
import {AJButton5} from "../../ui/AJButton3";
import {Modal} from "antd-mobile";
import JoinOurTalent from "../common/JoinOurTalent";
import "../common/JoinOurTalent.less";
import {getJoinedCommunityEmail, getJoinedCommunityName, 
    getJoinedCommunityEmailInSessionStorage, 
    getJoinedCommunityNameInSessionStorage} from "../../storage/AJStorage";
import {jobOpeningVisitor} from "../../http/AJHttp";
import LazyComponent from "../LazyComponent";
import { useUserInfoContext } from "./UserInfoContext";

const OpenJobsContainer = React.lazy(() => import('../common/openJobsContainer'))


export default function JobOpenings(props) {
    const [value, setValue] = useState(0)

    const {data, company} = props
    let jobOpenings = [...data]

    const onChange = (value) => {
        setValue(value)
    }

    const cardWidth = props.cardWidth || (288)
    const windowWidth = window.innerWidth

    const slidesPerPage = Math.floor(windowWidth/cardWidth)
    const sliderPerDot = slidesPerPage - 1 || 1
    const dotNumber = Math.ceil(jobOpenings.length/sliderPerDot)
    const missCard = sliderPerDot * dotNumber - jobOpenings.length
    const showDot = jobOpenings.length > sliderPerDot

    const emptyJobOpenings = (
        <div className={'empty-job-openings-container'}>
            <div className={'empty-job-openings'}>
                <div style={{textAlign: 'center'}}>
                    We will soon hire amazing<br/> people like you.<br/><br/> Follow us and learn more<br/> about our values.
                </div>
            </div>
        </div>
    )

    return (
        <div className={'job-openings'}>
            <div className={'job-openings-card-container'} hidden={jobOpenings.length===0}>
                <LazyComponent>
                    <OpenJobsContainer content={jobOpenings} company={company} />
                </LazyComponent>
            </div>
            <div hidden={jobOpenings.length!==0}>
                {emptyJobOpenings}
            </div>
        </div>
    )
}

export function JobCard(props) {
    let {title, description, onClick, location, type, href, company} = props
    const [showModal, setShowModal] = useState(false);
    if (description) {
        location = description[0]
        type = description[1]
    }

    onClick = onClick || function () {}

    let [isHovered, setIsHovered] = useState(false)
    const { emailContext, nameContext } = useUserInfoContext();

    return (
        <div className={'jobcard'}
            onTouchStart={()=>{setIsHovered(true)}}
            onTouchEnd={()=>{setIsHovered(false)}}>
            <div className={'jobcard-container'}>
                <div className="border">
                    <EmptyLine height={27}/>
                    <div className={'jobcard-title'}>
                        <div style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            '-webkit-line-clamp': '2',
                            '-webkit-box-orient': 'vertical',
                            display: '-webkit-box'
                        }}>
                            {title}
                        </div>
                    </div>
                    <div className={'jobcard-description-location'}>
                        {location}
                    </div>
                    <EmptyLine height={7}/>
                    <AJButton5
                    title={'Read more'}
                    type={'secondary'}
                    scheme={'secondary'}
                    height={53}
                    width={'100%'}
                    size={'small'}
                    shadow={false}
                    titleStyle={{
                        fontSize: 19,
                        fontWeight: 400,
                        fontFamily: 'InterRegular',
                        color: '#181414'
                    }}
                    customStyle={{
                        border: '1px solid #000000',
                        borderRadius: 2,
                        background: 'transparent'
                    }}
                    onClick={async ()=>{
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'custom_event',
                            'eventCategory': 'apply',
                            'eventAction': `${title}`,
                            'eventlabel': `${company}`
                        });
                        let email = getJoinedCommunityEmail()
                        let name = getJoinedCommunityName()
                        if (!(email && name)) {
                            // a workaround: some browsers are disabled for localStorage
                            email = getJoinedCommunityEmailInSessionStorage()
                            name = getJoinedCommunityNameInSessionStorage()
                        }
                        if (!(email && name)) {
                            // a workaround: some browsers are disabled for localStorage
                            // use context provider to cache the email and name
                            email = emailContext
                            name = nameContext
                        }
                        if (email && name) {
                            // save email and job id
                            window.open(href);
                            await jobOpeningVisitor(email, name, href, company)
                        } else {
                            setShowModal(true);
                        }
                    }}/>
                </div>
            </div>
            <Modal
                visible={showModal}
                closable={true}
                wrapClassName={'modal-wrapper'}
                onClose={() => {
                    setShowModal(false)
                    window.open(href)
                }}
                maskClosable={true}
            >
                <JoinOurTalent company={company} url={href} onClose={(noRedirect) => {
                    setShowModal(false);
                    if (!noRedirect) {
                        window.open(href);
                    }
                }}/>
            </Modal>
        </div>
    )
}
