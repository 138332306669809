import * as React from "react"

import {useHistory, useParams} from "react-router-dom";
import {useEffect} from "react";
import {setJoinedCommunity} from "./storage/AJStorage";
import {jobOpeningVisitor, sendCommunityMail} from "./http/AJHttp";

export default function OAuthCallback() {
    const history = useHistory();
    const params = new URLSearchParams(history.location.search)
    const email = params.get('email');
    const name = params.get('name');
    const state = params.get('state');
    const {redirectUrl, company} = JSON.parse(decodeURIComponent(state))

    useEffect(async () => {
        if (email) {
            await sendCommunityMail(email, name)
            await jobOpeningVisitor(email, name, redirectUrl, company)
            setJoinedCommunity(email);
            window.location.href = redirectUrl;
        }
    }, []);

    return <div/>;
}