import React, {useState} from "react";
import "./scrollTab.less";

export default function ScrollTab({onIndexChange}) {
	const tabList = [
		"Team culture",
		"Guiding principles",
		"Career development",
		"Workplace culture",
		"Strategy",
		"Health & Wellbeing",
		"Sustainability"
	]

	let [currentIndex, setCurrentIndex] = useState(0);
	const [isHintHovered, setIsHintHovered] = useState(false);
	let [typeHintSx, setTypeHintSx] = useState({});

	function handleArrowClick(direction) {
		if (isHintHovered) {
			setIsHintHovered(false);
		}
		let tempData = direction === "left" ? currentIndex - 1 : currentIndex + 1;
		setCurrentIndex(tempData);
		onIndexChange(tempData);
	}

	function handleHintPicPosition(index) {
		switch (index) {
			case 0: {
				return [-150, 232];
			}
			case 1: {
				return [-150, 254];
			}
			case 2: {
				return [-150, 254];
			}
			case 3: {
				return [-150, 254];
			}
			case 4: {
				return [-150, 254];
			}
			case 5: {
				return [-150, 254];
			}
			case 6: {
				return [-150, 275];
			}
		}
	}

	return (
		<div style={{'width': '100%'}}>
			<div style={{'left': '0px', 'position': 'absolute'}} className={`switch-button ${currentIndex <= 0 ? 'not-show' : ''}`} onClick={() => handleArrowClick('left')}>
				<img style={{'width': '42px', 'position': 'relative', 'left': '-7px'}} src={'/img/value-select/chevron-left.svg'}/>
			</div>

			<span className="tab-title">{tabList[currentIndex]}</span>

			<img
				id={`hint-${currentIndex}`}
				className={`type-hint ${!isHintHovered ? 'not-show' : ''}`}
				style={typeHintSx}
				src={`/img/value-select/value-hint-text-${currentIndex + 1}.svg`}/>
			
			<img
				id={"question-img"}
				onClick={()=>{
					if (!isHintHovered) {
						const img = document.getElementById("question-img").getBoundingClientRect();					
						const position = handleHintPicPosition(currentIndex);
						setTypeHintSx({...typeHintSx, 'top': `${position[0]}px`, 'left': `${img.x - 218}px`});
					}
					setIsHintHovered(!isHintHovered);
				}}
				style={{'width': '14px', 'marginLeft': '-11px', 'position': 'absolute', 'top': '6px', 'user-select': 'none'}}
				src={'/img/value-select/question-mark.svg'}>
			</img>

			<div style={{'right': '0px', 'position': 'absolute'}} className={`switch-button ${currentIndex >= tabList.length-1 ? 'not-show' : ''}`} onClick={() => handleArrowClick('right')}>
				<img style={{'width': '33px', 'position': 'relative', 'right': '21px'}} src={'/img/value-select/chevron-right.svg'}/>
			</div>
		</div>
	);
};
