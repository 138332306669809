import React, {useEffect, useState} from "react";
import "./SavedCompanies.less"
import {Flex, List, SwipeAction, Toast} from "antd-mobile";
import {getFavorite, removeFavorite} from "../http/AJHttp";
import EmptyLine from "../ui/EmptyLine";
import AirJobbFooter from "../component/AirJobbFooter";
import {useHistory} from "react-router";
import AJLoading from "../ui/AJLoading";


export default function SavedCompanies(props) {

    const [favoriteData, setFavoriteData] = useState( [])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        fetchFavorite()
    }, [])

    const fetchFavorite = async () => {
        const response = await getFavorite()
        if (response) {
            setFavoriteData(response.data)
        }
        else {
            Toast.fail('Get favorite data failed!')
        }

        setLoading(false)
    }

    // const favoriteData = props.favoriteData
    const refreshFavorite = props.refreshFavorite

    return (
        <>
            <AJLoading visible={loading}/>
        <div className={'saved-companies'}>
            <div className={'saved-companies-container'}>
                <div className={'saved-companies-header'}>
                    <img src={'/img/saved-companies-header.png'} width={'100%'}/>
                    <div className={'saved-companies-title'}>
                        Saved<br/> Companies
                    </div>

                </div>
            </div>

            {/*<List>*/}
                {
                    favoriteData.map(favorite => {
                        return <FavoriteItem favorite={favorite} onDelete={async () => {
                            const leftData = favoriteData.filter(f => f.company !== favorite.company)
                            setFavoriteData(leftData)
                            setTimeout(async () => {
                                const response = await removeFavorite(favorite.company)
                                if (response) {
                                    await refreshFavorite()
                                }
                                else {
                                    fetchFavorite()
                                    Toast.fail(`Remove favorite ${favorite.companyname} failed`)
                                }
                            })

                        }}/>
                    })
                }

            {/*</List>*/}

        </div>
            <EmptyLine height={60}/>
            <AirJobbFooter/>
            </>
    )

}

function FavoriteItem({favorite, onDelete}) {
    const history = useHistory()
    return (
        <>
        <SwipeAction style={{ backgroundColor: '#6E6E6E', boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.15)' }}
                     autoClose
                     right={[
                         {
                             text: <img src={'/img/delete.svg'}/>,
                             onPress: onDelete,
                             style: { backgroundColor: '#6E6E6E', color: 'white', width: 89 },
                         }]}>
            <div className={'favorite-item'} onClick={() => {
                history.push(`/company-profile/${favorite.company}`)
            }}>
                <Flex>
                    <Flex.Item>
                        <div className={'favorite-item-primary'} style={{textAlign: 'left'}}>
                            {favorite.companyname}
                        </div>
                    </Flex.Item>
                    <Flex.Item>
                        <div className={'favorite-item-primary'} style={{textAlign: 'right'}}>
                            {favorite.matchedvaluecnt}/9
                        </div>
                    </Flex.Item>
                </Flex>
                <EmptyLine height={3}/>
                <Flex>
                    <Flex.Item>
                        <div className={'favorite-item-secondary'} style={{textAlign: 'left'}}>
                            {favorite.companylocation}
                        </div>
                    </Flex.Item>
                    <Flex.Item>
                        <div className={'favorite-item-secondary'} style={{textAlign: 'right'}}>
                            Value Match
                        </div>
                    </Flex.Item>
                </Flex>

            </div>
        </SwipeAction>
        <EmptyLine height={3}/>
        </>
        )
}
