import React, {useLayoutEffect, useRef, useState} from "react";
import "./JoinOurTalent.less";
import EmptyLine from "../../ui/EmptyLine";
import LinkedinLogin from "./LinkedinLogin";
import {jobOpeningVisitor, sendCommunityMail} from "../../http/AJHttp";
import {setJoinedCommunity, setJoinedCommunityInSessionStorage} from "../../storage/AJStorage";
import {useOnClickOutside} from "../../hooks/common";
import AJButton3 from "../../ui/AJButton3";
import {InputItem} from "antd-mobile";
import { useUserInfoContext } from "../company/UserInfoContext";


export default function JoinOurTalent({emailWidth, onClose, url, company}) {
    emailWidth = emailWidth || 300;
    const [sending, setSending] = useState(false);
    const [disable, setDisable] = useState(true);
    const ref = useRef();
    const uuidRef = useRef('email-input');
    const uuidRef2 = useRef('name-input');
    const { emailContext, setEmailContext, nameContext, setNameContext } = useUserInfoContext();

    useOnClickOutside(ref, [], () => {
        onClose && onClose(true);
    });

    function onChange() {
        const id = uuidRef.current
        const email = document.getElementById(id).value
        const id2 = uuidRef2.current
        const name = document.getElementById(id2).value
        const pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/;
        if (pattern.test(email) && name.length > 0) {
            setDisable(false);
        }
        else {
            setDisable(true);
        }
    }

    return <div className={'join-our-talent-container-2'} ref={ref}>
        <div className={'join-our-talent-header-2'}>
            <img src={'/img/join-community-header.png'} alt={'Join Community'} width={'100%'} height={'100%'}/>
        </div>

        <EmptyLine height={13}/>

        <div className={'join-our-talent-title-2'}>
            Join Our Talent <br/>Network
        </div>

        <EmptyLine height={25}/>
        <div style={{textAlign: 'center', margin: '0 16px'}}>
            <div className="join-our-talent-input">
                <InputItem placeholder={'Your name'}
                           id={uuidRef2.current}
                           onChange={onChange}
                           onClick={() => {
                               document.getElementById(uuidRef2.current).focus();
                           }}/>
                <InputItem placeholder={'Your email'}
                           id={uuidRef.current}
                           onChange={onChange}
                           onClick={() => {
                               document.getElementById(uuidRef.current).focus();
                           }}/>
                <div style={{width: '100%'}}></div>
            </div>
        </div>
        <EmptyLine height={30}/>

        <div className={'join-our-talent-button-2'}>
            <AJButton3 title={'Send'}
                       size={'mini'}
                       width={202}
                       type={'secondary'}
                       loading={sending}
                       height={38}
                       disabled={disable}
                       onClick={
                           async () => {
                               const id = uuidRef.current
                               const email = document.getElementById(id).value
                               const id2 = uuidRef2.current
                               const name = document.getElementById(id2).value

                               setSending(true)
                               const response = await sendCommunityMail(email, name)
                               await jobOpeningVisitor(email, name, url, company)
                               if (response) {
                                setJoinedCommunityInSessionStorage(email, name)
                                setJoinedCommunity(email, name)
                                if (!(emailContext && nameContext)) {
                                    setEmailContext(email)
                                    setNameContext(name)
                                }
                               }
                               setSending(false)
                               onClose && onClose();
                           }
                       }/>
        </div>
        <EmptyLine height={22}/>
        <div className={'join-our-talent-fast-signup'}>
            Fast Signup
        </div>
        <EmptyLine height={6}/>
        <div className={'join-our-talent-linkedin-2'}>
            <LinkedinLogin redirectUrl={url} onClose={onClose} company={company}/>
        </div>
        <EmptyLine height={8}/>
        <div className={'join-our-talent-privacy-2'} onClick={() => {
            window.open('/privacy')
        }}>
            Privacy
        </div>

    </div>
}
