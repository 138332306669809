import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from 'react-router'
import {WingBlank} from 'antd-mobile';
import EmptyLine from "../ui/EmptyLine";
import "./StartPageV2.less";
import {getAllCompanies} from "../http/AJHttp";
import JoinCommunity from "../component/JoinCommunity";
import AirJobbFooter from "../component/AirJobbFooter";
import {AJButton4} from "../ui/AJButton3";
import {gotoLink} from "../component/Navigator";
import Slider from '../component/image-scroll-player/imageScrollPlayer';
import { companyWithHighPriority, companyWithSecondPriority, shouldIgnoreCompany, talentCardContent, ceoCardContent } from '../config/ValuesConfig';
import CarouselContainer from "../component/startV2/carouselContainer";

export default function StartPage(props) {
    const history = useHistory();
    const location = useLocation();
    const [searching, setSearching] = useState(false);
    const [companyLogoUrlLineOne, setCompanyLogoUrlLineOne] = useState([]);
    const [companyLogoUrlLineTwo, setCompanyLogoUrlLineTwo] = useState([]);
    const [companyLogoUrlLineThree, setCompanyLogoUrlLineThree] = useState([]);
    const [jobNum, setJobNum] = useState(0);
    const [needLoadingCompanyLogos, setNeedLoadingCompanyLogos] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const cachedData = JSON.parse(sessionStorage.getItem('cachedCompanyInfo'));
        if (cachedData) {
            getCompanyLogoUrl(cachedData);
            return;
        }
        setNeedLoadingCompanyLogos(true);
        fetchAllCompanyData();
    }, [location, history])

    const fetchAllCompanyData = async () => {
        if (searching) {
            return
        }
        setSearching(true)

        const response = await getAllCompanies()
        if (response) {
            if (response.data && response.data.length > 0) {
                sessionStorage.setItem('cachedCompanyInfo', JSON.stringify(response.data));
                getCompanyLogoUrl(response.data);
                setNeedLoadingCompanyLogos(false);
            }
        }
        else {
            console.log("search all company logo failed");
        }
        setSearching(false)
    }

    function getCompanyLogoUrl(companyInfo) {
        let jobNum = 0;
        let tmpLineOne = [];
        let tmpLineTwo = [];
        let tmpOther = [];
        for (let idx in companyInfo) {
            if (companyInfo[idx]?.companyStructure?.logoUrl && !shouldIgnoreCompany.includes(companyInfo[idx].name.toLowerCase())) {
                if (companyWithHighPriority.includes(companyInfo[idx].name.toLowerCase())) {
                    tmpLineOne.push(['https://m.genvalues.com/' + companyInfo[idx].companyStructure.logoUrl, companyInfo[idx].name, companyInfo[idx].id, companyInfo[idx].published]);
                } else if (companyWithSecondPriority.includes(companyInfo[idx].name.toLowerCase()) || idx % 2) {
                    tmpLineTwo.push(['https://m.genvalues.com/' + companyInfo[idx].companyStructure.logoUrl, companyInfo[idx].name, companyInfo[idx].id, companyInfo[idx].published]);
                } else {
                    tmpOther.push(['https://m.genvalues.com/' + companyInfo[idx].companyStructure.logoUrl, companyInfo[idx].name, companyInfo[idx].id, companyInfo[idx].published]);
                }
            }

            if(companyInfo[idx].published && companyInfo[idx].jobOpenings) {
                jobNum += companyInfo[idx].jobOpenings.length;
            }
        }
        setCompanyLogoUrlLineOne(tmpLineOne);
        setCompanyLogoUrlLineTwo(tmpLineTwo);
        setCompanyLogoUrlLineThree(tmpOther);
        setJobNum(jobNum);
    }

    function navigateToCompanyPage(companyId, isPublished) {
        if(isPublished) gotoLink(`/company-profile/${companyId}`, history)
    }

    return (
        <div className={"start-page"}>
            <div className={'start-page-container'}>
                <div className={'value-promotion'}>
                    <img src={"/img/home/title.svg"}></img>
                </div>

                <EmptyLine height={60}/>

                <div style={{width: '100%', textAlign: 'center'}}>
                    <AJButton4
                        title={'Start Matching'}
                        size={'mini'}
                        width={237}
                        type={'secondary'}
                        height={40}
                        titleStyle={{
                            fontSize: 19,
                            fontWeight: 400,
                            fontFamily: "InterRegular",
                            color: '#000000'
                        }}
                        hoverToBlack={true}
                        onClick={() => {
                            gotoLink('/value-select', history)
                        }}/>
                </div>

                <EmptyLine height={42}/>
            </div>

            <div className={'logo-scroll-container'}>
                {
                    needLoadingCompanyLogos ? 
                    <div className="loading-container">
                        <img src={"/img/home/loading.svg"} style={{"width": "30px"}}></img>
                    </div> : 
                    <div>
                        {jobNum ? <div className={"job-num"}>{jobNum} top Engineering jobs</div> : <></>}
                        <Slider durationInMs="4500" start="0">
                            {
                                companyLogoUrlLineOne.map(logo => {
                                    return (
                                        <img className={'logo-img-scroll'}
                                         style={{cursor: logo[3] ? 'pointer' : ''}}
                                         src={`${logo[0]}`}
                                         onClick={()=>navigateToCompanyPage(logo[2], logo[3])}></img>
                                    )
                                })
                            }
                        </Slider>
                        <Slider durationInMs="4500" start="0" customContainerStyle={{'padding-left': '60px', 'margin-top': '20px', 'margin-bottom': '20px'}}>
                            {
                                companyLogoUrlLineTwo.map(logo => {
                                    return (
                                        <img className={'logo-img-scroll'}
                                         style={{cursor: logo[3] ? 'pointer' : ''}}
                                         src={`${logo[0]}`}
                                         onClick={()=>navigateToCompanyPage(logo[2], logo[3])}></img>
                                    )
                                })
                            }
                        </Slider>
                        <Slider durationInMs="4500" start="0">
                            {
                                companyLogoUrlLineThree.map(logo => {
                                    return (
                                        <img className={'logo-img-scroll'}
                                         style={{cursor: logo[3] ? 'pointer' : ''}}
                                         src={`${logo[0]}`}
                                         onClick={()=>navigateToCompanyPage(logo[2], logo[3])}></img>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                }
                <EmptyLine height={22}/>
            </div>

            <div style={{'position': 'relative', 'text-align': 'center'}}>
                <img style={{'width': '100%'}} src={"/img/home/steps.svg"}></img>
                <img style={{'width': '80%', 'position': 'relative', 'bottom': '70px'}}
                    src={isHovered ? '/img/home/talentLinkBlack.svg' : '/img/home/talentLink.svg'}
                    onClick={() => {gotoLink('/talent', history)}}
                    onMouseEnter={()=>{setIsHovered(true)}}
                    onMouseLeave={()=>{setIsHovered(false)}}></img>
            </div>

            <div className="title-container">
                <img src={"/img/home/talentSaying.svg"}></img>
            </div>
            
            <CarouselContainer>     
                {
                    talentCardContent.map(item => {
                        return <CeoCard item={item}></CeoCard>
                    })
                }
            </CarouselContainer>

            <EmptyLine height={33}/>

            <div className="title-container">
                <img src={"/img/home/ceoSaying.svg"}></img>
            </div>

            <CarouselContainer>
                {
                    ceoCardContent.map(item => {
                        return <CeoCard item={item}></CeoCard>
                    })
                }
            </CarouselContainer>

            <EmptyLine height={52}/>

            <WingBlank>
                <JoinCommunity/>
            </WingBlank>
            <EmptyLine height={40}/>
            <AirJobbFooter/>

            <div style={{'display': 'none'}}>
                <img src={"/img/value-select/select-hint.svg"}></img>
                <img src={"/img/value-select/select-hint-2.svg"}></img>
            </div>

        </div>
    )
}

export function CeoCard(props) {
    let {item} = props;

    return (
        <div className="ceo-card-container">
            <div className="border">
                <div>
                    <img src="./img/home/quote.svg"></img>
                </div>
                <div className="ceo-description">
                    {item[0]}
                </div>
                <div className="ceo-picture">
                    <img src={`./img/home/${item[1]}.svg`}></img>
                </div>
            </div>
        </div>
    );
 }
 