import React, {useEffect, useState} from "react";
import "./CompanyProfile.less";
import {useHistory, useParams} from "react-router";
import {Toast, WingBlank} from "antd-mobile";
import {Collapse, Icon} from 'antd';
import {companyProfile} from "../http/AJHttp";
import {arrayContains} from "../utils/ArrayUtils";
import JobOpenings from "../component/company/JobOpenings";
import EmptyLine from "../ui/EmptyLine";
import JoinCommunity from "../component/JoinCommunity";
import AirJobbFooter from "../component/AirJobbFooter";
import ValueMedia, {getOrderedValues} from "../component/company/ValueMedia";
import CompanyTechs from "../component/company/CompanyTechs";
import AJLoading from "../ui/AJLoading";
import {AJImageUrl} from "../http/AJHttpConst";
import ClapComponent from "../component/company/ClapComponent";
import {valuePatch} from "../config/ValuesConfig";
import SocialMediaIcons from "../component/SocialMediaIcons";
import SvgValueIcon from "../icons/valueIcon";
import SvgRightNarrow from "../icons/rightNarrow";

function validateProfileData(profileData) {
    const orderedValues = profileData.orderedValues
    const describeValues = profileData.describeValues
    const orderedValuesNew = []
    const values = []
    orderedValues.forEach(v =>{
        if (!arrayContains(values, v.text)) {
            values.push(v.text)
            orderedValuesNew.push(v)
        }
    })

    const orderedDescribeValues = {}
    values.forEach((v, index) => {
        for (const key in describeValues) {
            if (describeValues[key].value === v) {
                orderedDescribeValues[index + 1] = describeValues[key]
                break
            }
        }
    })
    profileData.describeValues = orderedDescribeValues
    profileData.orderedValues = orderedValuesNew
    return profileData
}


export default function CompanyProfile(props) {
    const history = useHistory()
    let { id } = useParams()
    const [profileData, setProfileData] = useState(null)
    let [orderedValues, setOrderedValues] = useState([])
    let [orderedTopValues, setOrderedTopValues] = useState([])
    let [companyPic, setCompanyPic] = useState(null)
    
    let [activeKey, setActiveKey] = useState([])
    const { Panel } = Collapse

    useEffect(() => {
        fetchProfileData()
    }, [])

    const  fetchProfileData = async () => {
        const [response] = await Promise.all([companyProfile(id)])
        if (response) {
            setProfileData(validateProfileData(response.data))
            setOrderedValues(getOrderedValues(response.data))
            setOrderedTopValues(getOrderedTopValues(response.data))
            setCompanyPic(getCompanyPic(response.data.describeValues))
        }
        else {
            Toast.fail("Get company profile failed!")
            history.push("/404")
        }
    }

    if (!id) {
        history.push("/404")
        return
    }

    function getCompanyPic(valueList) {
        let img = undefined;
        for (let value of Object.values(valueList)) {
            if (value.image) {
                img = 'https://genvalues.com/' + value.image;
                break;
            }
        }
        return img = img || '/img/company-profile/default-company-pic.svg';
    }

    function ValueItem(props) {
        let [isHovered, setIsHovered] = useState(false)
    
        function handleClick() {
            const element = document.getElementById(props.value);
            if (element) {
                window.scrollTo({
                    top: element.getBoundingClientRect().top + window.scrollY-95,
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                });
                setTimeout(()=>{setActiveKey(activeKey => {
                    if(activeKey.includes(String(props.index+1))) {
                        return [...activeKey];
                    } else {
                        return [...activeKey, String(props.index+1)];
                    }
                })}, 800);
            }
        }
    
        return (
            <div key={props.key} className={'value-tag'}>
                <div className="value-content" 
                    onTouchStart={()=>{setIsHovered(true)}}
                    onTouchEnd={()=>{setIsHovered(false)}}
                    onClick={()=>{handleClick()}}
                    style={{background: isHovered ? 'black' : '#ffefc1'}}>
                    <div><SvgValueIcon fill={isHovered ? 'white' : 'black'}></SvgValueIcon></div>
                    <div className="value-text" style={{color: isHovered ? 'white' : 'black'}}>
                        {props.value}
                    </div>
                    <div className="arrow"><SvgRightNarrow fill={isHovered ? 'white' : 'black'}></SvgRightNarrow></div>
                </div>
            </div>
        )
    }

    const expandIcon = ({ isActive }) =>
        isActive ? (
            <Icon
                type="minus"
                style={{fontSize: '30px'}}
            />
        ) : (
            <Icon
                type="plus"
                style={{fontSize: '30px'}}
        />
    );

    function getOrderedTopValues(profileData) {
        const topValues = profileData.describeValues
        const values = getOrderedValues(profileData)
        const tmp = []
        values.forEach(v => {
            for (const key in topValues) {
                if (topValues[key].value === v) {
                    tmp.push(topValues[key])
                    break
                }
            }
        })
        return tmp;
    }

    return (
        <>
            {
                !profileData ? <div><AJLoading visible={true}/></div>
                    : <div className="company-container">
                        <div className="company-title-container">
                            <div className="company-name">{profileData.name}</div>
                            <div className="company-intro">{profileData.introducation}</div>
                            <div className="company-logo">
                                <img src= {AJImageUrl(profileData.companyStructure.imageUrl) || '/img/company-profile-default.png'}></img>
                            </div>
                        </div>

                        <div className="values-title-container">
                            <div className="values-title">
                                <div>Our values</div>
                                <div style={{borderRight: '2px solid black'}}>& principles</div>
                            </div>
                            <div className="clap-container">
                                <ClapComponent profileData={profileData}></ClapComponent>
                            </div>
                        </div>

                        <div className="values-container">
                            {
                                orderedValues.map((value, index)=>{
                                    return (                            
                                        <div style={{'display': 'inline'}}>
                                            <div className={'value-item'}>{valuePatch(value)}</div>
                                            {(index+1) == (orderedValues.length / 2).toFixed(0) ? <br/> : <></>}
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="company-description">
                            <div className="company-logo">
                                <img src={AJImageUrl(profileData.companyStructure.logoUrl) || '/img/upload-placeholder.png'}/>
                            </div>
                            <div className="company-location">
                                <div className="location">
                                    <span>
                                        <img src={'/img/company-profile/location.svg'}/>
                                    </span>
                                    
                                    <span className={'company-profile-company-location'}>
                                        {profileData.location || 'Stockholm, Sweden'}
                                    </span>
                                </div>
                                <div className="social-media">
                                    <SocialMediaIcons socials={profileData.socialLinks}
                                        color={'#181414'}
                                        width={20}
                                        height={20}
                                        space={16}
                                        backgroundColor={'white'}/>
                                </div>
                            </div>
                        </div>

                        <div className="company-desc">
                            {profileData.introducation}
                        </div>

                        <div className="company-pic">
                            <img src={companyPic}></img>
                        </div>

                        <EmptyLine height={60}/>

                        <div className="our-values-title">Job openings</div>
                        <EmptyLine height={16}/>

                        <JobOpenings data={profileData.jobOpenings} company={profileData.name}/>
                        <EmptyLine height={60}/>

                        <div className="our-values-title">Principles & values</div>
                        <div className="values-card-container">
                            {
                                orderedValues.map((v, index)=>{
                                    return (                            
                                        <ValueItem key={index} value={valuePatch(v)} index={index}></ValueItem>
                                    )
                                })
                            }
                        </div>

                        <EmptyLine height={23}/>

                        <Collapse bordered={false} activeKey={activeKey} expandIconPosition={'right'} expandIcon={(isActive)=>expandIcon(isActive)} onChange={key => {setActiveKey([...key])}}>
                            {
                                orderedTopValues.map((v, index) => {
                                    return ( 
                                        <Panel header={<p className="value-title" id={`${valuePatch(v.value)}`}>{valuePatch(v.value)}</p>} key={index+1} style={{background: 'white', overflow: 'hidden'}}>
                                            <div className="value-content-container">
                                                <div className={'aj-rich-editor'} dangerouslySetInnerHTML={{__html: v.text}}/>
                                                <div className="job-openings-title">Job openings</div>
                                                <EmptyLine height={16}/>
                                                <JobOpenings data={profileData.jobOpenings} company={profileData.name}/>
                                                {
                                                    v.video ? 
                                                    <div style={{width: '100%'}}>
                                                        <ValueMedia value={v} width={'100%'} height={240}/>
                                                    </div> : v.image ? <img src={`https://genvalues.com/${v.image}`} /> : <></>
                                                }
                                                <EmptyLine height={50}/>
                                            </div>
                                        </Panel>
                                    );
                                })
                            }
                        </Collapse>

                        <EmptyLine height={60}/>

                        <div className="tech-container">
                            <div className="tech-content">
                                <CompanyTechs profileData={profileData}></CompanyTechs>
                            </div>
                            <div className="tech-pic">
                                <img src={"/img/company-profile/technology-pic.svg"} />
                            </div>
                        </div>

                        <EmptyLine height={40}/>

                        <WingBlank>
                            <JoinCommunity/>
                        </WingBlank>
                        <EmptyLine height={60}/>
                        <AirJobbFooter/>
                    </div>
            }
        </>
    )
}
