import React, {useEffect, useState} from "react";
import "./AboutPage.less"
import EmptyLine from "../ui/EmptyLine";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AJButton3, {AJButton5, AJCenterButton} from "../ui/AJButton3";
import {Flex} from "antd-mobile";
import AirJobbFooter from "../component/AirJobbFooter";
import {useHistory} from "react-router";
import VisibilitySensor from "react-visibility-sensor";
import {getJoinedCommunity, getJoinedCommunityEmail} from "../storage/AJStorage";
import {jobOpeningVisitor} from "../http/AJHttp";

export default function AboutPage(props) {
    const history = useHistory()
    return (
        <>
            <div className={'about-page'}>
                <div className={'about-page-container'}>
                    <div className={'about-page-header'}>
                        <img src={'/img/about/about-header.png'} width={'100%'}/>
                        <div className={'about-page-title'}>
                            Crafting careers that<br/> make a difference
                        </div>

                    </div>
                    <EmptyLine height={28}/>
                    <div className={'about-page-text-container'}>
                        <div className={'about-page-text-title'}>
                            A new era of<br/> value based<br/> hiring
                        </div>
                        <br/>
                        <div className={'about-page-text-body'}>
                            Our Values-Based Matching approach sets us apart in today's dynamic impact tech landscape. As the demands for skills continue to evolve, we understand that values and attitudes remain constant and crucial to performance and success. That's why we strongly emphasise aligning modern tech talent's professional and personal values with those of the team and workplace. This initial step ensures a meaningful fit and positions the organisation for sustained success in the long term.<br/><br/>

                            By prioritising team-values first, we create a more efficient and effective hiring process beyond traditional methods. We carefully consider not only the candidate's technical abilities but also their beliefs, motivations, and cultural fit to ensure a comprehensive match. Our approach results in successful integration and contributes to a positive and productive work environment, driving business performance to new heights.<br/><br/>

                            As technology continues to shape the future, our Values-Based Matching approach provides a smart and innovative solution for attracting top talent in the tech industry. Join us in revolutionising the hiring process and creating a brighter future for your organisation."<br/><br/>
                        </div>
                        <EmptyLine height={24}/>
                    </div>

                    <div style={{background: '#F3F2EF'}}>
                        <EmptyLine height={1}/>
                        <div >
                            <img src={'/img/about/founder.png'} width={'100%'} height={'100%'}/>
                        </div>
                        <EmptyLine height={31}/>
                        <div style={{paddingLeft: '10%'}}>
                            <svg width="38" height="28" viewBox="0 0 38 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.7312 17.4875C23.7312 12.4389 24.9495 8.48636 27.386 5.62989C29.856 2.77343 33.3105 0.896797 37.7497 0V5.18149C34.3786 6.21115 32.1089 8.07117 30.9407 10.7616C30.2732 12.1898 30.0061 13.5682 30.1397 14.8968H38V28H23.7312V17.4875ZM0 17.4875C0 12.5053 1.18489 8.5694 3.55468 5.67972C5.95784 2.79004 9.44576 0.896797 14.0184 0V5.18149C10.614 6.24437 8.32762 8.07117 7.15942 10.6619C6.55863 12.0237 6.3083 13.4353 6.40843 14.8968H14.2688V28H0V17.4875Z" fill="#181414"/>
                            </svg>
                            <EmptyLine height={31}/>
                        </div>
                        <div className={'about-page-text-container'} style={{paddingLeft: '10%'}}>
                            <div className={'about-page-text-large'} style={{fontWeight: 400, textAlign: 'left'}}>
                                There's a big movement happening right now that goes beyond traditional recruiting. It's about connecting the brightest individuals with impact-driven companies that share their values and mission. Every day, I'm inspired to create meaningful connections that drive positive change.”
                            </div>
                        </div>
                        <EmptyLine height={60}/>
                    </div>

                    <div className={'about-page-img-container'}>
                        <img src={'/img/about/what-we-do.png'}/>
                    </div>

                    <EmptyLine height={34}/>
                    <div className={'about-page-text-container'}>
                        <div className={'about-page-text-title'}>
                            What we do
                        </div>
                        <br/>
                        <div className={'about-page-reason-text-body-1'}>
                            Our value-matching platform help tech talents join
                            the impact wave and find teams that share the
                            same values.
                        </div>
                        <EmptyLine height={40}/>
                        <div className={'about-page-reason-text-body-2'} >
                            We are transforming the traditional way of hiring to a modern
                            approach that aligns with the new generation of talent pool
                            that have a wider lens about a job and companies
                            responsibility in the society.
                        </div>
                        <EmptyLine height={40}/>
                        <div className={'about-page-reason-text-body-2'} >
                            <div>
                                We put values and purpose before skills. We believe
                                purpose is a commitment, and commitment is
                                performance.
                            </div>
                            <EmptyLine height={50}/>
                            <div>
                                We put values before qualifications. We believe values are teamwork, and teamwork leads to collective intelligence.
                            </div>
                        </div>
                        <EmptyLine height={50}/>
                    </div>

                    <div className={'about-page-img-container'}>
                        <img src={'/img/about/how-it-work.png'}/>
                    </div>
                    <EmptyLine height={41}/>

                    <div className={'about-page-text-container'}>
                        <div className={'about-page-text-title'}>
                            How it works
                        </div>
                        <br/>
                        <div className={'about-page-text-body'} style={{
                            fontWeight: 400,
                            fontSize: 20,
                            lineHeight: '28px'
                            /* or 140% */
                        }}>
                            We use cutting-edge technology and science to provide a unique platform. Genvalues elevate your employer brand, aligning with a modern generation with a wider lens on what a job is and a company's role in society. 82% of office workers consider it important to connect their values and purpose with their employer.<br/><br/>

                            We provide tech companies with a unique opportunity to stand out from the crowd, with over 60 attractive and modern professional values to choose from.<br/><br/>

                            Companies can select the top nine values that best represent their engineering team and their SDGs, and receive professional help to create a modern profile.

                        </div>

                    </div>
                    <EmptyLine height={41}/>

                    <div style={{background: '#F3F2EF', position: 'relative'}}>
                        <div style={{position: 'absolute', right: 20, bottom: 20}}>
                            <a href={'https://kpmg.com/uk/en/home/media/press-releases/2023/01/climate-quitting-younger-workers-voting-esg.html'} style={{color: '#181414', fontFamily: 'InterLight', fontSize: 11}} target="_blank">Source: <u>KPMG</u></a>
                        </div>
                        <EmptyLine height={30}/>
                        <div className={'about-page-text-title'} style={{textAlign: 'center'}}>
                            Numbers that matters
                        </div>
                        <EmptyLine height={30}/>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{ width: 200}}>
                                <VisibilitySensor offset={{top: -200}}>
                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 98 : 0;
                                        return (
                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    // Colors
                                                    pathColor: '#181414',
                                                    textColor: '#181414',
                                                    // trailColor: '#181414',
                                                    // backgroundColor: '#181414',
                                                })}
                                            />
                                        );
                                    }}
                                </VisibilitySensor>
                                <EmptyLine height={18}/>
                                <div className={'about-card-percentage-text'}>
                                    Of employees wouldn’t<br/>
                                    work somewhere that<br/>
                                    does not fit their values
                                </div>
                            </div>
                        </div>

                        <EmptyLine height={26}/>

                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{ width: 200}}>
                                <VisibilitySensor offset={{top: -200}}>
                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 82 : 0;
                                        return (
                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    // Colors
                                                    pathColor: '#181414',
                                                    textColor: '#181414',
                                                    // trailColor: '#181414',
                                                    // backgroundColor: '#181414',
                                                })}
                                            />
                                        );
                                    }}
                                </VisibilitySensor>
                                <EmptyLine height={18}/>
                                <div className={'about-card-percentage-text'}>
                                    Place importance on linking
                                    values and purpose with
                                    their employer
                                </div>
                            </div>
                        </div>

                        <EmptyLine height={26}/>


                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{ width: 200}}>
                                <VisibilitySensor offset={{top: -200}}>
                                    {({ isVisible }) => {
                                        const percentage = isVisible ? 14 : 0;
                                        return (
                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    // Colors
                                                    pathColor: '#181414',
                                                    textColor: '#181414',
                                                    // trailColor: '#181414',
                                                    // backgroundColor: '#181414',
                                                })}
                                            />
                                        );
                                    }}
                                </VisibilitySensor>
                                <EmptyLine height={18}/>
                                <div className={'about-card-percentage-text'}>
                                    Engagement rate at
                                    workplace in Sweden
                                </div>
                            </div>
                        </div>


                        <EmptyLine height={69}/>

                        <div style={{textAlign: 'center'}}>
                            <AJButton5
                                title={'More insights'}
                                type={'secondary'}
                                scheme={'secondary'}
                                height={45}
                                width={137}
                                size={'small'}
                                shadow={true}
                                titleStyle={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'ManropeRegular',
                                    color: '#181414',
                                    lineHeight: '18px'

                                }}
                                customStyle={{
                                    border: '1px solid #000000',
                                    borderRadius: 2,
                                    background: 'transparent'
                                }}
                                onClick={() => {
                                    history.push("/latest")
                                }}/>
                        </div>
                        <EmptyLine height={55}/>
                    </div>



                    <div className={'about-page-img-container'}>
                        <img src={'/img/about/why-airjobb.png'}/>
                    </div>
                    <EmptyLine height={41}/>
                    <div className={'about-page-text-container'}>
                        <div className={'about-page-text-title'}>
                            Why Genvalues
                        </div>
                        <br/>
                        <div className={'about-page-text-body'}>
                            At Genvalues, our mission is to empower the impact tech wave with successful teams driven by innovation and a sustainable future for all. A match based on shared team values and purpose is key to fostering growth and success for impact tech companies and modern engineering talent.
                            <br/>
                            <br/>
                            <div className={'about-page-text-sub-title'}>
                                We help employers build successful tech teams
                                <br/><br/>
                            </div>

                            For companies, we offer a unique solution to building successful tech teams. We understand that attracting and retaining top talent goes beyond skills and experience; it's about finding individuals who share the company's purpose and team’s values. Recent studies show that  82% of office workers place importance on linking values and purpose with their employer.<br/><br/><br/>
                            <div className={'about-page-text-sub-title'}>
                                We help talents create meaningful working experience
                                <br/><br/>
                            </div>
                            {/*<br/>*/}

                            For tech talents, we provide a platform for meaningful career growth. Career success goes beyond just ideas and experience; it's about finding a workplace that aligns with your purpose and professional values. With Genvalues, you can discover opportunities that offer career growth and match your values and purpose, creating a fulfilling work experience.
                            Join us in revolutionizing the tech industry with value-based matches that drive innovation, success, and a sustainable future for all. Discover the power of shared values and purpose with Genvalues."
                            <br/>
                            We are here to build successful tech teams, driving innovation and a sustainable future for all. We do it by matching talents and companies based on shared values and purpose. We know that employment based on shared values and purpose gives the highest possible conditions for development. We want every tech talent to find employment with purpose and meaning – and every team to find the right people to drive innovation and success.<br/>
                        </div>

                    </div>
                    <EmptyLine height={48}/>

                    <div className={'about-page-img-container'}>
                        <img src={'/img/about/why-genvalues2.png'}/>
                    </div>
                    <EmptyLine height={87}/>
                    <div className={'about-card-team-container'}>
                        <EmptyLine height={38}/>

                        <div className={'about-page-text-title'} style={{textAlign: 'center'}}>
                            Team
                        </div>
                        <EmptyLine height={38}/>
                        <Flex>
                            <Flex.Item>
                                <div className={'about-card-team-avatar'}>
                                    <img src={'/img/about/carwan.png'}/>
                                </div>
                                <EmptyLine height={9}/>
                                <div className={'about-card-team-title'}>
                                    Carwan Rasoal
                                </div>
                                <div className={'about-card-team-position'}>
                                    Founder & CEO
                                </div>

                            </Flex.Item>
                            <Flex.Item>
                                <div className={'about-card-team-avatar'}>
                                    <img src={'/img/about/Hank.png'}/>
                                </div>
                                <EmptyLine height={9}/>
                                <div className={'about-card-team-title'}>
                                    Michael Lee
                                </div>
                                <div className={'about-card-team-position'}>
                                    CTO
                                </div>
                            </Flex.Item>

                        </Flex>
                        <EmptyLine height={29}/>
                        <Flex>

                            <Flex.Item>
                                <div className={'about-card-team-avatar'}>
                                    <img src={'/img/about/Jonas.png'}/>
                                </div>
                                <EmptyLine height={9}/>
                                <div className={'about-card-team-title'}>
                                    Jonas Enqvist
                                </div>
                                <div className={'about-card-team-position'}>
                                    Creative Lead
                                </div>
                            </Flex.Item>
                            <Flex.Item>

                            </Flex.Item>

                        </Flex>

                        <EmptyLine height={70}/>
                    </div>

                        <div className={'about-card-team-container'} style={{background: 'white'}}>
                            <EmptyLine height={38}/>
                            <div className={'about-page-text-title'} style={{textAlign: 'center'}}>
                                Advisors
                            </div>

                            <EmptyLine height={38}/>
                            <Flex>
                                <Flex.Item>
                                    <div style={{'position': 'relative', 'bottom': '10px'}}>
                                        <div className={'about-card-team-avatar'}>
                                            <img src={'/img/about/Jessica.png'}/>
                                        </div>
                                        <EmptyLine height={9}/>
                                        <div className={'about-card-team-title'}>
                                            Jessica Cederberg<br/> Wodmar
                                        </div>
                                        {/*<div className={'about-card-team-position'} style={{lineHeight: '18px', fontWeight: 400}}>*/}
                                        {/*    Stockholm*/}
                                        {/*</div>*/}
                                        <div className={'about-card-team-title'} style={{fontFamily: 'InterLight', fontSize: 12, lineHeight: '18px', fontWeight: 400}}>
                                            Sustainability expert with +30 years of knowledge and experience & Author
                                        </div>
                                    </div>
                                </Flex.Item>

                                <Flex.Item>
                                    <div className={'about-card-team-avatar'}>
                                        <img src={'/img/about/dave.png'}/>
                                    </div>
                                    <EmptyLine height={9}/>
                                    <div className={'about-card-team-title'}>
                                        Dave Edwards<br/><br/>
                                    </div>
                                    {/*<div className={'about-card-team-position'} style={{lineHeight: '18px', fontWeight: 400}}>*/}
                                    {/*    San Francisco*/}
                                    {/*</div>*/}
                                    <div className={'about-card-team-title'} style={{fontFamily: 'InterLight', fontSize: 12, lineHeight: '18px', fontWeight: 400}}>
                                        <>AI & Future workforce expert with experience from Apple & Morgan Stanley</>
                                    </div>
                                </Flex.Item>


                            </Flex>
                            <EmptyLine height={29}/>

                            <Flex>
                                <Flex.Item>
                                    <div className={'about-card-team-avatar'}>
                                        <img src={'/img/about/chato2.png'}/>
                                    </div>
                                    <EmptyLine height={9}/>
                                    <div className={'about-card-team-title'}>
                                        Chato Rasoal<br/><br/>
                                    </div>
                                    {/*<div className={'about-card-team-position'} style={{lineHeight: '18px', fontWeight: 400}}>*/}
                                    {/*    Stockholm*/}
                                    {/*</div>*/}
                                    <div className={'about-card-team-title'} style={{fontFamily: 'InterLight', fontSize: 12, lineHeight: '18px', fontWeight: 400}}>
                                        Ph.D in Psychology & Scientist
                                    </div>
                                </Flex.Item>
                                {/*<Flex.Item>*/}

                                {/*</Flex.Item>*/}
                            </Flex>
                        </div>


                        <EmptyLine height={100}/>


                </div>

            </div>
            <AirJobbFooter/>
        </>
    )
}

function AboutCard(props) {
    return (
        <div className={'about-card'}>
            <div className={'about-card-title'}>
                <br/>
                Are you a Company?<br/>
                Do you want to attract<br/>
                Engineers that share your<br/>
                Values & Purpose?<br/><br/>

            </div>
            <div className={'about-card-body'}>
                To provide the best<br/> onboarding experience,<br/> please make sure you use<br/>
                your computer<br/><br/>
                Contact us and we will help you<br/><br/>
                +46 (0)73 280 22 61<br/> team@genvalues.com
            </div>
            <br/>
        </div>
    )
}


function Percent(props) {
    const percent = props.percent || 0
    const color = props.color
    return (
        <div style={{width: 200, height:200, margin: 'auto'}}>

            <CircularProgressbar
                value={percent}
                text={`${percent*100}%`}
                maxValue={1}
                styles={buildStyles({
                    pathColor: '#754FDE',
                    backgroundColor: '#D2D2D2',
                    textSize: 26,
                    textColor: '#754FDE'
                })}
            />
        </div>)
}
