import React, {useEffect, useState} from 'react';
import { Switch, Route,  useLocation, useHistory } from 'react-router-dom';
import { useTransition,  } from 'react-spring';
import './App.css';
import Navigator from "./component/Navigator";
import Menu from "./component/Menu";
import {Toast} from "antd-mobile";
import ScrollPageContainer from "./component/ScrollPageContainer";
import ContactUs from "./component/ContactUs";
import {getFavorite} from "./http/AJHttp";
import {Provider} from "react-redux";
import store from './redux/store';
import CompanySignIn from "./page/CompanySignIn";
import OAuthCallback from "./OAuthCallback";
import ValueSelectWrapper from "./page/ValueSelectPage";
import StartPageWrapper from "./page/StartPageV2";
import MatchedCompaniesWrapper from "./page/MatchedCompanies";
import AboutPage from "./page/AboutPage";
import PrivacyPage from "./page/PrivacyPage";
import Insights from "./page/Insights";
import CompanyPage from "./page/CompanyPage";
import ContactPage from "./page/ContactPage";
import TalentPage from "./page/TalentPage";
import NFTPage from "./page/NFTPage";
import CompanyProfile from "./page/CompanyProfile";
import SavedCompanies from "./page/SavedCompanies";
import TermsConditions from "./page/TermsConditions";
import LazyComponent from "./component/LazyComponent";
import PricePage from "./page/price/PricePage";
import BlogPage from './page/BlogPage';
const QuestionsInInterview = React.lazy(() => import('./page/toolbox/QuestionsInInterview'))

export function showContact() {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'hidden'
    const contact = document.getElementById('aj-contact')
    if (contact) {
        contact.style.display = 'block'
    }
}
export function hideContact() {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'auto'
    const contact = document.getElementById('aj-contact')
    if (contact) {
        contact.style.display = 'none'
    }
}
const freezeVp = function(e) {
    e.preventDefault();
};
export function disableScroll() {
    const body = document.getElementsByTagName('body')[0]
    body.addEventListener('touchmove', freezeVp, { passive: false });
}

export function enableScroll() {
    const body = document.getElementsByTagName('body')[0]
    body.removeEventListener('touchmove', freezeVp, { passive: false });
}


function closest(el, selector) {
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
    while (el) {
        if (matchesSelector.call(el, selector)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}


export default function App() {
    const location = useLocation()
    const history = useHistory()

    const [menuOpened, setMenuOpened] = useState(false)
    const [drawerOpened, setDrawerOpened] = useState(false)
    const [favoriteData, setFavoriteData] = useState([])
    const [menuTop, setMenuTop] = useState(0)

    function AppNavigator(props) {
        let {version='1', customStyle={}} = props;
        return (<Navigator
                    customStyle={customStyle}
                    favorite={favoriteData.length}
                    version={version}
                    toggleMenu={()=>{
                        setMenuOpened(!menuOpened);
                        disableScroll();
                    }}>
                </Navigator>);
    }

    useEffect(() => {
        refreshFavorite()
    }, [])

    const refreshFavorite = async () => {
        const response = await getFavorite()
        if (response) {
            setFavoriteData(response.data)
        } else {
            Toast.fail('Get favorite data failed!')
        }
    }

    const onWrapTouchStart = (e) => {
        // fix touch to scroll background page on iOS
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, '.am-modal-content');
        if (!pNode) {
            e.preventDefault();
        }
    }

    const MenuDrawer = () => {
        return (
            <>
                {menuOpened ? <div className={'app-menu-mask'} onClick={() => {
                    setMenuOpened(false)
                    enableScroll()
                }}>
                </div> : null}
                <div className={`app-menu-container ${menuOpened? 'app-menu-container-show' : null}`}>
                    <Menu
                        onClose={() => {
                            setMenuOpened(false)
                            enableScroll()
                        }}/>
                </div>
            </>
        )
    }

    let config;
    if (history.action === 'POP') {
        config = {
            leave: { opacity: 1, transform: 'translate3d(100%,0,0)' },
            enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
            from: { opacity: 1, transform: 'translate3d(-50%,0,0)' },
        }
    }
    else {
        config = {
            from: { opacity: 1, transform: 'translate3d(100%,0,0)' },
            enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
            leave: { opacity: 1, transform: 'translate3d(-50%,0,0)' },
        }
    }
    const transitions = useTransition(location, location => location.pathname, config)
    return (
        <Provider store={store}>
            <div>
                <MenuDrawer/>

                <ScrollPageContainer>
                    <div style={{position: 'relative'}}>
                        <Switch location={location}>
                        <Route exact path="/"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator version="2"></AppNavigator>
                                            <StartPageWrapper/>
                                        </div>
                                    )
                                }}/>

                            <Route exact path="/value-select"
                                render={() => {
                                    return (
                                        <div>
                                            <ValueSelectWrapper/>
                                        </div>                                      
                                    )
                                }}/>

                            <Route exact path="/companies-matched"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator version="2"></AppNavigator>
                                            <MatchedCompaniesWrapper/>
                                        </div>
                                    )
                                }}/>

                            <Route exact path="/about"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator></AppNavigator>
                                            <AboutPage/>
                                        </div>
                                    )
                                }}/>

                            <Route path="/company-profile/:id"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator version="2" 
                                                customStyle={{height: '79px', 
                                                borderBottom: '1px solid black',
                                                background: 'white'}}></AppNavigator>
                                            <CompanyProfile
                                                refreshFavorite={refreshFavorite}
                                                favoriteData={favoriteData}/>
                                        </div>
                                    )
                                }}/>

                            <Route exact path="/saved-companies"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator></AppNavigator>
                                            <SavedCompanies
                                                refreshFavorite={refreshFavorite}
                                                favoriteData={favoriteData}/>
                                        </div>
                                    )
                                }}/>

                            <Route exact path="/privacy"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator></AppNavigator>
                                            <PrivacyPage/>
                                        </div>
                                    )
                                }}/>

                            <Route exact path="/terms-conditions"
                                   render={() => {
                                       return (
                                           <div>
                                                <AppNavigator></AppNavigator>
                                                <TermsConditions/>
                                           </div>
                                       )
                                   }}/>

                            <Route exact path="/employer"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator></AppNavigator>
                                            <CompanyPage/>
                                        </div>
                                    )
                                }}/>

                            <Route exact path="/latest"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator version="2"
                                                customStyle={{height: '62px', 
                                                borderBottom: '1px solid black',
                                                background: 'white',
                                                topGap: 11}}
                                            ></AppNavigator>
                                            <Insights/>
                                        </div>
                                    )
                                }}/>

                            <Route exact path={'/blog/:id'}
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator version="2"
                                                customStyle={{height: '62px', 
                                                borderBottom: '1px solid black',
                                                background: 'white',
                                                topGap: 11}} />
                                            <BlogPage/>
                                        </div>
                                    )
                                }}/>

                            <Route exact path="/talent"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator></AppNavigator>
                                            <TalentPage/>
                                        </div>
                                    )
                                }}/>

                            <Route exact path="/contact"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator></AppNavigator>
                                            <ContactPage/>
                                        </div>
                                    )
                                }}/>

                            <Route exact path="/nft"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator></AppNavigator>
                                            <NFTPage/>
                                        </div>
                                    )
                                }}/>

                            <Route exact path={'/toolbox'} render={() => {
                                return (
                                    <div>
                                        <AppNavigator></AppNavigator>
                                        <LazyComponent>
                                            <QuestionsInInterview/>
                                        </LazyComponent>
                                    </div>
                                )
                            }}/>

                            <Route exact path={'/register'} render={() => {
                                return (
                                    <div>
                                        <AppNavigator></AppNavigator>
                                        <CompanySignIn/>
                                    </div>
                                )
                            }}/>

                            <Route path={'/oauth/callback'}>
                                <OAuthCallback/>
                            </Route>

                            <Route exact path="/price"
                                render={() => {
                                    return (
                                        <div>
                                            <AppNavigator></AppNavigator>
                                            <PricePage/>
                                        </div>
                                    )
                                }}/>

                            {/*<Route exact path="/test"*/}
                            {/*       render={() => {*/}
                            {/*           return (*/}
                            {/*               <JoinOurTalent/>*/}
                            {/*           )*/}
                            {/*       }}/>*/}
                        </Switch>

                    </div>
                    <ContactUs/>
                </ScrollPageContainer>
            </div>
        </Provider>
    )
}

