import React, {useState} from "react";
import "./AirJobbFooter.less"
import EmptyLine from "../ui/EmptyLine";
import {Flex, Popover} from "antd-mobile";
import SocialMediaIcons from "./SocialMediaIcons";
import {LoginTips} from "./Menu";
import ContactUs from "./ContactUs";
import {showContact} from "../App";
import {gotoLink} from "./Navigator";
import {useHistory} from "react-router";
import EmptySpan from "../ui/EmptySpan";
import AJLogo from "../ui/AJLogo";

export const socialLinkConfig = {
    linkedin:
        'https://www.linkedin.com/company/airjobb/?viewAsMember=true',

    facebook:
        'https://www.facebook.com/Genvalues',

    instagram:
        'https://www.instagram.com/genvalues/?hl=en',

    medium:
        'https://medium.com/@GenValues',

    twitter:
        'https://twitter.com/genvalues',

    youtube:
        'https://www.youtube.com/channel/UCLJjJBqhBFbNDm3ONTrXbxQ'
}

export default function AirJobbFooter(props) {
    const [showTips, setShowTips] = useState(false)
    // const [showContactUs, setShowContactUs] = useState(false)
    const history = useHistory()

    function getMarginLeft() {
        return window.innerWidth > 375 ? 48 : 0;
    }

    return (
        <div className={'aj-footer'}>
            <div className={'aj-footer-container'}>
                <div className={'aj-footer-title'} onClick={() => gotoLink('/', history)}>
                    <AJLogo schema={'white'} changeWithScroll={false}/>
                </div>
                <EmptyLine height={32}/>
                <Flex>
                    <Flex.Item className={'aj-footer-menu'}  onClick={() => {
                        showContact()
                    }}>
                        Contact
                    </Flex.Item>
                    <Flex.Item className={'aj-footer-menu'} style={{marginLeft: getMarginLeft()}} onClick={() => gotoLink('/toolbox', history)}>
                        True Fit
                    </Flex.Item>
                </Flex>
                <EmptyLine height={16}/>
                <Flex>
                    <Flex.Item className={'aj-footer-menu'}  onClick={() => gotoLink('/latest', history)}>
                        Media & News
                    </Flex.Item>
                    <Flex.Item className={'aj-footer-menu'} style={{marginLeft: getMarginLeft()}}  onClick={() => gotoLink('/about', history)}>
                        About us
                    </Flex.Item>
                </Flex>

                <EmptyLine height={16}/>
                <Flex>
                    <Flex.Item className={'aj-footer-menu'}  onClick={() => gotoLink('/talent', history)}>
                        Talent
                    </Flex.Item>
                    <Flex.Item className={'aj-footer-menu'}  style={{marginLeft: getMarginLeft()}}  onClick={() => gotoLink('/price', history)}>
                        Pricing
                    </Flex.Item>
                </Flex>

                <EmptyLine height={16}/>
                <Flex>
                    <Flex.Item className={'aj-footer-menu'} onClick={() => gotoLink('/employer', history)}>
                        Employer
                    </Flex.Item>
                    <Flex.Item className={'aj-footer-menu'} style={{marginLeft: getMarginLeft()}} >
                        <Popover overlay={<LoginTips/>}
                                 visible={showTips}
                                 placement={'top'}
                                 onVisibleChange={() => setShowTips(false)}
                                 style={{width: '50%'}} >
                            <span>
                                Employer login
                            </span>
                        </Popover>
                    </Flex.Item>
                </Flex>

                <EmptyLine height={37}/>

                <Flex>
                    <Flex.Item className={'aj-footer-menu-small'} onClick={() => gotoLink('/terms-conditions', history)}>
                        Terms of use
                    </Flex.Item>
                </Flex>
                <EmptyLine height={34}/>

                <div className={'aj-footer-menu-fix'}>
                    <Flex>
                        <Flex.Item className={'aj-footer-menu-small'} style={{marginTop: -24}}onClick={() => gotoLink('/privacy', history)}>
                            Privacy
                        </Flex.Item>
                    </Flex>
                </div>

                <EmptyLine height={39}/>
                <div className={'menu-company-icons'}>
                    <SocialMediaIcons
                        socials={socialLinkConfig}
                        // color={'#474A63'}
                        width={30}
                        height={30}
                        space={20}/>
                </div>
                <EmptyLine height={20}/>
                <div className={'aj-footer-menu-copyright'}>
                    Copyright {new Date().getFullYear()}
                </div>


            </div>
                {/*<ContactUs*/}
                {/*    hidden={!showContactUs}*/}
                {/*    onClose={() => setShowContactUs(false)}/>*/}
        </div>
    )
}
