import { backgrounds } from 'polished';
import React from 'react';

function EmptyLine({height, width, background}) {
    height = height || 0;
    width = width || '1px';
    background = background || 'transparent';

    return (
        <div style={{height: height, width: width, background: background }}/>
    )
}

export default EmptyLine;