import React, {useEffect, useState} from "react";
import "./Insights.less";
import { useHistory } from 'react-router-dom';
import EmptyLine from "../ui/EmptyLine";
import AirJobbFooter from "../component/AirJobbFooter";
import {Toast} from "antd-mobile";
import AJLoading from "../ui/AJLoading";
import {client} from "../http/AJHttp";
import _ from "lodash";
import DynamicRatioImage from '../component/common/dynamicRatioImage';


export default function Insights() {
    let history = useHistory();
    const [isFirstLoading, setIsFirstLoading] = useState(true);

    function navigateBack() {
        history.goBack();
    }

    return (
        <div className={'insights-page-container'}>
            <AJLoading visible={isFirstLoading} />
            <div className={`content-container ${isFirstLoading? 'not-show' : null}`}>
                <div style={{paddingLeft: 15, paddingRight: 15}}>
                    <EmptyLine height={23} />
                    <div onClick={() => navigateBack()}>
                        <img style={{'width': '56px'}} src={'/img/latest/back.svg'}/>
                    </div>
                    <EmptyLine height={11} />

                    <div className="main-title">Media & News</div>
                    <EmptyLine height={11} />

                    <BlogsContainer onFirstLoadingDone={()=>{setIsFirstLoading(false)}} />
                </div>
                <AirJobbFooter/>
            </div>
        </div>
    )
}

export function BlogsContainer(props) {
    const history = useHistory();
    let {ignoreBlogSlug, onFirstLoadingDone} = props;
    ignoreBlogSlug = ignoreBlogSlug || null;

    const [currentPage, setCurrentPage] = useState(0); // PS: keep the page index consistent with getAtricles API
    const [hasMore, setHasMore] = useState(true);
    let [isFetching, setIsFetching] = useState(false);
    let [blogCard, setBlogCard] = useState([]);

    const fetchBlogsData = async (page, numberPerPage) => {
        if (isFetching || !hasMore) {
            console.log("skip fetch blogs...");
            return;
        }

        setIsFetching(true);

        try {
            const response = await client.getArticles(page, 6);

            if(response?.articles) {
                if((page+1)*numberPerPage>=response.total) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
                if (ignoreBlogSlug) {
                    const tmp = response.articles.filter(blog => blog.slug !== ignoreBlogSlug);
                    setBlogCard([...blogCard, ...tmp]);
                } else {
                    setBlogCard([...blogCard, ...response.articles]);
                }
                setCurrentPage(page => {
                    if(page==0) onFirstLoadingDone && onFirstLoadingDone();
                    return page + 1;
                }); 
            } else {
                throw('fetch blogs failed');
            }
        } catch(e) {
            setHasMore(false);
            Toast.fail('fetch blogs failed');
        } finally {
            setIsFetching(false);
        }
    }

    useEffect(() => {
        fetchBlogsData(currentPage, 6)
    }, [])

    return (
        <div className="blogs-container">
            {
                blogCard.map(article => {
                    return (
                        <div className="blog" onClick={() => window.open(`/blog/${article.slug}`)}>
                            <div className="blog-img">
                                <DynamicRatioImage imageUrl={article.image} fixedAspectRadio={'aspect-ratio-1-1'} />
                            </div>

                            <div className="blog-title">{article.headline}</div>
                        </div>
                    )
                })
                
            }

            <EmptyLine height={16} />
            <div className={`load-button-container ${!hasMore? 'not-show' : null}`}>
                <div className={"load-button"} onClick={() => fetchBlogsData(currentPage, 6)}>Load more</div>
            </div>
            <EmptyLine height={40} />
        </div>
    )
}

export function removeH1Content(str) {
    const regex = /<h1.*?>.*?<\/h1>/s;
    return str.replace(regex, '');
}
