import React from "react";
import "./PricePage.less"
import EmptyLine from "../../ui/EmptyLine";
import AirJobbFooter from "../../component/AirJobbFooter";
import FunctionsCard from "./FunctionsCard";
import PriceRequestCard from "./PriceRequestCard";
import CEOReviewCard from "./CEOReviewCard";

export default function PricePage() {
    return <>
        <div className={'price-page'}>
            <div className={'price-page-container'}>
                <div className={'price-page-header'}>
                    <img src={'/img/price/price-header-v2.png'} width={'100%'}/>
                    {/*<div className={'about-page-title'}>*/}
                    {/*    Work<br/> that<br/> matters*/}
                    {/*</div>*/}

                </div>
                <div className={'price-page-title'}>
                    Let the modern<br/>
                    talent know your<br/>
                    positive impact &<br/>
                    team-values
                </div>

                <EmptyLine height={32}/>
                <div className={'price-page-content'}>
                    <EmptyLine height={8}/>
                    <div className={'price-page-content-title'}>
                        List of functions
                    </div>
                    <EmptyLine height={15}/>
                    <div className={'price-page-content-desc'}>
                        Get in touch - book your free demo now.
                    </div>
                    <EmptyLine height={17}/>
                    <FunctionsCard/>
                    <EmptyLine height={40}/>

                    <div className={'price-page-content-title'}>
                        Price request
                    </div>
                    <EmptyLine height={15}/>
                    <div className={'price-page-content-desc2'}>
                        We'd like to offer you a plan that suits you best!
                    </div>
                    <EmptyLine height={17}/>
                    <PriceRequestCard/>

                    <EmptyLine height={60}/>
                    <div className={'price-page-content-title'}>
                        What CEO’s say about us
                    </div>
                    <EmptyLine height={20}/>
                    <CEOReviewCard
                        avatar={'/img/price/CHARLOTTE.png'}
                        introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                            <span style={{fontWeight: 500, fontFamily: 'InterMedium', fontSize: '11px'}}>CHARLOTTE</span><br/>
                            <span style={{fontSize: 10, fontFamily: 'InterLight'}}>Co-Founder & CEO</span> <br/>
                            <span style={{fontSize: 10, fontFamily: 'InterLight'}}><i>of Teemyco</i></span>
                        </div>}
                        comment={<>"I love what Genvalues is doing. It’s
                            democratizing hiring and helping
                            teams attract talents based in team
                            values and pushing them to put light
                            on culture. We have received a lot of
                            hoc applications to Teemyco since we
                            got a Genvalues profile."</>}/>
                    <EmptyLine height={16}/>
                    <CEOReviewCard
                        avatar={'/img/price/STEFAN.png'}
                        introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                            <span style={{fontWeight: 500, fontFamily: 'InterMedium', fontSize: '11px'}}>STEFAN</span><br/>
                            <span style={{fontSize: 10, fontFamily: 'InterLight'}}>Founder & CEO of</span> <br/>
                            <span style={{fontSize: 10, fontFamily: 'InterLight'}}><i>CAKE</i></span>
                        </div>}
                        comment={<>
                            "We will definitely speed up our process in terms of identifying and hiring talent. Being able to scan the market with the ability of embracing specific values and directions should promote our ability to be much more efficient in terms of finding the talents."
                        </>}/>
                    <EmptyLine height={16}/>
                    <CEOReviewCard
                        avatar={'/img/price/RAMZI.png'}
                        introduce={<div style={{textAlign: 'center', fontSize: 12, color: '#181414'}}>
                            <span style={{fontWeight: 500, fontFamily: 'InterMedium', fontSize: '11px'}}>MIKAELA</span><br/>
                            <span style={{fontSize: 10, fontFamily: 'InterLight'}}>Founder of</span> <br/>
                            <span style={{fontSize: 10, fontFamily: 'InterLight'}}><i>Din Psykolog</i></span>
                        </div>}
                        comment={<>
                            "Genvalues helped us shed light on what's unique about our engineering team values & company culture. Their platform made it simple for us to showcase it to potential candidates and future team members. It also made us think twice about what is actually unique about working at Din Psykolog."
                        </>}/>

                    <EmptyLine height={52}/>
                </div>

            </div>
        </div>
        <AirJobbFooter/>
    </>

}