import React, {useState} from "react";
import "./JoinCommunity.less";
import EmptyLine from "../ui/EmptyLine";
import AJButton3 from "../ui/AJButton3";
import {InputItem, Toast} from "antd-mobile";
import {sendCommunityMail} from "../http/AJHttp";
import {useHistory} from "react-router";

export default function JoinCommunity(props) {
    const [sending, setSending] = useState(false);
    const [hasInput, setHasInput] = useState(false);
    const history = useHistory()

    return (
        <div className={'join-community'}>
            <div className={'join-community-container'}>
                <div className={'join-community-title'}>
                    Stay ahead
                </div>
                <EmptyLine height={9}/>
                <div className={'join-community-body'}>
                    Sign up for weekly newsletter
                </div>
                <EmptyLine height={25}/>
                <InputItem
                    placeholder="Your e-mail here"
                    id={'aj-community-mail'}
                    onChange={(content)=> {
                        if (content && content.length) {
                            if (!hasInput) {
                                setHasInput(true);
                            }
                        } else {
                            if(hasInput) {
                                setHasInput(false);
                            }
                        }
                    }}/>
                <EmptyLine height={39}/>

                <span className={'join-community-privacy'} onClick={() => {
                    history.push('/privacy')
                }}>
                    Privacy
                </span>
                
                <div className="join-community-button">
                    <AJButton3 size={'small'}
                        loading={sending}
                        title={'Send'} scheme={'dark'}
                        customStyle=  {{
                            border: '1px solid #FFFFFF',
                            background: '#FFFFFF'
                        }}
                        titleStyle={{'color': 'black', 'fontFamily': 'ManropeMedium', 'fontSize': '20px'}}
                        width={'99px'} height={45}
                        onClick={async() => {
                            const email = document.getElementById('aj-community-mail').value
                            const pattern = props.regex || /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                            if (!pattern.test(email)) {
                                Toast.fail("Not a valid email address!")
                                return
                            }
                            setSending(true)

                            const response = await sendCommunityMail(email)
                            if (response) {
                                Toast.info("Welcome to a value-driven engineering community")
                            }
                            else {
                                Toast.fail("Subscribe failed!")
                            }
                            // document.getElementById('aj-community-mail').value = null
                            setSending(false)
                    }}/>
                </div>
            </div>
        </div>
    )
}
