import React, {useState, useEffect} from "react";
import "./carouselContainer.less";
import SvgLeftIcon from "../../icons/SvgLeftIcon";
import SvgRightIcon from "../../icons/SvgRightIcon";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function CarouselContainer(props) {
	let {infinite, swipeable, lastIndex, buttonWidth, showCustomDot} = props;
	infinite = infinite ?? true;
	swipeable = swipeable ?? true;
	showCustomDot = showCustomDot ?? false;
	lastIndex = lastIndex ?? props.children.length - 1;
	buttonWidth = buttonWidth ?? 48;
	let dotIndexList = Array.from({ length: props.children.length }, (_, index) => index);

	let [buttonLFill, setButtonLFill]=useState('white');
	let [buttonLBackground, setButtonLBackground]=useState('black');
	let [buttonRFill, setButtonRFill]=useState('white');
	let [buttonRBackground, setButtonRBackground]=useState('black');
	let [buttonLDisable, setButtonLDisable] = useState(!infinite ? true : false);
	let [buttonRDisable, setButtonRDisable] = useState((lastIndex <= 0 && !infinite) ? true : false);
	let [selectIndex, setSelectIndex] = useState(0);
	const [translateX, setTranslateX] = useState(0);

	useEffect(() => {
		if (buttonLDisable && !infinite) setButtonLBackground('rgb(206, 206, 206)');
		if (!buttonLDisable && !infinite) setButtonLBackground('black');
    }, [buttonLDisable]);

	useEffect(() => {
		if (buttonRDisable && !infinite) setButtonRBackground('rgb(206, 206, 206)');
		if (!buttonRDisable && !infinite) setButtonRBackground('black');
    }, [buttonRDisable]);

	function onLeftClick(previous) {
		if (infinite) {
			setButtonLFill('black');
			setButtonLBackground('white');
		}
		previous();
	}

	function onLeftClickEnd() {
		if (infinite) {
			setTimeout(()=>{
				setButtonLFill('white');
				setButtonLBackground('black');
			}, 500);
		}
	}

	function onRightClick(next) {
		if (infinite) {
			setButtonRFill('black');
			setButtonRBackground('white');
		}
		next();
	}

	function onRightClickEnd() {
		if (infinite) {
			setTimeout(()=>{
				setButtonRFill('white');
				setButtonRBackground('black');
			}, 500);
		}
	}

	const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
          <div className="carousel-button-group">
            <div className={`direction-button ${buttonLDisable ? 'disabledL' : ''}`} onClick={() => onLeftClick(previous)} onMouseUp={() => onLeftClickEnd()}>
				<SvgLeftIcon fill={buttonLFill} background={buttonLBackground} width={buttonWidth}></SvgLeftIcon>
			</div>

			<div className={`direction-button ${buttonRDisable ? 'disabledL' : ''}`} onClick={() => onRightClick(next)} onMouseUp={() => onRightClickEnd()}>
				<SvgRightIcon fill={buttonRFill} background={buttonRBackground} width={buttonWidth}></SvgRightIcon>
			</div>
          </div>
        );
    };

	return (
		<div className={"carousel-container"} style={{"position": "relative"}}>
			<Carousel
				additionalTransfrom={0}
				arrows={false}
				centerMode={false}
				className="line-one-container"
				containerClass="container-padding-bottom"
				customButtonGroup={<ButtonGroup />}
				partialVisible
				focusOnSelect={false}
				infinite={infinite}
				itemClass="line-one-item"
				minimumTouchDrag={80}
				pauseOnHover
				renderArrowsWhenDisabled={false}
				renderButtonGroupOutside
				renderDotsOutside={false}
				responsive={{
				desktop: {
					breakpoint: {
					max: 3000,
					min: 1024
					},
					items: 3,
					partialVisibilityGutter: 40
				},
				mobile: {
					breakpoint: {
					max: 464,
					min: 0
					},
					items: 1,
					partialVisibilityGutter: 60
				},
				tablet: {
					breakpoint: {
					max: 1024,
					min: 464
					},
					items: 1,
					partialVisibilityGutter: 30
				}
				}}
				rewind={false}
				rewindWithAnimation={false}
				rtl={false}
				shouldResetAutoplay
				showDots={false}
				sliderClass=""
				slidesToSlide={1}
				swipeable={swipeable}
				afterChange={(previousSlide, state) => {
					if (!infinite) {
						const direction = previousSlide > state.currentSlide ? 'left' : 'right';
						if (direction === 'left') {
							if (showCustomDot) {
								setSelectIndex(index => {
									if (index != 0) setSelectIndex(previousSlide-1);
								});
								if((previousSlide)%5==0) {
									setTranslateX((cur) => cur+95);
								}
							}
							if (state.currentSlide == 0) {
								setButtonLDisable(true);
							}
							setButtonRDisable(buttonRDisable => {
								if(buttonRDisable) return false;
							});
						}
						else if (direction === 'right') {
							if (showCustomDot) {
								setSelectIndex(index => {
									if (index != props.children.length - 1) setSelectIndex(previousSlide+1);
								});
								if((previousSlide+1)%5==0) {
									setTranslateX((cur) => cur-95);
								}
							}
							if (state.currentSlide == state.totalItems - 1) {
								setButtonRDisable(true);
							}
							setButtonLDisable(buttonLDisable => {
								if (buttonLDisable) {
									return false;
								}
							});
						}
					}
				}}
			>
				{props.children}
			</Carousel>
			{
				showCustomDot ? 
				<div className="dot-container">
					<div
						style={{
							width: 'max-content',
							transform: `translateX(${translateX}px)`,
							transition: "all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
						}}
					>
						{dotIndexList.map(item => (
							<div className={`dot ${selectIndex === item ? 'selected-dot' : ''}`}></div>
						))}
					</div>
				</div> : <></>
			}
        </div>
	);
};
