import React from "react";
import "./CompanyTechs.less";
import EmptyLine from "../../ui/EmptyLine";
import {valuePatch} from "../../config/ValuesConfig";
import TechStack from "../tech-stack/TechStack";


function getTechs(profileData) {
    let technologies = profileData.companyStructure.technologies || {}
    if (typeof technologies === 'string') {
        technologies = {
            freeText: technologies,
            selected: []
        }
    }
    technologies.selected = technologies.selected || []
    technologies.freeText = technologies.freeText || ''
    return technologies
}

export default function CompanyTechs(props) {
    const profileData = props.profileData || {}
    let technologies = getTechs(profileData)

    return (
        <>
            <div className={'our-tech'}>
                Our technology
            </div>
            <EmptyLine height={18}/>
            <div className={'tech-body'}>
                <TechStack text={technologies.freeText} selected={technologies.selected}/>
            </div>
        </>)
}