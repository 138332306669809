import React from "react";
import "./ValueMedia.less";
import AJVideoPlayer from "../../ui/AJVideoPlayer";
import {AJImageUrl} from "../../http/AJHttpConst";


export function getOrderedValues(profileData) {
    const describeValues = profileData.describeValues
    const values = Object.keys(describeValues)
        .map(key => describeValues[key].value)
    return values
}

export default function ValueMedia(props) {
    const {value, height, width} = props

    if (value.video) {
        return (
            <AJVideoPlayer
                height={height}
                width={width}
                url={value.video}/>
        )
    }
    else if (value.image) {
        return (
            <img
                width={width} src={AJImageUrl(value.image) || props.placeholderImage}
                onError={() => {}}/>
        )
    }

    return null
}
