import React, {useEffect} from "react";
import {NavBar, Icon, Badge} from 'antd-mobile';
import {useHistory} from 'react-router'
import "./Navigator.less"
import SvgMenuIcon from "../icons/SvgMenuIcon";
import SvgFavoriteIcon from "../icons/SvgFavoriteIcon";
import {enableScroll} from "../App";
import AJLogo from "../ui/AJLogo";
import EmptyLine from "../ui/EmptyLine";

export function gotoLink(link, history) {
    if (history.location.pathname === link) {

    }
    else {
        history.push(link)
    }
}

export default function Navigator(props) {
    const history = useHistory()
    let {version='1', customStyle={}} = props

    useEffect(() => {
        const elements = document.getElementsByClassName("am-navbar-light");
        if (elements.length > 0) {
            elements[0].style.background = version === '1' ? 'black' : 'white';
        }
    }, []);

    return (
        <div style={{...customStyle}} className={'aj-navigator'}>
            {
                version === '2' ? <EmptyLine height={customStyle.topGap ?? 20} width={'100%'} background={'white'} /> : <></>
            }
            <div className={'aj-navigator-container'}>
                <NavBar
                    mode="light"
                    leftContent={<div className={'aj-navigator-title'}
                                    onClick={() => {
                                        gotoLink("/", history)
                                    }}>
                        <AJLogo schema={version === '1' ? 'white' : 'black'}/>
                    </div>}
                    rightContent={<div>
                        <div onClick={props.toggleMenu} style={{display: 'inline-block', verticalAlign: 'middle'}}>
                            <SvgMenuIcon height={20} width={24} fill={version === '1' ? '#FFF' : '#000'} />
                        </div>
                    </div>}
                />
            </div>
        </div>
    )
}
