import React, {useState} from "react";
import "./Menu.less";
import {Popover} from "antd-mobile";
import EmptyLine from "../ui/EmptyLine";
import {SocialMediaIcons2Line} from "./SocialMediaIcons";
import {socialLinkConfig} from "./AirJobbFooter";
import {gotoLink} from "./Navigator";
import {useHistory} from "react-router";
import "../ui/AJClass.less";

export const LoginTips = () => (
    <div className={'aj-menu-tips'}>
        Are you a Company?<br/>
        Do you want to attract<br/>
        Engineers that share your<br/>
        Values & Purpose?<br/><br/>
        To provide the best<br/> onboarding,<br/>
        please make sure you use<br/>
        your computer.
    </div>
)

export const HowItWorkTips = () => {
    return (
        <>
            <div className={'aj-menu-tips'}>
                How it works<br/><br/>
                1. Pick your most<br/>important values<br/><br/>
                2. Be matched<br/> with companies <br/><br/>
                3. Apply to the job<br/> meaningful for you<br/>
            </div>
            <div style={{background: 'black'}}>
                <EmptyLine height={18}/>
            </div>
        </>
    );
}

function needAdjustMenu() {
    return window && window.innerHeight < 666;
}

export default function Menu(props) {
    const [showTips, setShowTips] = useState(false)
    // const [showContactUs, setShowContactUs] = useState(false)
    const history = useHistory()
    const [howItWork, setHowItWork] = useState(false)

    return (
        <div className={'aj-menu'}>
            <div className={'aj-menu-close'} onClick={props.onClose}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="22.5783" height="2.25783" transform="translate(1.43848 16.9534) rotate(-45)" fill="white"/>
                    <rect width="22.5783" height="2.25783" transform="translate(2.56738 1.14844) rotate(45)" fill="white"/>
                </svg>
            </div>
            <div className={'aj-menu-container'} style={{'padding-top': needAdjustMenu() ? '54px' : '95px'}}>
                <div className={'aj-menu-title'} onClick={() => {
                    gotoLink('/contact', history)
                    props.onClose()
                    //showContact()
                }}>
                    Contact
                </div>
                <EmptyLine height={6}/>
                <div className={'aj-menu-title'} onClick={() => {
                    gotoLink('/latest', history)
                    props.onClose()
                }}>
                    Media & News
                </div>
                <EmptyLine height={6}/>
                <div className={'aj-menu-title'} onClick={() => {
                    gotoLink('/talent', history)
                    props.onClose()
                }}>
                    Talent
                </div>
                <EmptyLine height={6}/>
                <div className={'aj-menu-title'} onClick={() => {
                    gotoLink('/employer', history)
                    props.onClose()
                }}>
                    Employer
                </div>
                <EmptyLine height={26}/>
                <div className={'aj-menu-title'} onClick={() => {
                    gotoLink('/toolbox', history)
                    props.onClose()
                }}>
                    True Fit
                </div>
                <EmptyLine height={6}/>
                <div className={'aj-menu-title'} onClick={() => {
                    gotoLink('/about', history)
                    props.onClose()
                }}>
                    About us
                </div>
                <EmptyLine height={6}/>
                <div className={'aj-menu-title'} onClick={() => {
                    gotoLink('/price', history)
                    props.onClose()
                }}>
                    Pricing
                </div>
                <EmptyLine height={6}/>
                <Popover overlay={<LoginTips/>}
                        visible={showTips}
                        placement={'left'}
                        onVisibleChange={
                    () => setShowTips(false)
                }
                >
                    <div className={'aj-menu-title'} onClick={() => setShowTips(true)}>
                        Employer login
                    </div>
                </Popover>
                <EmptyLine height={needAdjustMenu() ? 50 : 60}/>
                <div className={'aj-menu-title-small'} onClick={() => {
                    gotoLink('/terms-conditions', history)
                    props.onClose()
                }}>
                    Terms of use
                </div>
                <EmptyLine height={11}/>
                <div className={'aj-menu-title-small'} onClick={() => {
                    gotoLink('/privacy', history)
                    props.onClose()
                }}>
                    Privacy
                </div>

                {/*<EmptyLine height={104}/>*/}
                <div style={{position: 'absolute', left: 24, bottom: 50}}>
                    <div className={'menu-company-icons'}>
                        <SocialMediaIcons2Line
                            socials={socialLinkConfig}
                            // color={'#474A63'}
                            width={20}
                            height={20}
                            space={16}/>
                    </div>
                    <EmptyLine height={23}/>
                    <div className={'aj-menu-copyright'}>
                        Copyright {new Date().getFullYear()}
                    </div>
                </div>
            </div>
        </div>
    )
}
