import React from "react";

import Vimeo from 'react-vimeo';
import YouTube from 'react-youtube';

import "./AJVideoPlayer.less"

function isYoutube(url) {
    if (!url) return false
    return url.startsWith('https://www.youtube.com')
}

function isVimeo(url) {
    if (!url) return false
    return url.startsWith('https://vimeo.com/')
}

function getVideoId(url) {
    if (isYoutube(url)) {
        let urlTmp = url.replace(/https:\/\/www.youtube.com\/.*v=/, '')
        if (urlTmp.includes('&')) {
            urlTmp = urlTmp.substring(0, urlTmp.indexOf('&'))
        }
        return urlTmp
    }
    else if (isVimeo(url)) {
        return url.replace(/https:\/\/vimeo.com\//, '')
    }

    return null
}

export default function AJVideoPlayer(props) {

    const {url} = props
    let height = props.height || 390
    let width = props.width || 640
    // console.log(getVideoId(url))
    return (
        <div style={{height: height, width: width}} className={'aj-video-player'}>
            {
                isYoutube(url) ?
                    <YouTube
                        videoId={getVideoId(url)}
                        opts={{
                            height: height,
                            width: width,
                            playerVars: {
                                autoplay: props.autoplay || false
                            }
                        }}
                        onReady={()=>true}
                    />
                    :
                    isVimeo(url) ?
                        <Vimeo
                            videoId={getVideoId(url)}
                            autoplay={true}
                            width={width}
                            height={height}
                        />
                        : null
            }
        </div>

    )

}
