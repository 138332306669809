import EmptyLine from "../ui/EmptyLine";
import AirJobbFooter from "../component/AirJobbFooter";
import React from "react";

import "./ContactPage.less"

export default function ContactPage(props) {
    return (
        <>
            <div className={'static-page'}>
                <div className={'static-page-container'}>
                    <div className={'static-page-header'}>
                        <img src={'/img/contact/contact-header-v2.png'} width={'100%'} alt={'company page header'}/>
                        {/*<div className={'static-page-title'}>*/}
                        {/*    Contact*/}
                        {/*</div>*/}
                    </div>
                    <EmptyLine height={50}/>
                    <div className={'contact-card-container-3'}>
                        <EmptyLine height={40}/>
                        <div className={'contact-card-header'}>
                            Contact
                        </div>
                        <EmptyLine height={25}/>
                        <div className={'contact-card-small'}>
                            Write to us
                        </div>
                        <div className={'contact-card-middle'} onClick={() =>
                            window.open("mailto:team@genvalues.com")
                        }>
                            team@genvalues.com
                        </div>
                        {/*<EmptyLine height={16}/>*/}
                        {/*<div className={'contact-card-small'}>*/}
                        {/*    Talk to us*/}
                        {/*</div>*/}
                        {/*<div className={'contact-card-middle'} onClick={() =>*/}
                        {/*    window.open("tel:+46(0)732802261")*/}
                        {/*}>*/}
                        {/*    +46(0) 732 80 22 61*/}
                        {/*</div>*/}
                    </div>
                </div>

                <EmptyLine height={50}/>



                    <div className={'static-page-text-container'}>
                        <div className={'static-page-text-title'}>
                            Values matter
                        </div>
                        <EmptyLine height={12}/>
                        <div className={'static-page-text-body'} style={{fontSize: 17, lineHeight: '150%'}}>
                            Welcome to Genvalues! We are thrilled that you have landed on our "Contact Us" page, which means you might be interested in learning more about our platform or have a question that needs answering. We're here to help!
                            <br/><br/>
                            <div style={{fontSize: 16, lineHeight: '150%'}}>
                            At Genvalues, our mission is to connect modern tech talent with impact tech companies that share the same values.  We're passionate about what we do and want to ensure that every visitor to our platform feels the same way.
                                <br/><br/>
                            We know that navigating the job market can be overwhelming, which is why we've made it our mission to simplify the process for you. Whether you're a talented engineer looking for your next team or an impact tech company looking to hire modern talent, we're here to help.
                                <br/><br/>
                            We will love to hear from you if you have any questions about our platform, our team, or the companies and positions listed on our site.
                            </div>





                        </div>
                        <EmptyLine height={50}/>

                    </div>

                    {/*<div className={'contact-card-container-2'}>*/}
                    {/*    <EmptyLine height={30}/>*/}
                    {/*    <div className={'content'}>*/}


                    {/*        For a limited time, <br/>we provide support<br/> to define values<br/> and publish profile. <br/><br/>*/}
                    {/*        Contact us if you<br/> want to know more<br/> about profile<br/> creation or subscription.*/}



                    {/*    </div>*/}
                    {/*</div>*/}
                <EmptyLine height={98}/>
                </div>
            <AirJobbFooter/>
        </>
    )
}