import React, { useState, useEffect } from 'react';
import './dynamicRatioImage.less';

const DynamicRatioImage = ({ imageUrl, fixedAspectRadio }) => {
  const [aspectClass, setAspectClass] = useState('');

  useEffect(() => {
    if (fixedAspectRadio) {
        setAspectClass(fixedAspectRadio);
        return;
    }

    const img = new Image();
    img.onload = function() {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      const aspectRatio = width / height;

      // Define the aspect ratio ranges
      const aspectRatios = {
        'aspect-ratio-1-1': 1,
        'aspect-ratio-16-9': 16 / 9,
        'aspect-ratio-4-3': 4 / 3,
      };

      // Find the closest aspect ratio
      let closestRatio = 'aspect-ratio-1-1';
      let minDifference = Infinity;

      for (const [className, ratio] of Object.entries(aspectRatios)) {
        const difference = Math.abs(aspectRatio - ratio);
        if (difference < minDifference) {
          closestRatio = className;
          minDifference = difference;
        }
      }

      setAspectClass(closestRatio);
    };

    img.src = imageUrl;
  }, [imageUrl]);

  return (
    <div className={`image-wrapper ${aspectClass}`}>
      <img src={imageUrl} alt="Dynamic Aspect Ratio" />
    </div>
  );
};

export default DynamicRatioImage;
