import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { debounce } from 'lodash';
import './ValueSelectPage.less';
import BubbleLogoButton from '../component/startV2/bubbleLogo';
import ScrollTab from '../component/startV2/scrollTab';
import { defaultValuesConfig3, valuePatch, valuePatchReverse, valueListWithIndex } from '../config/ValuesConfig';
import { talentValueMatch, getAllCompanies } from "../http/AJHttp";
import EmptyLine from '../ui/EmptyLine';

export default function ValueSelectWrapper(props) {
  let history = useHistory();
  const location = useLocation();
  const [containerWidthHeight, setContainerWidthHeight] = useState([0, 0]);
  let [selectIndex, setSelectIndex] = useState(0);
  let [selectedValues, setSelectedValues] = useState(defaultValuesConfig3);
  let [neededFetchMatched, setNeededFetchMatched] = useState();
  let [showInitialPage, setShowInitialPage] = useState(false);
  let [showLoadingPage, setShowLoadingPage] = useState(false);
  let [initialPageSx, setInitialPageSx] = useState({});
  let [bubbleContainerSx, setBubbleContainerSx] = useState({});
  let [imgSx, setImgSx] = useState({});
  let [selectHintTextSx, setSelectHintTextSx] = useState({});
  let [totalPageSx, setTotalPageSx] = useState({});
  const [canViewAllMatches, setCanViewAllMatches] = useState(false);

  useEffect(() => {
    setContainerWidthHeight([window.innerWidth, window.innerHeight-280]);
    setInitialPageSx({...initialPageSx, 'height': `${window.innerHeight-315}px`});
    setBubbleContainerSx({...bubbleContainerSx, 'height': `${window.innerHeight-275}px`});
    if(window.innerHeight <= 800) {
      setImgSx({...imgSx, 'margin-top': `${(48/177)*window.innerHeight-150 < 10 ? 10 : (48/177)*window.innerHeight-150}px`, 'margin-bottom': '20px', 'width': `${(1/11)*window.innerHeight-26}%`});
      setSelectHintTextSx({...selectHintTextSx, 'width': `${(2/11)*window.innerHeight-52}%`});
    } else {
      setImgSx({...imgSx, 'margin-top': `${(48/177)*window.innerHeight-150 < 10 ? 10 : (48/177)*window.innerHeight-150}px`, 'margin-bottom': '26px'});
    }
    setTotalPageSx({...totalPageSx, 'max-height': `${window.innerHeight}px`, 'max-width': `${window.innerWidth}px`});
  }, []);

  useEffect(() => {
    const addFilterPropInSelectedValues = (filterValues) => {
      setSelectedValues(prevSelectedValues => {
        prevSelectedValues.forEach((item, index)=>{
          item.values.forEach(subItem=>{
            if (!filterValues[index].includes(subItem?.value)) {
              subItem['isFiltered'] = true;
            }
          })
        });
        return [...prevSelectedValues];
      });
    };

    const getShouldFilterValues = (companyInfo) => {
      let filterValues = Array.from({ length: 7 }, (_, index) => index).reduce((obj, key) => ({ ...obj, [key]: [] }), {});
      companyInfo.forEach(item => {
        if(item?.published) {
          Object.values(item.describeValues).forEach(value=>{
            const valueText = value.value;
            const valueTextPatch1 = valuePatch(valueText);
            const valueTextPatch2 = valuePatchReverse(valueText);
            const allValues = [...new Set([valueText, valueTextPatch1, valueTextPatch2])];

            Object.keys(valueListWithIndex).forEach(index => {
              let tmpList = valueListWithIndex[index];
              let newIndex = index;
              if(index==1) {
                newIndex = 6;
              } else if(index==0) {
                newIndex = index;
              } else {
                newIndex = index - 1;
              }
              if(tmpList.includes(valueText.toLowerCase()) || tmpList.includes(valueTextPatch1.toLowerCase()) 
                || tmpList.includes(valueTextPatch2.toLowerCase())) {
                  filterValues[newIndex] = filterValues[newIndex].concat(allValues);
              }
            })
          });
        }
      });
      sessionStorage.setItem('cachedFilteredValues', JSON.stringify(filterValues));
      return filterValues;
    };

    const fetchData = async () => {
      try {
        let shouldFilterValues;
        shouldFilterValues = JSON.parse(sessionStorage.getItem('cachedFilteredValues'));
        if (!shouldFilterValues) {
          let cachedCompanyInfo = JSON.parse(sessionStorage.getItem('cachedCompanyInfo'));
          if (!cachedCompanyInfo) {
            const response = await getAllCompanies();
            if (response?.data && response.data.length > 0) {
              cachedCompanyInfo = response.data;
            }

            shouldFilterValues = getShouldFilterValues(cachedCompanyInfo);
            addFilterPropInSelectedValues(shouldFilterValues);
          } else {
            shouldFilterValues = getShouldFilterValues(cachedCompanyInfo);
            addFilterPropInSelectedValues(shouldFilterValues);
          }
        } else {
          addFilterPropInSelectedValues(shouldFilterValues);
        }
      } catch (error) {}
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (history.action) {
      const cachedSelectedValues = JSON.parse(sessionStorage.getItem('cachedSelectedValues'))?.selectedValues;
      const cachedBubbleData = JSON.parse(sessionStorage.getItem('cachedSelectedValues'))?.bubbleData;
      const cachedOnlySelectedValues = JSON.parse(sessionStorage.getItem('cachedOnlySelectedValues'));

      let isSelectedValueChanged = location.state?.isChanged;
      let newSelectedValue = location.state?.selectedValues;
      if (isBool(isSelectedValueChanged) && isSelectedValueChanged && newSelectedValue) {
        // selected values has been changed and the number is not zero
        solveBrowserBack(newSelectedValue);
        return;
      } else {
        if (cachedOnlySelectedValues && cachedSelectedValues && cachedOnlySelectedValues &&
           !compareSelectedValues(cachedOnlySelectedValues, cachedSelectedValues)) {
          // select values passed is different with cache
          solveBrowserBack(cachedOnlySelectedValues);
          return;
        }
        if (cachedSelectedValues && cachedBubbleData) {
          setCanViewAllMatches(true);
          setShowInitialPage(false);
          setSelectedValues(cachedSelectedValues);
          setBubbleLogoData(cachedBubbleData);
          return;
        }
      }
    }
    setShowInitialPage(true);
  }, [location, history]);

  function solveBrowserBack(values) {
    if (selectedValuesNum(values)) {
      setCanViewAllMatches(true);
      setSelectedValues(values);
      setShowInitialPage(false);
      setNeededFetchMatched(true);
    } else {
      setCanViewAllMatches(false);
      setShowInitialPage(true);
    }
  }

  // debounce user's select input
  const fetchMatchedCompanies = debounce(() => {
    setNeededFetchMatched(!neededFetchMatched);
  }, 800); // debounce time is 0.8 seconds

  // debounce browser window change behavior
  // const redrawWithWindowChange = debounce(() => {
  //   const parentElement = document.getElementById('bubble-logo-container');
  //   if (parentElement) {
  //     const width = parentElement.offsetWidth;
  //     const height = parentElement.offsetHeight;
  //     if (width === containerWidthHeight[0] && height === containerWidthHeight[1]) return;
  //     setContainerWidthHeight([width, height]);
  //   } else {
  //     setInitialPageSx({...initialPageSx, 'min-height': `${window.innerHeight-300}px`});
  //   }
  // }, 1000); // debounce time is 1 seconds

  // redraw bubble logos due to zoom in and out behavior
  // function handleZoom() {
  //   redrawWithWindowChange();
  // }

  // redraw bubble logos due to browser window size change behavior
  // useEffect(() => {
  //   const handleWindowResize = () => {
  //     redrawWithWindowChange();
  //   };

  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);

  useEffect(() => {
    if (neededFetchMatched == undefined) {
      return;
    }

    // get selected values
    let selectedValuesList = [];
    for (let valueType of selectedValues) {
      for (let value of valueType['values']) {
        if (value['isSelected']) {
          selectedValuesList.push(value['value']);
        }
      }
    }
    fetchTopMostCompanies(selectedValuesList, 1);
  }, [neededFetchMatched]);

  let [bubbleLogoData, setBubbleLogoData] = useState([]);
  useEffect(() => {
    const parentElement = document.getElementById('bubble-logo-container');
    if (parentElement) parentElement.scrollLeft = parentElement.scrollTop = 0;
  }, [bubbleLogoData]);
  let lastData = [];
  const fetchTopMostCompanies = async (values, page) => {
    if (!showLoadingPage) setShowLoadingPage(true);

    values = values || [];
    page = page || 1;
    let originalDataList = [];
    let hasNext = true;
    let matchedNumber = 0;
    while (hasNext && page <= 1) {
      const response = await talentValueMatch(values, page, 27);
      if (response) {
        const tmpList = response.data.results;
        if (tmpList && tmpList.length > 0) {
          matchedNumber = Number(tmpList[0]['matched']);
          originalDataList = originalDataList.concat(tmpList);
        }
        if (originalDataList.length >= matchedNumber) {
          break;
        }
        if (!response.data.next) {
          hasNext = false;
        } else {
          page += 1;
        }
      }
    }

    let result = [];
    if (originalDataList && originalDataList.length > 0) {
      for (let index in originalDataList) {
        if (index >= matchedNumber) {
          break;
        }
        const item = originalDataList[index];
        const logoUrl = item?.logoUrl ? item?.logoUrl : '';
        result.push({company_name: item['company_name'], company_id: Number(item['company_id']), logoUrl: 'https://genvalues.com/' + logoUrl});
      }
    }

    lastData = bubbleLogoData;
    const tmp = [];
    for (let index in result) {
      const companyId = result[index].company_id.toString();
      let isExistCompany = false;
      let positionOfExistCompany = Number(index) + 1;
      for (let company of lastData) {
        if (company['companyId'] === companyId) {
          isExistCompany = true;
          positionOfExistCompany = Number(company['to']);
          break;
        }
      }
      if(!isExistCompany) {
        tmp.push({ imgUrl: result[index]['logoUrl'], companyId: companyId, companyName: result[index]['company_name'], from: 0, to: Number(index) + 1 });
      } else {
        tmp.push({ imgUrl: result[index]['logoUrl'], companyId: companyId, companyName: result[index]['company_name'], from: positionOfExistCompany, to: Number(index) + 1 });
      }
    }
    const tmpObj = {
      selectedValues: selectedValues,
      bubbleData: tmp,
      bubbleNum: tmp.length
    };
    sessionStorage.setItem('cachedSelectedValues', JSON.stringify(tmpObj));
    setShowLoadingPage(false);
    setBubbleLogoData(tmp);
  }

  function indexChange(index) {
    setSelectIndex(index);
  };

  const handleValueSelect = (event, valueTypeIndex, valuesIndex) => {
    if (showInitialPage) {
      setShowInitialPage(false);
    }
    let tmp = selectedValues;
    tmp[valueTypeIndex]["values"][valuesIndex]["isSelected"] = !tmp[valueTypeIndex]["values"][valuesIndex]["isSelected"];
    event.target.className = tmp[valueTypeIndex]["values"][valuesIndex]["isSelected"] ? 'value-item is-selected' : 'value-item'; 
    selectedValues = tmp;
    fetchMatchedCompanies();
  };

  function jumpToMatchCompanyPage() {
    if ((selectIndex === 6 || canViewAllMatches) && selectedValuesNum(selectedValues)) {
      sessionStorage.setItem('cachedOnlySelectedValues', JSON.stringify(selectedValues));
      history.push({
        pathname: '/companies-matched',
        state: { selectedValues: selectedValues }
      });
    }
  }

  function isBool(value) {
    return typeof value === 'boolean';
  }

  function selectedValuesNum(list) {
    let num = 0;
    for (let valueType of list) {
      for (let value of valueType['values']) {
          if (value?.isSelected) {
            num += 1;
          }
      }
    }
    return num;
  }

  function compareSelectedValues(source, target) {
    for (let i=0; i<source.length; i++) {
      for (let j=0; j<source[i]['values'].length; j++) {
        const srcIsSelcted = source[i]['values'][j]?.isSelected ? true : false;
        const tagIsSelected = target[i]['values'][j]?.isSelected ? true : false;
        if (srcIsSelcted !== tagIsSelected) {
          return false;
        }
      }
    }
    return true;
  }

  return (
    <div className='total-container' style={totalPageSx}>
      {
        showInitialPage ?
          <div className={"initial-page-container"} style={initialPageSx}>
              <EmptyLine height={51} width={'100%'} background={'white'} />
              <img style={imgSx} src={"/img/value-select/select-hint.svg"}></img>
              <br/>
              <img src={"/img/value-select/select-hint-2.svg"} style={selectHintTextSx}></img>
          </div> : showLoadingPage ?
          <div className={"initial-page-container"} style={{...initialPageSx, 'background-color': '#ffffff'}}>
            <img src={"/img/home/loading.svg"} style={{"width": "30px", top: '50%', position: 'relative'}}></img>
          </div> :
          <div className={"bubble-logo-container"} style={bubbleContainerSx} id={'bubble-logo-container'}>
            {
              bubbleLogoData.map((item, index) => {
                return (
                  <BubbleLogoButton key={index} img={item.imgUrl} containerWidthHeight={containerWidthHeight} 
                    from={item.from} to={item.to} companyId={item.companyId} companyName={item.companyName} 
                    currentIndex={index+1} forceUpdate={bubbleLogoData} />
                )
              })
            }
          </div>
      }

      <div className='value-select-container' style={{'width': '100%', height: '256px'}}>
        <div className='border'>
          <div className="select-tab">
            <ScrollTab onIndexChange={indexChange}></ScrollTab>
          </div>

          {
            selectedValues.map((values, index)=>{
              return (
                <div className={`${selectIndex !== index ? 'not-show' : ''} button-container`}>
                  {values['values'].map((item, index1) => (
                    <div style={{'display': 'inline'}}>
                      <button className={`value-item ${item["isSelected"] ? 'is-selected' : ''} ${item["isFiltered"] ? 'is-filtered' : ''}`} style={{'marginBottom': '13px'}} key={index1} 
                        onClick={(event)=>{handleValueSelect(event, index, index1)}}
                      >
                        {item.image ? <img src={`/img/value/${item.image}.svg`} style={{width: '20px'}} /> : <></>}
                        {item.displayValue ? item.displayValue : item.value}
                      </button>
                      {(index1+1) == (values['values'].length / 2).toFixed(0) ? <br/> : <></>}
                    </div>
                  ))} 
                </div>
              )
            })
          }

          <div className="dot-container">
            {[0, 1, 2, 3, 4, 5, 6].map(item => (
              <div className={`dot ${selectIndex === item ? 'selected-dot' : ''}`}></div>
            ))}
          </div>

          <div style={{"text-align": "center", "margin-bottom": "5px"}}>
            <button className={`matched-company-button ${(((selectIndex === 6) || canViewAllMatches) && selectedValuesNum(selectedValues)) ? 'active' : ''}`} 
              onClick={()=>{jumpToMatchCompanyPage()}}>View all matches</button>
          </div>
        </div>
      </div>

    </div>
  )
}
