import React from "react";
import "./FunctionsCard.less"
import EmptyLine from "../../ui/EmptyLine";


export default function FunctionsCard()
{
    return <div className={'function-card-container'}>
        <FunctionItem description={'High quality matching'}/>
        <FunctionItem description={'Applicant data'}/>
        <FunctionItem description={'Support with values description'}/>
        <FunctionItem description={'Onboarding support'}/>
        <FunctionItem description={'Woman in tech: 50,3% of visitors'}/>
        <FunctionItem description={'Team employer branding'}/>
        <FunctionItem description={'Social media integration'}/>
        {/*<FunctionItem description={'High quality profile visitors'}/>*/}
        <FunctionItem description={'Profile boost 👏🏽 by talents '}/>

        {/*<FunctionItem description={'Talent interest data '}/>*/}
    </div>

}

function Checked() {
    return <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 2.26115L16.7675 -1.21487e-07L6.33333 10.4936L2.24833 6.40127L-3.62396e-07 8.6465L6.33333 15L19 2.26115Z" fill="#6A35FF"/>
    </svg>
}

function FunctionItem({description}) {
    return <div style={{borderBottom: '1px solid #EAEAEA', height: 55}}>
        <div className={'function-card-item'} style={{position: 'relative'}}>
            <span>{description}</span>
            <span style={{position: 'absolute', right: 0}}>
                <Checked/>
            </span>
        </div>

    </div>

}